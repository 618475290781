import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import MessageFromModal from "./MessageFromModal"
import * as styles from "../Supporters.module.css"

function Modal(props) {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
    {props.messagePerson ? (
      <div onClick={() => setModalShow(true)} className={styles.messageLink}>
        { props?.messagePerson ? (
          <>
            Message from{" "}
        {props.personTitle && (
          `${props.personTitle}${", "}`
        )}
        {props.messagePerson} <span><FontAwesomeIcon icon={faExternalLink}/></span>
          </>
        ) : null }

        
      </div>
    ) : null }
      

      <MessageFromModal 
        messagePerson={props.messagePerson}
        partner={props.partner}
        message={props.message}
        partnerUrl={props.partnerUrl}
        personTitle={props.personTitle}
        personPhoto={props.personPhoto}
        partnerLogo={props.partnerLogo}
      show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Modal
