import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const SimplotLongLogo = (props) => {
    const data = useStaticQuery(graphql`
{
  simplotLongLogo: file(relativePath: { eq: "Simplot-Company-Foundation-Logo_Full-Color.png" }) {
    childImageSharp {
      fluid(maxWidth: 250, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`
)

return (
    <>
    <a href="https://www.simplot.com/" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.simplotLongLogo.childImageSharp.fluid} alt="Simplot Logo" />
    </a>
    </>
)

}

export default SimplotLongLogo