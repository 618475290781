import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
`;

const IdahoCentralLogo = (props) => {
    return (

<a href="https://www.iccu.com" target="_blank" rel="noopener noreferrer">
  <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.5162 116.64029">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <path fill={props.fillcolor} d="M166.52861,55.369c-2.099-4.344-5.918-7.398-12.219-7.398-8.734,0-14.845,7.063-14.845,17.231,0,10.547,5.87,17.232,14.607,17.232,6.061,0,10.072-2.817,12.362-7.545l-5.633-3.338c-1.765,2.96-3.532,4.247-6.537,4.247-5.015,0-7.926-4.583-7.926-10.596,0-6.207,2.864-10.598,7.876-10.598a6.34577,6.34577,0,0,1,6.11,3.628Z"/>
          <path fill={props.fillcolor} d="M189.63249,69.40309c0-7.161-3.868-13.08-10.548-13.08-6.35,0-11.172,5.394-11.172,13.08,0,7.924,4.584,13.031,11.791,13.031a11.70516,11.70516,0,0,0,8.975-4.057l-4.678-3.915a5.59493,5.59493,0,0,1-4.297,1.765,4.8206,4.8206,0,0,1-5.154-4.725h14.988a20.6236,20.6236,0,0,0,.095-2.099m-6.396-2.865h-8.593c.241-2.532,1.72-4.489,4.106-4.489,2.959,0,4.296,2.244,4.487,4.489"/>
          <path fill={props.fillcolor} d="M215.1606,81.90579v-14.126c0-7.066-2.862-11.456-9.26-11.456a8.21241,8.21241,0,0,0-5.87,2.193v-1.667h-6.589v25.056h6.589v-13.697c0-4.344,1.575-5.68,4.295-5.68s4.249,1.336,4.249,5.825v13.552Z"/>
          <path fill={props.fillcolor} d="M235.5462,74.22219c-1.624,1.385-2.531,1.91-3.724,1.91-1.575,0-2.433-.62-2.433-3.008v-10.073h6.014v-6.202h-6.014v-9.739l-6.588,3.293v6.446h-4.201v6.202h4.201v11.838c0,4.394,2.625,7.545,6.683,7.545a8.77944,8.77944,0,0,0,5.107-1.529Z"/>
          <path fill={props.fillcolor} d="M257.11981,58.09069a6.40128,6.40128,0,0,0-4.63-1.767,6.93929,6.93929,0,0,0-5.156,2.193v-1.667h-6.588v25.056h6.588v-13.552c0-4.347,1.671-5.444,4.248-5.444a5.67669,5.67669,0,0,1,3.819,1.624Z"/>
          <path fill={props.fillcolor} d="M279.88528,81.90569v-16.802c0-5.629-4.105-8.78-10.166-8.78a16.86821,16.86821,0,0,0-9.405,2.528l2.53,4.586a12.35411,12.35411,0,0,1,6.875-1.674c2.385,0,3.579,1.433,3.579,3.196v1.244a10.38911,10.38911,0,0,0-4.82-1.001c-5.68,0-10.168,2.766-10.168,8.304,0,6.063,4.678,8.928,9.357,8.928a7.99039,7.99039,0,0,0,5.631-2.198v1.669Zm-6.587-7.348a5.39126,5.39126,0,0,1-4.582,2.29c-2.817,0-3.819-1.576-3.819-3.341,0-2.005,1.336-3.151,3.819-3.151a7.62153,7.62153,0,0,1,4.582,1.052Z"/>
          <polygon fill={props.fillcolor} points="292.866 81.906 292.866 47.11 286.278 50.403 286.278 81.906 292.866 81.906"/>
          <rect fill={props.fillcolor} x="140.9456" y="8.71529" width="6.68401" height="33.41299"/>
          <path fill={props.fillcolor} d="M173.5428,42.12869V7.33069l-6.588,3.295v7.875a7.93471,7.93471,0,0,0-5.298-1.956c-6.206,0-9.737,5.25-9.737,12.744,0,9.355,4.392,13.366,9.737,13.366a7.72292,7.72292,0,0,0,5.298-2.1v1.574Zm-6.588-7.78a4.61024,4.61024,0,0,1-4.056,2.099c-2.578,0-4.297-2.147-4.297-7.303,0-4.201,1.387-6.397,3.915-6.397a5.24294,5.24294,0,0,1,4.438,2.15Z"/>
          <path fill={props.fillcolor} d="M199.45059,42.12879v-16.802c0-5.633-4.105-8.782-10.165-8.782a16.86808,16.86808,0,0,0-9.405,2.528l2.53,4.584a12.35886,12.35886,0,0,1,6.875-1.67c2.385,0,3.578,1.43,3.578,3.197v1.241a10.37081,10.37081,0,0,0-4.819-1.003c-5.682,0-10.169,2.768-10.169,8.306,0,6.063,4.678,8.927,9.356,8.927a7.99439,7.99439,0,0,0,5.632-2.196v1.67Zm-6.587-7.351a5.38184,5.38184,0,0,1-4.582,2.291c-2.816,0-3.82-1.574-3.82-3.341,0-2.007,1.338-3.151,3.82-3.151a7.6375,7.6375,0,0,1,4.582,1.05Z"/>
          <path fill={props.fillcolor} d="M226.36771,42.12859v-14.131c0-7.063-2.863-11.453-9.26-11.453a8.2102,8.2102,0,0,0-5.87,2.194V7.3306l-6.587,3.295v31.503h6.587v-13.699c0-4.344,1.575-5.682,4.296-5.682s4.247,1.338,4.247,5.825v13.556Z"/>
          <path fill={props.fillcolor} d="M252.9588,29.622c0-7.684-4.821-13.077-11.362-13.077-6.538,0-11.312,5.393-11.312,13.077,0,7.686,4.774,13.033,11.312,13.033,6.541,0,11.362-5.347,11.362-13.033m-6.683,0c0,3.486-1.528,6.683-4.679,6.683-3.148,0-4.629-2.815-4.629-6.683,0-3.866,1.481-6.729,4.629-6.729,3.151,0,4.679,2.911,4.679,6.729"/>
          <path fill={props.fillcolor} d="M150.97321,97.02979a5.18188,5.18188,0,0,0-5.024-3.039c-3.589,0-6.103,2.903-6.103,7.082,0,4.337,2.415,7.085,6.006,7.085a5.24751,5.24751,0,0,0,5.082-3.102l-2.316-1.375a2.87639,2.87639,0,0,1-2.687,1.75c-2.063,0-3.258-1.887-3.258-4.358,0-2.551,1.177-4.357,3.238-4.357a2.61054,2.61054,0,0,1,2.511,1.493Z"/>
          <path fill={props.fillcolor} d="M165.5529,107.86059l-2.63-5.337a3.91885,3.91885,0,0,0,2.63-4.043c0-2.922-2.14-4.355-4.73-4.355h-6.397v13.735h2.747v-4.946h2.767l2.454,4.946Zm-2.827-9.322c0,1.199-.687,1.788-1.962,1.788h-3.591v-3.534h3.591c1.217,0,1.962.51,1.962,1.746"/>
          <polygon fill={props.fillcolor} points="179.374 107.86 179.374 105.172 172.291 105.172 172.291 101.855 176.233 101.855 176.233 99.186 172.291 99.186 172.291 96.813 179.08 96.813 179.08 94.125 169.544 94.125 169.544 107.86 179.374 107.86"/>
          <path fill={props.fillcolor} d="M194.3344,100.991c0-5.022-2.904-6.865-6.828-6.865h-4.299V107.86h4.103c3.532,0,7.024-2.041,7.024-6.869m-2.825,0c0,2.512-1.315,4.2-4.199,4.2h-1.355V96.792h1.452c2.905,0,4.102,1.396,4.102,4.199"/>
          <rect fill={props.fillcolor} x="198.57262" y="94.12529" width="2.74699" height="13.735"/>
          <polygon fill={props.fillcolor} points="215.906 96.832 215.906 94.126 205.269 94.126 205.269 96.832 209.214 96.832 209.214 107.86 211.962 107.86 211.962 96.832 215.906 96.832"/>
          <path fill={props.fillcolor} d="M240.4079,101.87619v-7.673h-2.746v7.713c0,2.198-1.08,3.552-2.826,3.552s-2.805-1.394-2.805-3.592v-7.673h-2.747v7.713c0,4.002,2.236,6.241,5.552,6.241,3.492,0,5.572-2.297,5.572-6.281"/>
          <path fill={props.fillcolor} d="M256.07431,107.94079v-13.737h-2.708v5.809c0,.765.039,2.493.039,3.004-.176-.356-.627-1.041-.962-1.551l-4.866-7.262h-2.629v13.737h2.709v-6.047c0-.763-.041-2.49-.041-3,.177.354.629,1.04.961,1.551l5.043,7.496Z"/>
          <rect fill={props.fillcolor} x="260.71161" y="94.20329" width="2.745" height="13.737"/>
          <path fill={props.fillcolor} d="M278.65167,101.05269c0-4.432-2.452-7.062-6.024-7.062-3.591,0-6.082,2.668-6.082,7.101,0,4.434,2.452,7.066,6.044,7.066,3.57,0,6.062-2.669,6.062-7.105m-2.825.039c0,2.787-1.277,4.34-3.199,4.34s-3.257-1.592-3.257-4.379,1.276-4.337,3.198-4.337c1.924,0,3.258,1.59,3.258,4.376"/>
        </g>
        <g>
          <path fill={props.fillcolor} d="M292.6712,107.94079v-13.737h-2.707v5.809c0,.765.039,2.493.039,3.004-.178-.356-.629-1.041-.962-1.551l-4.867-7.262h-2.627v13.737h2.706v-6.047c0-.763-.04-2.49-.04-3,.178.354.629,1.04.962,1.551l5.042,7.496Z"/>
          <path fill={props.fillcolor} d="M47.416,21.06149a39.06216,39.06216,0,0,0-8.674,5.454L0,65.2375l7.967.071,46.941-46.745a38.99617,38.99617,0,0,0-7.492,2.498"/>
        </g>
        <g>
          <path fill={props.fillcolor} d="M71.25671,18.13638c-1.048-.198-2.103-.348-3.161-.461l-66.143,66.127,8.287.004,64.763-64.752q-1.836-.54452-3.746-.918"/>
          <path fill={props.fillcolor} d="M51.53111,116.64029l8.201-.038,35.878-35.919a39.91,39.91,0,0,0,3.398-5.19,39.48162,39.48162,0,0,0,3.608-9.871Z"/>
          <path fill={props.fillcolor} d="M83.6686,22.75218l-75.086,75.034,8.316-.005,71.701-71.675a39.28973,39.28973,0,0,0-4.931-3.354"/>
          <path fill={props.fillcolor} d="M19.3462,107.63639l8.27.021,70.548-70.552a39.29873,39.29873,0,0,0-3.385-4.901Z"/>
          <path fill={props.fillcolor} d="M33.6649,113.94219l8.295.008,61.334-61.378a39.75437,39.75437,0,0,0-1.405-6.879Z"/>
          <path fill={props.fillcolor} d="M75.4716.007,24.8776,50.586a39.80232,39.80232,0,0,0-.461,8.748L83.7576,0Z"/>
          <path fill={props.fillcolor} d="M93.3076,2.77759l-66.909,66.878a39.37254,39.37254,0,0,0,2.475,5.81l72.734-72.695Z"/>
          <path fill={props.fillcolor} d="M115.8064,42.08349l-54.49,54.484a39.90841,39.90841,0,0,0,8.66-.371l54.111-54.107Z"/>
          <path fill={props.fillcolor} d="M114.5509,22.75809l-15.662,15.644-53.614,53.586a39.3687,39.3687,0,0,0,5.767,2.515l50.359-50.327,21.386-21.373Z"/>
          <path fill={props.fillcolor} d="M107.7657,8.9325l-73.889,73.896q.9855,1.146,2.069,2.23c.669.671,1.359,1.311,2.063,1.922l78.047-78.075Z"/>
        </g>
        <path fill={props.fillcolor} d="M303.5162,51.37173a4.21387,4.21387,0,1,1-4.21386-4.21386A4.13182,4.13182,0,0,1,303.5162,51.37173Zm-.74707,0a3.4668,3.4668,0,1,0-3.46679,3.4668A3.39517,3.39517,0,0,0,302.76913,51.37173Zm-1.459-.93359A1.30458,1.30458,0,0,1,300.4,51.78091l.88672,1.8086H300.19l-.8291-1.68067H298.567v1.68067h-.96875V48.99088H299.735A1.40168,1.40168,0,0,1,301.31015,50.43814Zm-.99219.02344c0-.4082-.24511-.584-.65332-.584H298.567v1.17969h1.09765C300.08456,51.05728,300.318,50.84732,300.318,50.46158Z"/>
      </g>
    </g>
  </g>
  </Logo>
  </a>
  )
  }

export default IdahoCentralLogo