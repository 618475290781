import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as style from "../Supporters.module.css"
import MessageFromModalButton from "../MessageFromModal/MessageFromModalButton"
import IdahoPowerLogo from '../IdahoPowerLogo'


const GoldLevel = () => {
    const data = useStaticQuery(graphql`
    {
      Vodka: file(relativePath: { eq: "44-north-vodka.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      KenWyatt: file(relativePath: { eq: "ken-wyatt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 130, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      unitedHeritage: file(relativePath: { eq: "uh_web_logo_80-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      albertsons: file(relativePath: { eq: "albertsons.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <div className="tab-content">
        
        <hr />
        <h1 className="text-center">Gold Level Corporate Donors - $25,000+</h1>
        
  <Row className={`${style.goldLevel} my-4`}>
   <Col md={6}>
      <Row>
        <Col md={2} className="text-center">
         <Img fluid={data.Vodka.childImageSharp.fluid} alt="44 North Vodka logo" />
        </Col>
        <Col md={10}>
          <h2>44&deg; North Vodka</h2>
          <h4>44&deg; North&reg;, the home of exceptional craft spirits using the finest local ingredients that proudly embrace the state’s rich agricultural tradition. 44&deg; North&reg; embodies both rugged authenticity and refined quality while expressing a state of mind that’s defiantly optimistic and mirrors the remarkable spirit of Idaho’s people.</h4>
          {/* <MessageFromModalButton 
            messagePerson="Ken Wyatt"
            personTitle="Co-Founder, President and CEO"
            partnerUrl="https://44northvodka.com/"
            partner="44&deg; North Vodka"
            message="Message from Co-Founder, President and CEO, Ken Wyatt"
          /> */}
        </Col>
      </Row>
   </Col>
  </Row>
  </div>
  )
}

export default GoldLevel
