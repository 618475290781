import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;

`;

const DeltaDentalLogo = (props) => {
    return (
<a href="https://www.deltadentalid.com/" target="_blank" rel="noopener noreferrer">

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.8843 12.8604">

  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path fill={props.fillColor} d="M28.2222,4.6988c-1.1426-1.1207-2.6987-1.4322-4.6699-1.5064,0,0-2.6665.0513-3.3355.0513v9.5879c2.1377,0,5.1021.1914,6.5923-.4278a4.88762,4.88762,0,0,0,1.4131-7.705M23.7271,9.9229V6.2427a1.88761,1.88761,0,1,1,0,3.6802"/>
        <path fill={props.fillColor} d="M79.1197,3.356h3.4257a37.99073,37.99073,0,0,0,2.9102,4.8803V3.356c1.1172,0,2.2734.0132,3.3945.0132,0,2.3818-.0127,5.8173-.0127,9.4912H85.2857c-.5811-.9859-1.7002-2.9492-2.7539-4.5381,0,.791.0156,3.3955.0136,4.5381H79.1197c-.0264-1.292.0498-7.1206,0-9.5044"/>
        <path fill={props.fillColor} d="M9.7369,0l5.6904,9.6504L13.356,12.8296H1.8423L0,9.7471,4.105,3.2359H7.6377l.1812-.0079L6.0713,0ZM7.7705,3.4458,4.2383,9.6504h7.1983L7.833,3.315Z"/>
        <polygon fill={props.fillColor} points="55.23 3.244 55.229 3.244 51.968 3.244 51.966 3.244 48.501 12.832 51.84 12.832 53.599 7.111 55.356 12.832 58.696 12.832 55.23 3.244"/>
        <polygon fill={props.fillColor} points="102.171 3.244 102.171 3.244 98.907 3.244 95.443 12.832 98.782 12.832 100.541 7.111 102.298 12.832 105.636 12.832 102.171 3.244"/>
        <polygon fill={props.fillColor} points="36.567 12.832 30.077 12.832 30.077 3.246 36.569 3.246 36.569 5.821 33.543 5.821 33.543 6.761 36.225 6.761 36.225 9.255 33.564 9.255 33.564 10.216 36.567 10.216 36.567 12.832"/>
        <path fill={props.fillColor} d="M69.9497,4.6988c-1.1425-1.1207-2.6982-1.4322-4.6699-1.5064,0,0-2.666.0513-3.3349.0513v9.5879c2.1386,0,5.1025.1914,6.5927-.4278a4.88849,4.88849,0,0,0,1.4121-7.705M65.4556,9.9229V6.2427a1.88765,1.88765,0,1,1,0,3.6802"/>
        <polygon fill={props.fillColor} points="78.294 12.832 71.805 12.832 71.805 3.246 78.297 3.246 78.297 5.821 75.27 5.821 75.27 6.761 77.953 6.761 77.953 9.255 75.291 9.255 75.291 10.216 78.294 10.216 78.294 12.832"/>
        <polygon fill={props.fillColor} points="37.378 12.83 37.378 3.242 40.833 3.242 40.833 9.726 43.753 9.726 43.753 12.83 37.378 12.83"/>
        <polygon fill={props.fillColor} points="106.096 12.83 106.096 3.242 109.549 3.242 109.549 9.726 112.471 9.726 112.471 12.83 106.096 12.83"/>
        <polygon fill={props.fillColor} points="42.691 6.334 42.691 3.246 49.986 3.246 49.986 6.335 48.118 6.335 48.118 12.832 44.522 12.832 44.522 6.334 42.691 6.334"/>
        <polygon fill={props.fillColor} points="89.57 6.334 89.57 3.246 96.866 3.246 96.866 6.335 94.996 6.335 94.996 12.832 91.402 12.832 91.402 6.334 89.57 6.334"/>
        <path fill={props.fillColor} d="M112.2798,3.959a1.30227,1.30227,0,1,1,1.3018,1.2944,1.29086,1.29086,0,0,1-1.3018-1.2944m2.4053,0a1.10257,1.10257,0,1,0-1.1035,1.0957,1.10435,1.10435,0,0,0,1.1035-1.0957m-.4863.7012h-.2393l-.4248-.6548h-.2383v.6548h-.1992V3.2422h.6172c.2568,0,.5049.0698.5049.3755,0,.2783-.1963.3804-.4473.3877Zm-.6641-.8545c.1758,0,.4863.0332.4863-.1919,0-.1465-.1933-.1724-.3476-.1724h-.377v.3643Z"/>
      </g>
    </g>
  </g>
</Logo>
</a>
    )
}
export default DeltaDentalLogo;
