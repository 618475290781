import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import InlightMediaLogo from "./Individuals/InlightMediaLogo"
import MessageFromModalButton from "./MessageFromModal/MessageFromModalButton"
import * as style from "./Supporters.module.css"

const Partners = () => {
    const data = useStaticQuery(graphql`
    {
      idahoDefenseAlliance: file(relativePath: { eq: "idaho-defense-alliance.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
        idahoVeterans: file(relativePath: { eq: "idvs-logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sayler: file(relativePath: { eq: "Sayler.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hagedorn: file(relativePath: { eq: "Hagedorn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      universityIdaho: file(relativePath: { eq: "university-of-idaho.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fairfieldInn: file(relativePath: { eq: "fairfield-inn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
         Vodka: file(relativePath: { eq: "44-north-vodka.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
        riverClub: file(relativePath: { eq: "river-club-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sockeyeBrewing: file(relativePath: { eq: "sockeye-brewing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
        outerlightBrewing: file(relativePath: { eq: "outer-light-brewing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
         idahoBrewingCompany: file(relativePath: { eq: "idaho-brewing-company.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <div className="tab-content">
    <Row className={`${style.supportersRow} my-4`}>
      <Col md={2} className="text-center">
        <a href="https://idahodefensealliance.com/" target="_blank" rel="noreferrer noopener">
          <Img fluid={data.idahoDefenseAlliance.childImageSharp.fluid} alt="Idaho Defense Alliance Logo" style={{maxWidth: '162px'}} />
        </a>
      </Col>
      <Col md={10}>
        <h2>Idaho Defense Alliance</h2>
        <h4>Working to enhance Idaho's economy and support the defense of our Nation.</h4>
        <MessageFromModalButton 
        messagePerson="Maj Gen Gary Sayler"
        personTitle="IDA President"
        partnerUrl="https://idahodefensealliance.com/"
        partner="Idaho Defense Alliance"
        message="The Idaho Defense Alliance advocates in support of Idaho’s military missions, installations, members and businesses in the defense sector.  In conjunction with our Idaho focus we believe in a strong national defense and therefore are privileged to support the USS Idaho Commissioning Foundation's efforts in the commissioning of the US Navy’s new attack submarine, the USS Idaho, a significant addition to the Navy’s fleet and one that will help protect our nation against foreign aggression."
        personPhoto={data.sayler.childImageSharp.fluid}
        partnerLogo={<Img fluid={data.idahoDefenseAlliance.childImageSharp.fluid} alt="Idaho Defense Alliance Logo" style={{maxWidth: '162px'}} />}
      />
      </Col>
    </Row>
    <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center">
    <a href="https://www.veterans.idaho.gov/" target="_blank" rel="noreferrer noopener">
      <Img fluid={data.idahoVeterans.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />
    </a>
    </Col>
    <Col md={10}>
      <h2>Idaho Division of Veterans Services</h2>
      <h4>We are dedicated to serving Idaho’s veterans and their families by providing superior advocacy, excellent assistance with benefits and education, high quality long-term care, and respectful interment services in a dignified final resting place.</h4>
      <MessageFromModalButton 
        //messagePerson="Marv Hagedorn"
        personTitle="Chief Administrator"
        partnerUrl="https://www.veterans.idaho.gov/"
        partner="Idaho Division of Veterans Services"
        message="A message from IDVS Chief, Marv Hagedorn"
        personPhoto={data.hagedorn.childImageSharp.fluid}
        partnerLogo={<Img fluid={data.idahoVeterans.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center">
    <a href="https://www.uidaho.edu/class/music" target="_blank" rel="noreferrer noopener">
    <Img fluid={data.universityIdaho.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />
    </a>
    </Col>
    <Col md={10}>
      <h2>University of Idaho Lionel Hampton School of Music</h2>
      {/* <h4>An Idaho company delivering unique and exceptional web experiences.</h4> */}
      {/* <MessageFromModalButton 
        messagePerson="Jansen Gunderson"
        personTitle="Principal"
        partnerUrl="https://inlightmedia.com"
        partner="Inlight Media, LLC"
        message="Inlight Media has been serving non-profit organizations throughout the Intermountain West for the past 15 years. We are grateful for the opportunity to support the USS Idaho Commissioning Committee and US Navy servicemen and women."
        partnerLogo={<InlightMediaLogo />}
      /> */}
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={4} lg={2} className="text-center">
    <div style={{maxWidth: "100%"}}>
    <a href="https://inlightmedia.com" target="_blank" rel="noreferrer noopener">
     <InlightMediaLogo />
    </a>
    </div>
    </Col>
    <Col md={9} lg={10}>
        <h2>Inlight Media</h2>
        <h4>An Idaho company delivering unique and exceptional web experiences.</h4>
        <MessageFromModalButton 
          messagePerson="Jansen Gunderson"
          personTitle="Principal"
          partnerUrl="https://inlightmedia.com"
          partner="Inlight Media, LLC"
          message="Inlight Media has been serving non-profit organizations throughout the Intermountain West for the past 20 years. We are grateful for the opportunity to support the USS Idaho Commissioning Committee and US Navy servicemen and women."
          partnerLogo={<InlightMediaLogo />}
        />
    </Col>
  </Row>
  
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center">
    <a href="https://www.marriott.com/en-us/hotels/puwfi-fairfield-inn-and-suites-moscow/overview/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MjgxODQ5NC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D" target="_blank" rel="noreferrer noopener">
    <Img fluid={data.fairfieldInn.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />
    </a>
    </Col>
    <Col md={10}>
      <h2>Fairfield Inn &amp; Suites Moscow</h2>
      <h4>1000 West Pullman Road, Moscow, Idaho, USA, 83843</h4>
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
  <Col lg={6} mb={2}>
          <Row style={{alignItems: 'center', height: '100%'}}>
          <Col md={4} className="text-center">
                <a href="https://www.riverclubboise.com/" target="_blank" rel="noopener noreferrer">
                <Img
                  fluid={data.riverClub.childImageSharp.fluid}
                  alt="River Club Boise Logo"
                  
                />
                 
                </a>
              </Col>
          
              <Col md={8}>
                <h2>River Club Boise</h2>
              </Col>
            </Row> 
          </Col>
          </Row>
  <Row className={`${style.supportersRow} my-4`}>
   <Col>
      <Row>
        <Col md={3} lg={2} className="text-center">
         <a href="https://44northvodka.com/" target="_blank" rel="noopener noreferrer"><Img fluid={data.Vodka.childImageSharp.fluid} alt="44 North Vodka logo" /></a>
        </Col>
        <Col md={10}>
          <h2 style={{ color: 'white'}}>44&deg; North Vodka</h2>
          <h4>44&deg; North&reg;, the home of exceptional craft spirits using the finest local ingredients that proudly embrace the state’s rich agricultural tradition. 44&deg; North&reg; embodies both rugged authenticity and refined quality while expressing a state of mind that’s defiantly optimistic and mirrors the remarkable spirit of Idaho’s people.</h4>
          {/* <MessageFromModalButton 
            messagePerson="Ken Wyatt"
            personTitle="Co-Founder, President and CEO"
            partnerUrl="https://44northvodka.com/"
            partner="44&deg; North Vodka"
            message="Message from Co-Founder, President and CEO, Ken Wyatt"
          /> */}
        </Col>
      </Row>
   </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
   <Col>
      <Row>
        <Col md={3} lg={2} className="text-center">
         <a href="http://www.sockeyebrew.com/" target="_blank" rel="noopener noreferrer"><Img fluid={data.sockeyeBrewing.childImageSharp.fluid} alt="Sockeye Brewing logo" /></a>
        </Col>
        <Col md={10}>
          <h2>Sockeye Brewing</h2>
          <h4>Our journey in brewing commenced in 1996 in Boise, Idaho, within the confines of a modest warehouse nestled off Federal Way, on the eastern side of Treasure Valley. During that inaugural year, our production amounted to fewer than one hundred barrels, primarily enjoyed within the confines of our brewery. Fast forward to 2023, and we're poised to brew approximately 10,000 barrels, with our reach extending to Idaho, Montana, Alaska, and Eastern Oregon. Throughout this evolution, we've had the privilege of introducing some of the most iconic ales and lagers to the Pacific Northwest. Our unwavering commitment to innovation, refinement, and the pursuit of brewing excellence endures, just as resolutely as it did on our very first day.</h4>
        </Col>
      </Row>
   </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
   <Col>
      <Row>
        <Col md={3} lg={2} className="text-center">
         <a href="http://www.outerlightbrewing.com/" target="_blank" rel="noopener noreferrer"><Img fluid={data.outerlightBrewing.childImageSharp.fluid} alt="Sockeye Brewing logo" /></a>
        </Col>
        <Col md={10}>
          <h2 style={{ color: 'white'}} >Outer Light Brewing</h2>
          <h4>At OUTER LIGHT BREWING COMPANY, we understand the sacred oath between brewer and enthusiastic beer connoisseurs the world over. The connection begins with a quality product, it grows through the experience, and it endures through a relationship created in that oath. </h4>
        </Col>
      </Row>
   </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
   <Col>
      <Row>
        <Col md={3} lg={2} className="text-center">
         <a href="https://www.facebook.com/idahobrewing/" target="_blank" rel="noopener noreferrer"><Img fluid={data.idahoBrewingCompany.childImageSharp.fluid} alt="Sockeye Brewing logo" /></a>
        </Col>
        <Col md={10}>
          <h2>Idaho Brewing Company</h2>
          {/* <h4>At OUTER LIGHT BREWING COMPANY, we understand the sacred oath between brewer and enthusiastic beer connoisseurs the world over. The connection begins with a quality product, it grows through the experience, and it endures through a relationship created in that oath. </h4> */}
        </Col>
      </Row>
   </Col>
  </Row>
  
  </div>
  )
}

export default Partners
