import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  
  .cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: #00b259;
      }

      .cls-3 {
        fill: #00818c;
      }
`;

const IdahoPowerLogo = () => {
    return (
      <a href="https://www.idahopower.com" target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.70001 75.1">
  <g id="Layer_2" data-name="Layer 2">
    <g id="idaho-power-logo">
      <g>
        <path className="cls-1" d="M77.3,48.7h0a5.96577,5.96577,0,0,1-5.9,5.4H5.9A5.91075,5.91075,0,0,1,0,48.2V6A5.91075,5.91075,0,0,1,5.9.1H71.4A5.91075,5.91075,0,0,1,77.3,6V48.7Z"/>
        <path className="cls-2" d="M71.7,21.8l5.6,3.4V46H59.4c-6.9,0-23.7-20.9-30.7-20.9H0V21.6l1.3-.5c.6-.2.5-.2,1,0l1,.4c.7.5.8.5,1.6.2L14,18c.7-.4.7-.2,1,0l1.2.9c.2.2.3.2.6,0l1.1-.7c.3-.2.3-.2.6,0l1.1.7c.3.2.4.1.7-.1l1.6-1.5c.3-.4.4-.3,1.2-.3.4,0,.4-.1.7-.4l3.5-3c.6-.5.7-.5,1-.1l.8,1.3c.3.4.5.3,1.4-.2l2.7-1.7c.4-.3.4-.2.6,0,.5.5.5.7,1.2.3l.6-.4c.2-.2.3,0,.3,0l3.2,3.6c.2.2.2.2.5,0l.4-.3c.3-.3.4-.2.6,0l5.8,4.7c.3.2.6.2,1-.6l2.3-4.9c.4-.7.6-.6,1.3.1L57,21c.4.3.7.5,1,.3l.8-.8a.20833.20833,0,0,1,.4,0l1.4.9c.5.3.5.2.9.1L62,21c.3-.3.3-.2.7.1l1.1.7c.3.2.4.2.6-.1l2-1.9c.1-.2.5-.3,1,.1L70,21.7a.74936.74936,0,0,0,.5.1c.8-.2.6-.4,1.2,0"/>
        <path className="cls-3" d="M64.7,15.9,77.3,23V6A5.91075,5.91075,0,0,0,71.4.1H5.9A5.91075,5.91075,0,0,0,0,6V17.4l3.2-1.3a2.04271,2.04271,0,0,1,2-.1l.7.2c1.1.4.9.4,2.2,0l10.2-4.1c1.8-.8,1.5-.8,2.6-.4l.3.1c.9.4.8.6,2.5-.7L34,2.7c2.7-2.1,2-2.1,4.9-.1l8.5,6.5c.9.7.8.6,1.3.5.7-.3.3-.3,1,.2L59.5,17c1.2.8,1.2.8,2.3,0l1.9-1.2c.5-.3.5-.3,1,.1"/>
        <path className="cls-3" d="M77.3,48.7a5.96577,5.96577,0,0,1-5.9,5.4H5.9A5.91075,5.91075,0,0,1,0,48.2v-20H18.9c7.6,0,22.5,20.6,29.7,20.6H77.3Z"/>
      </g>
      <g>
        <rect className="cls-3" x="81.9" y="0.6" width="7" height="25.3"/>
        <path className="cls-3" d="M97.8,6.8h4.1c4.1,0,4.5,3.6,4.5,6.1,0,2.2-.7,7-4.8,7H97.8ZM90.9,26h9.8c8.4,0,12.3-5.6,12.3-13.9,0-9-4-11.5-10.1-11.5h-12Z"/>
        <path className="cls-3" d="M123.5,14.7h-4.6l2.4-9.5h.1ZM109,26h6.9l1.4-5.5H125l1.5,5.5h7.1L126.6.7H116.1Z"/>
        <polygon className="cls-3" points="132.7 0.6 139.7 0.6 139.7 9.8 146.7 9.8 146.7 0.6 153.8 0.6 153.8 26 146.7 26 146.7 16.2 139.7 16.2 139.7 26 132.7 26 132.7 0.6"/>
        <path className="cls-3" d="M164.5,20.6c-4.2,0-4.9-4.4-4.9-7.7,0-3,.9-6,4.9-6s4.9,3,4.9,6c0,3.3-.7,7.7-4.9,7.7m0,6c8.1,0,11.5-5.8,11.5-13.7C176,3.1,172,0,164.5,0,157.1,0,153,3.1,153,12.9c.1,7.9,3.4,13.7,11.5,13.7"/>
        <path className="cls-3" d="M88.7,35h3.6c1.9,0,3.5.8,3.5,2.7,0,1.8-1.4,3.1-3.7,3.1H88.7ZM81.9,54.1h6.8V46.4H93c5.8,0,9.5-3.7,9.5-9.9,0-6.9-4.4-7.8-9.4-7.8H81.9Z"/>
        <path className="cls-3" d="M113.1,48.8c-4.2,0-4.9-4.4-4.9-7.7,0-3,.9-6,4.9-6s4.9,3,4.9,6c0,3.3-.7,7.7-4.9,7.7m0,5.9c8.1,0,11.5-5.8,11.5-13.7,0-9.7-4.1-12.9-11.5-12.9S101.6,31.2,101.6,41c0,8,3.4,13.7,11.5,13.7"/>
        <polygon className="cls-3" points="154.5 28.8 171.9 28.8 171.9 35 160.9 35 160.9 38.4 171.9 38.4 171.9 44.4 160.9 44.4 160.9 48.1 171.9 48.1 171.9 54.1 154.5 54.1 154.5 28.8"/>
        <path className="cls-3" d="M179.9,34.7h3.5c1.8,0,3.1.3,3.1,2.5a2.46666,2.46666,0,0,1-2.6,2.6h-4Zm-6.8,19.4h6.8v-10h.1l5.9,10h8.2l-7.6-11c3.6,0,6.9-2.6,6.9-7.6,0-6.1-4.4-6.7-8.8-6.7H173.1V54.1Z"/>
        <path className="cls-3" d="M121.7,28.8h7A140.97426,140.97426,0,0,1,131.8,49c.8-3.6,1.5-14.6,3-20.2h8c1.6,5.6,2.2,16.6,3,20.2.5-7.5,2.4-18.3,3.1-20.2h7l-6.3,25.3h-7.8c-.5-1.8-2.8-16.3-2.8-18.9,0,2.7-2.4,17.2-2.9,18.9H128Z"/>
      </g>
      <path className="cls-3" d="M196.1,50.9a3.182,3.182,0,0,1,3.3-3.2,3.24437,3.24437,0,0,1,3.3,3.2,3.182,3.182,0,0,1-3.3,3.2,3.12314,3.12314,0,0,1-3.3-3.2m3.3,2.7a2.54817,2.54817,0,0,0,2.6-2.7,2.66916,2.66916,0,0,0-2.6-2.7,2.60572,2.60572,0,0,0-2.6,2.7,2.54817,2.54817,0,0,0,2.6,2.7m-.7-.8h-.6V49.1h1.4c.9,0,1.3.3,1.3,1.1a.897.897,0,0,1-1,1l1.1,1.6h-.6l-1-1.6h-.6v1.6Zm.7-2.1c.5,0,.9,0,.9-.6,0-.5-.4-.6-.8-.6h-.8v1.2Z"/>
      <g>
        <path className="cls-3" d="M86.9,70.2H84.6l1.2-3.9h0Zm-4.7,3.3h1.3l.6-2.2h3l.6,2.2H89l-2.4-7.9H84.7Z"/>
        <path className="cls-3" d="M89.9,66.9H91v1.3h0a2.33776,2.33776,0,0,1,2.2-1.5,1.98574,1.98574,0,0,1,2.1,2.1v4.7H94.1V69.4c0-.6-.2-1.5-1.3-1.5-1.4,0-1.7,1.3-1.7,2.1v3.5H89.9Z"/>
        <rect className="cls-3" x="100" y="65.6" width="1.3" height="7.9"/>
        <path className="cls-3" d="M104.3,66.9h1.6c1.6,0,2.1.9,2.1,2.5s-.6,3-2.3,3h-1.4ZM103,73.5h2.4c2.7,0,3.8-2,3.8-4.2,0-2.8-1.3-3.7-3-3.7H103Z"/>
        <path className="cls-3" d="M114.6,70.2h-2.3l1.2-3.9h0Zm-4.7,3.3h1.3l.6-2.2h3l.6,2.2h1.3l-2.4-7.9h-1.9Z"/>
        <path className="cls-3" d="M123.3,72.6a3.58515,3.58515,0,0,1-2.6,1c-2.5,0-3.3-2.1-3.3-4.3,0-2.1,1-3.9,3.3-3.9a3.499,3.499,0,0,1,2.6,1v1.7h0a2.54519,2.54519,0,0,0-2.4-1.4c-1.2,0-2.1.9-2.1,2.8,0,1.7.7,2.9,2.2,2.9a2.73763,2.73763,0,0,0,2.5-1.6v1.8Z"/>
        <path className="cls-3" d="M129.9,69.5c0,1.4-.4,3-2.1,3s-2.1-1.6-2.1-3,.4-2.7,2.1-2.7,2.1,1.3,2.1,2.7m1.2-.1c0-2.1-1-3.9-3.3-3.9s-3.3,1.7-3.3,3.9.8,4.3,3.3,4.3,3.3-2.1,3.3-4.3"/>
        <path className="cls-3" d="M134,66.9h1.9c.7,0,1.2.3,1.2,1.1,0,1.1-.7,1.2-1.6,1.2H134Zm-1.4,6.6h1.3V69.6h0l2.9,3.9h1.6l-2.7-3.4h.4a2.12253,2.12253,0,0,0,2.2-2.3,1.99975,1.99975,0,0,0-2.2-2.2h-3.4v7.9Z"/>
        <path className="cls-3" d="M141,66.9h1.8a1.23628,1.23628,0,0,1,1.4,1.3c0,1-.4,1.6-1.4,1.6H141Zm-1.4,6.6h1.3V70.9h1.7a2.48634,2.48634,0,0,0,2.8-2.8,2.26465,2.26465,0,0,0-2.5-2.5h-3.2v7.9Z"/>
        <path className="cls-3" d="M155.2,72.6a3.51975,3.51975,0,0,1-2.5,1c-2.5,0-3.3-2.1-3.3-4.3,0-2.1,1-3.9,3.3-3.9a3.08671,3.08671,0,0,1,2.5,1v1.7h0a2.5452,2.5452,0,0,0-2.4-1.4c-1.2,0-2.1.9-2.1,2.8,0,1.7.7,2.9,2.1,2.9a2.73763,2.73763,0,0,0,2.5-1.6v1.8Z"/>
        <path className="cls-3" d="M161.3,70.1c0,1.4-.5,2.5-1.9,2.5s-1.9-1.1-1.9-2.5c0-1.2.5-2.2,1.9-2.2a1.91671,1.91671,0,0,1,1.9,2.2m1.1,0c0-2-.8-3.4-3-3.4s-3,1.4-3,3.4.8,3.6,3,3.6,3-1.6,3-3.6"/>
        <path className="cls-3" d="M163.8,66.9h1.1v1.3h0a2.13033,2.13033,0,0,1,2-1.4,1.84641,1.84641,0,0,1,1.9,1.6h0a1.94762,1.94762,0,0,1,2-1.6c1.7,0,1.9,1.6,1.9,2.5v4.3h-1.2V70.1c0-1-.1-2.1-1.2-2.1s-1.5,1.2-1.5,2.3v3.3h-1.2V70.1c0-1-.1-2.1-1.2-2.1s-1.5,1.2-1.5,2.3v3.3h-1.2V66.9Z"/>
        <path className="cls-3" d="M175.6,70.3c0-1.1.4-2.4,1.8-2.4s1.7,1,1.7,2.2-.4,2.5-1.7,2.5c-1.5,0-1.8-1.1-1.8-2.3m-1.2,4.8h1.2V72.6h0a1.96025,1.96025,0,0,0,1.9,1c1.8,0,2.6-1.3,2.6-3.5,0-1.7-.5-3.4-2.4-3.4a2.19382,2.19382,0,0,0-2.1,1.5h0V66.8h-1.1v8.3Z"/>
        <path className="cls-3" d="M184.6,71c0,.8-.6,1.8-1.5,1.8a.87552.87552,0,0,1-.9-.8c0-1.2,1.4-1.2,2.4-1.7h0Zm1.2-2.3a1.82681,1.82681,0,0,0-2.1-2,2.92673,2.92673,0,0,0-2.3,1V69a2.60885,2.60885,0,0,1,2.1-1.1c.5,0,1.2.2,1.2.9,0,.6-.4.7-1,.9-1.3.4-2.7.5-2.7,2.2a1.5755,1.5755,0,0,0,1.7,1.7,2.24069,2.24069,0,0,0,1.9-1.2h0v1.1h1.2V68.7Z"/>
        <path className="cls-3" d="M187.4,66.9h1.1v1.3h0a2.33776,2.33776,0,0,1,2.2-1.5,1.98574,1.98574,0,0,1,2.1,2.1v4.7h-1.2V69.4c0-.6-.2-1.5-1.3-1.5-1.4,0-1.7,1.3-1.7,2.1v3.5h-1.2V66.9Z"/>
        <polygon className="cls-3" points="196.1 73.2 193.8 66.9 195 66.9 196.7 71.9 196.7 71.9 198.3 66.9 199.5 66.9 196.7 75.1 195.5 75.1 196.1 73.2"/>
      </g>
    </g>
  </g>
</Logo>
</a>
)
}

export default IdahoPowerLogo
