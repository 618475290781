import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
      .cls-1 {
        fill: #1e4184;
      }

      .cls-2 {
        fill: #c7c3c2;
      }
`;

const HeclaMiningCompanyLogo = (props) => {
    return (
<a href="https://www.hecla-mining.com/" target="_blank" rel="noopener noreferrer">

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273.71002 210.29303">
  <defs>
    <style>

    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path fill={props.fillColor1} class="cls-1" d="M8.599,135.337H.4V6.33H250.555V135.337h-8.197V116.944c0-10.949-7.83-13.142-22.93-13.142s-23.419,1.385-23.419,10.445h15.772c0-2.839,2.468-2.796,7.647-2.796s7.153.956,7.153,4.408v3.887c-2.048-2.42-1.335-3.3-14.28-3.3-12.942,0-17.425,1.786-17.425,10.417,0,4.23,1.545,6.862,4.416,8.474h-9.251V91.696H174.509v43.641h-11.43c.248-.103.481-.184.713-.298,3.757-1.76,5.321-5.021,5.321-10.949H153.339c0,3.929-.234,5.074-7.385,5.074-5.961,0-7.382-.782-7.382-8.228,0-7.258,1.858-7.857,7.382-7.857,5.522,0,7.184.417,7.385,3.865h15.774c0-11.311-9.449-13.142-23.299-13.142-19.717,0-23.016,6.368-23.016,17.134,0,7.887,1.74006,12.236,7.672,14.401H110.609c4.127-1.569,6.511-4.35395,6.511-9.132H102.915c0,3.324-3.98,3.686-8.846,3.686-6.898,0-8.653-1.161-8.653-7.198H117.12v-1.757c0-12.943-5.412-17.134-23.478-17.134-19.347,0-23.143,5.276-23.143,17.134,0,7.663,1.739,12.155,8.088,14.401H65.325V91.696H46.341v16.405H27.553V91.696H8.566l.033,43.641Z"/>
        <path fill={props.fillColor1} class="cls-1" d="M217.955,124.42c3.452,0,7.768-.255,7.768,2.768,0,3.019-4.316,2.883-7.768,2.883-3.453,0-7.768.13605-7.768-2.883,0-3.023,4.315-2.768,7.768-2.768"/>
        <path fill={props.fillColor1} class="cls-1" d="M85.415,117.097c0-3.97,3.11-4.826,8.713-4.826,5.615,0,8.722.856,8.722,4.826Z"/>
        <polygon fill={props.fillColor1} class="cls-1" points="27.553 119.778 46.341 119.778 46.341 135.336 27.553 135.336 27.553 119.778 27.553 119.778"/>
        <polygon fill={props.fillColor1} class="cls-1" points="0.4 167.366 4.315 167.366 4.315 154.033 4.365 154.033 9.026 167.366 12.247 167.366 16.909 153.9 16.959 153.9 16.959 167.366 20.874 167.366 20.874 148.361 14.992 148.361 10.784 161.434 10.729 161.434 6.282 148.361 0.4 148.361 0.4 167.366 0.4 167.366"/>
        <polygon fill={props.fillColor1} class="cls-1" points="25.745 167.367 29.927 167.367 29.927 148.362 25.745 148.362 25.745 167.367 25.745 167.367"/>
        <polygon fill={props.fillColor1} class="cls-1" points="34.27 167.366 38.185 167.366 38.185 154.643 38.232 154.643 46.141 167.366 50.319 167.366 50.319 148.361 46.408 148.361 46.408 161.112 46.351 161.112 38.421 148.361 34.27 148.361 34.27 167.366 34.27 167.366"/>
        <polygon fill={props.fillColor1} class="cls-1" points="55.457 167.367 59.639 167.367 59.639 148.362 55.457 148.362 55.457 167.367 55.457 167.367"/>
        <polygon fill={props.fillColor1} class="cls-1" points="63.981 167.366 67.888 167.366 67.888 154.643 67.946 154.643 75.852 167.366 80.032 167.366 80.032 148.361 76.119 148.361 76.119 161.112 76.067 161.112 68.132 148.361 63.981 148.361 63.981 167.366 63.981 167.366"/>
        <path fill={props.fillColor1} class="cls-1" d="M98.534,167.366h2.663V157.09H93.212v3.116h4.201a4.11038,4.11038,0,0,1-4.521,4.104c-3.757,0-5.166-3.198-5.166-6.362,0-3.328,1.409-6.526,5.166-6.526a3.89289,3.89289,0,0,1,4.018,3.198h3.994c-.453-4.316-4.13-6.715-8.012-6.715-5.884,0-9.346,4.397-9.346,10.043,0,5.484,3.462,9.874,9.346,9.874a6.42855,6.42855,0,0,0,5.215-2.611l.427,2.155Z"/>
        <path fill={props.fillColor1} class="cls-1" d="M130.023,154.751c-.503-4.397-4.018-6.846-8.202-6.846-5.878,0-9.342,4.397-9.342,10.043,0,5.484,3.46405,9.874,9.342,9.874,4.661,0,7.937-3.033,8.364-7.773h-4.049c-.317,2.501-1.752,4.26-4.315,4.26-3.749,0-5.162-3.197-5.162-6.361,0-3.329,1.413-6.526,5.162-6.526a4.11166,4.11166,0,0,1,4.158,3.329Z"/>
        <path fill={props.fillColor1} class="cls-1" d="M132.342,157.948c0,5.484,3.45905,9.876,9.346,9.876,5.882,0,9.342-4.392,9.342-9.876,0-5.646-3.46-10.043-9.342-10.043-5.887,0-9.346,4.397-9.346,10.043m4.179,0c0-3.329,1.409-6.524,5.167-6.524,3.753,0,5.162,3.195,5.162,6.524,0,3.166-1.409,6.361-5.162,6.361-3.758,0-5.167-3.195-5.167-6.361"/>
        <polygon fill={props.fillColor1} class="cls-1" points="154.009 167.366 157.929 167.366 157.929 154.033 157.982 154.033 162.636 167.366 165.862 167.366 170.521 153.9 170.573 153.9 170.573 167.366 174.484 167.366 174.484 148.361 168.601 148.361 164.396 161.434 164.338 161.434 159.899 148.361 154.009 148.361 154.009 167.366 154.009 167.366"/>
        <path fill={props.fillColor1} class="cls-1" d="M179.096,167.366h4.178v-6.812h4.394c4.738,0,6.574-3.009,6.574-6.096,0-3.091-1.836-6.097-6.574-6.097h-8.572v19.005Zm4.178-15.753h3.249c1.944,0,3.675.422,3.675,2.845,0,2.424-1.731,2.849-3.675,2.849h-3.249v-5.694Z"/>
        <path fill={props.fillColor1} class="cls-1" d="M192.99005,167.365h4.237l1.488-4.227h7.108l1.44,4.227H211.6l-7.108-19.005h-4.285l-7.21695,19.005Zm9.29395-14.32h.05l2.399,6.977h-4.926l2.477-6.977Z"/>
        <polygon fill={props.fillColor1} class="cls-1" points="213.916 167.366 217.831 167.366 217.831 154.643 217.883 154.643 225.79 167.366 229.97 167.366 229.97 148.361 226.057 148.361 226.057 161.112 226.002 161.112 218.065 148.361 213.916 148.361 213.916 167.366 213.916 167.366"/>
        <polygon fill={props.fillColor1} class="cls-1" points="239.235 167.366 243.414 167.366 243.414 160.075 250.47 148.361 245.808 148.361 241.392 155.869 236.948 148.361 232.229 148.361 239.235 159.968 239.235 167.366 239.235 167.366"/>
        <path fill={props.fillColor2} class="cls-2" d="M224.156,68.905s-31.072,11.933-31.138,11.874L162.345,68.905V0h61.811V68.905Z"/>
        <g>
          <path fill={props.fillColor1} class="cls-1" d="M175.68457,46.58105V23.541h-3.84375V20.38965a6.65905,6.65905,0,0,0,3.0918-1.22949,6.01161,6.01161,0,0,0,2.11328-2.51954h3.15137V46.58105Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M189.31152,42.2832q3.104,0,3.1045-2.88574V37.63281q0-4.60254-4.82325-4.60254V29.166a4.7807,4.7807,0,0,0,3.84375-1.45411,5.402,5.402,0,0,0,1.14649-3.67382v-.69141q0-2.93409-2.5791-2.93359a2.284,2.284,0,0,0-2.10059,1.09179,4.13414,4.13414,0,0,0-.502,2.16016v.7832h-4.417v-.69238a8.00942,8.00942,0,0,1,1.624-5.20508,6.26164,6.26164,0,0,1,5.18066-2.292,6.46948,6.46948,0,0,1,5.08594,2.14844,6.99792,6.99792,0,0,1,1.71875,4.82324v1.26562a7.35435,7.35435,0,0,1-1.6709,4.82325,6.97615,6.97615,0,0,1-1.67187,1.45605,7.478,7.478,0,0,1,3.10449,3.41406,6.41876,6.41876,0,0,1,.57226,2.8418v2.36328a7.1271,7.1271,0,0,1-2.26757,5.44336,7.34234,7.34234,0,0,1-5.2295,2.05371,7.1318,7.1318,0,0,1-7.13867-7.13867V39.0127l4.29785-.334.09571,1.04981a2.875,2.875,0,0,0,.7998,1.83886A2.44667,2.44667,0,0,0,189.31152,42.2832Z"/>
        </g>
        <path fill={props.fillColor1} class="cls-1" d="M213.98633,39.75293a7.15088,7.15088,0,0,1-14.30176,0v-16.331a7.15092,7.15092,0,1,1,14.30176,0Zm-4.67969-16.42676a2.60735,2.60735,0,0,0-2.60254-2.60254,2.46836,2.46836,0,0,0-1.82617.76367,2.52485,2.52485,0,0,0-.75195,1.83887V39.68066a2.52325,2.52325,0,0,0,.75195,1.83887,2.46463,2.46463,0,0,0,1.82617.76367,2.60735,2.60735,0,0,0,2.60254-2.60254Z"/>
        <g>
          <path fill={props.fillColor1} class="cls-1" d="M176.11328,60.24023l-2.626-7.56738h2.15918l1.45313,4.67188,1.44336-4.67188h2.16894l-2.63671,7.56738v5.4502h-1.96192Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M182.71973,65.69043V52.67285h5.585v1.97266h-3.62305v3.5498h2.64746V60.168h-2.64746v3.55078h3.62305v1.97168Z"/>
        </g>
        <g>
          <path fill={props.fillColor1} class="cls-1" d="M192.6084,62.70117l-.47656,2.98926h-2.043l2.21093-13.00684H194.999l2.17969,13.00684h-2.0625l-.459-2.98926Zm1.03027-7.03808-.72656,5.12793h1.4541Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M202.36719,52.68359a2.99146,2.99146,0,0,1,2.4082.90332,3.284,3.284,0,0,1,.67481,2.2002v1.88965a3.04027,3.04027,0,0,1-1.04883,2.33594l1.45312,5.67773H203.7334L202.543,60.791h-1.30762v4.89941h-1.96191V52.68359ZM203.54,55.81836a.99608.99608,0,0,0-1.12109-1.13184h-1.1836v4.16309h1.1836a1.07078,1.07078,0,0,0,.79394-.332,1.10076,1.10076,0,0,0,.32715-.79981Z"/>
        </g>
        <path fill={props.fillColor1} class="cls-1" d="M213.7959,62.45215a3.66011,3.66011,0,0,1-.70606,2.32519,2.5449,2.5449,0,0,1-1.34961.92383,3.686,3.686,0,0,1-1.08984.14551,3.08272,3.08272,0,0,1-1.91016-.57129,3.20159,3.20159,0,0,1-1.00195-1.27148,5.10972,5.10972,0,0,1-.43066-1.84278l1.86816-.13476a2.84023,2.84023,0,0,0,.60254,1.64843,1.003,1.003,0,0,0,.80957.36524,1.21524,1.21524,0,0,0,1.02734-.63379,1.57168,1.57168,0,0,0,.19726-.8623,2.39386,2.39386,0,0,0-.73632-1.61036q-.58153-.55077-1.74414-1.65234a7.428,7.428,0,0,1-1.38086-1.69336,3.91439,3.91439,0,0,1-.43555-1.8291,3.17846,3.17846,0,0,1,1.19336-2.69043,2.96651,2.96651,0,0,1,1.82715-.55078,3.31857,3.31857,0,0,1,1.7959.4668,2.69777,2.69777,0,0,1,.93945,1.01757,3.77553,3.77553,0,0,1,.43067,1.50489l-1.87891.34277a2.26928,2.26928,0,0,0-.457-1.24609.96017.96017,0,0,0-.77832-.32129.89723.89723,0,0,0-.82031.47754,1.83919,1.83919,0,0,0-.22852.95507,2.86971,2.86971,0,0,0,.76856,1.81641,6.39591,6.39591,0,0,0,.87207.83008q.68554.57129.90332.7998a6.6769,6.6769,0,0,1,1.12109,1.43262,5.63836,5.63836,0,0,1,.30078.6123A3.57429,3.57429,0,0,1,213.7959,62.45215Z"/>
        <polygon fill={props.fillColor2} class="cls-2" points="244.843 194.291 251.84 178.293 155.639 178.293 96.2 178.293 0 178.293 6.996 194.291 0 210.293 96.2 210.293 155.639 210.293 251.84 210.293 244.843 194.291 244.843 194.291"/>
        <g>
          <path fill={props.fillColor1} class="cls-1" d="M16.98145,185.77734h2.33593v12.665h5.65576v1.9668H16.98145Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M33.64453,198.11426c0,.40918.24609.77832.57373.77832a.92191.92191,0,0,0,.36914-.06153v1.43457a2.6398,2.6398,0,0,1-1.08594.26661c-.98388,0-1.80371-.38965-1.86523-1.45508h-.041a2.86811,2.86811,0,0,1-2.7456,1.61914c-1.7627,0-2.86914-.9834-2.86914-3.09473,0-2.459,1.06543-2.97168,2.56152-3.46289l1.72119-.47168c.79931-.22558,1.29151-.45117,1.29151-1.41406,0-1.00391-.34864-1.63965-1.5166-1.63965-1.47559,0-1.68067,1.0459-1.68067,2.11133H26.26758c0-2.33594.96289-3.627,3.873-3.627,1.94678,0,3.50391.75781,3.50391,2.70507Zm-2.08984-3.52539a14.47292,14.47292,0,0,1-2.45948,1.02539,1.80956,1.80956,0,0,0-.90136,1.78222c0,.92286.45068,1.66016,1.373,1.66016,1.332,0,1.9878-1.00391,1.9878-2.623Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M36.10693,189.38379h2.21338v1.72168h.041a2.72618,2.72618,0,0,1,2.56152-2.00781,1.70543,1.70543,0,0,1,.53272.082v2.2539a4.16361,4.16361,0,0,0-.92188-.123c-1.00439,0-2.21338.45117-2.21338,2.5v6.59863H36.10693Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M44.98291,201.35156a1.44482,1.44482,0,0,0,1.59863,1.31153,1.83592,1.83592,0,0,0,1.86475-2.06934v-1.7832h-.041a2.66791,2.66791,0,0,1-2.5,1.5166c-2.582,0-3.29931-2.541-3.29931-5.55371,0-2.418.36865-5.67578,3.5039-5.67578a2.45318,2.45318,0,0,1,2.41846,1.63867h.041v-1.35254h2.08984V199.876c0,2.68457-1.14746,4.30371-4.11865,4.30371-3.27881,0-3.64795-1.92578-3.64795-2.82813Zm1.55762-2.78711c1.72119,0,1.90576-2.17187,1.90576-3.91406,0-1.80273-.22559-3.791-1.7627-3.791-1.59814,0-1.86474,1.63964-1.86474,3.68847C44.81885,196.43359,44.90088,198.56445,46.54053,198.56445Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M54.74023,195.18359v.63575c0,1.47461.18457,3.36035,1.84473,3.36035,1.57764,0,1.76221-1.80371,1.76221-2.459h2.09032a3.64436,3.64436,0,0,1-3.873,3.97559c-1.80371,0-4.03711-.5332-4.03711-5.63574,0-2.88965.61475-5.96289,4.07813-5.96289,3.07373,0,3.89355,1.84375,3.89355,4.918v1.168Zm3.54541-1.5166v-.59375c0-1.39355-.45068-2.46-1.70068-2.46-1.41406,0-1.84473,1.39356-1.84473,2.82813v.22558Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M67.28564,192.60156v-.28711c0-.88086-.30712-1.70117-1.45507-1.70117-.88086,0-1.57764.41016-1.57764,1.43457,0,.86133.38916,1.209,1.55713,1.61914l1.41406.49219a2.98663,2.98663,0,0,1,2.39795,3.17578c0,2.35742-1.72168,3.36133-3.95508,3.36133-2.78711,0-3.62744-1.291-3.62744-3.42285v-.40918h1.96729v.34765c0,1.27051.45068,1.96778,1.72119,1.96778,1.20946,0,1.80371-.61524,1.80371-1.61914a1.60506,1.60506,0,0,0-1.209-1.61914l-1.80371-.63477a2.93764,2.93764,0,0,1-2.35644-3.17676c0-2.06933,1.49609-3.03222,3.77051-3.03222,2.80761,0,3.44287,1.63867,3.44287,3.01172v.49218Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M70.48535,189.38379h1.5166V186.249h2.21289v3.13477h1.80323v1.63965H74.21484v6.57812c0,.86035.2461,1.168,1.0044,1.168a4.2072,4.2072,0,0,0,.79883-.06152v1.63965a7.323,7.323,0,0,1-1.78272.18457c-1.49609,0-2.2334-.41016-2.2334-2.66407v-6.84472h-1.5166Z"/>
        </g>
        <g>
          <path fill={props.fillColor1} class="cls-1" d="M84.665,185.77734v10.49219c0,1.84473.73779,2.66406,2.15185,2.66406,1.39307,0,2.13086-.81933,2.13086-2.66406V185.77734h2.33643V196.085c0,3.38184-1.68066,4.61133-4.46729,4.61133-2.80761,0-4.48828-1.22949-4.48828-4.61133V185.77734Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M93.19336,197.7041H95.5293v2.70508H93.19336Z"/>
        </g>
        <g>
          <path fill={props.fillColor1} class="cls-1" d="M100.043,195.87988v.36914c0,1.78321.77881,2.68457,2.398,2.68457a2.074,2.074,0,0,0,2.21289-2.13086,2.45827,2.45827,0,0,0-2.08985-2.60253l-1.59863-.55372c-2.13135-.81933-3.05322-1.92578-3.05322-4.0166,0-2.66406,1.80322-4.13965,4.54932-4.13965,3.75,0,4.13916,2.33692,4.13916,3.85254v.32813h-2.33594v-.30762c0-1.332-.59424-2.11035-2.04932-2.11035a1.868,1.868,0,0,0-1.96728,2.11035c0,1.25.63525,1.88574,2.19287,2.48047l1.57763.57324c2.06983.73828,2.97168,1.86524,2.97168,3.87305,0,3.11523-1.88525,4.40625-4.83642,4.40625-3.62695,0-4.44678-2.418-4.44678-4.46777v-.34864Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M108.55127,197.7041h2.33594v2.70508h-2.33594Z"/>
        </g>
        <g>
          <path fill={props.fillColor1} class="cls-1" d="M118.67578,195.87988v.36914c0,1.78321.77881,2.68457,2.39795,2.68457a2.074,2.074,0,0,0,2.21289-2.13086,2.45827,2.45827,0,0,0-2.08984-2.60253l-1.59864-.55372c-2.13134-.81933-3.05322-1.92578-3.05322-4.0166,0-2.66406,1.80322-4.13965,4.54932-4.13965,3.75,0,4.13916,2.33692,4.13916,3.85254v.32813h-2.33594v-.30762c0-1.332-.59424-2.11035-2.04932-2.11035a1.868,1.868,0,0,0-1.96728,2.11035c0,1.25.63525,1.88574,2.19287,2.48047l1.57764.57324c2.06982.73828,2.97168,1.86524,2.97168,3.87305,0,3.11523-1.88526,4.40625-4.83643,4.40625-3.62695,0-4.44678-2.418-4.44678-4.46777v-.34864Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M127.67578,185.61328h2.21338v2.21387h-2.21338Zm0,3.77051h2.21338v11.02539h-2.21338Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M132.43213,185.77734H134.645v14.63184h-2.21289Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M136.1626,189.38379h2.37695l1.92725,8.68945h.041l1.74121-8.68945h2.35645l-2.90918,11.02539h-2.582Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M147.92969,195.18359v.63575c0,1.47461.18457,3.36035,1.84375,3.36035,1.57812,0,1.76269-1.80371,1.76269-2.459H153.626a3.64388,3.64388,0,0,1-3.87305,3.97559c-1.80273,0-4.03711-.5332-4.03711-5.63574,0-2.88965.61523-5.96289,4.07813-5.96289,3.07421,0,3.89354,1.84375,3.89354,4.918v1.168Zm3.54492-1.5166v-.59375c0-1.39355-.45117-2.46-1.70117-2.46-1.41406,0-1.84375,1.39356-1.84375,2.82813v.22558Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M155.71973,189.38379h2.21386v1.72168h.04a2.72688,2.72688,0,0,1,2.5625-2.00781,1.707,1.707,0,0,1,.53223.082v2.2539a4.159,4.159,0,0,0-.92188-.123c-1.0039,0-2.21289.45117-2.21289,2.5v6.59863h-2.21386Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M167.65039,185.77734h4.63184c2.80664,0,4.03613,1.7627,4.03613,4.2627a3.9632,3.9632,0,0,1-4.28223,4.32422h-2.0498v6.04492h-2.33594Zm2.33594,6.82422h1.66015a2.20712,2.20712,0,0,0,2.33594-2.52051c0-1.57812-.63476-2.541-2.5-2.541h-1.49609Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M177.96191,189.38379h2.21387v1.72168h.04a2.72687,2.72687,0,0,1,2.5625-2.00781,1.707,1.707,0,0,1,.53223.082v2.2539a4.15912,4.15912,0,0,0-.92188-.123c-1.0039,0-2.21289.45117-2.21289,2.5v6.59863h-2.21387Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M188.292,189.09766c3.21778,0,4.11914,2.377,4.11914,5.79882,0,3.42286-1.04492,5.79981-4.11914,5.79981-3.05273,0-4.09765-2.37695-4.09765-5.79981C184.19434,191.47461,185.0957,189.09766,188.292,189.09766Zm0,9.959c1.61914,0,1.90625-1.35254,1.90625-4.16016,0-2.43847-.28711-4.16015-1.90625-4.16015-1.59765,0-1.88476,1.72168-1.88476,4.16015C186.40723,197.7041,186.69434,199.05664,188.292,199.05664Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M199.95605,199.05664h-.041a2.35608,2.35608,0,0,1-2.418,1.63965c-2.582,0-3.60645-2.15234-3.60645-5.94336,0-4.89746,1.72071-5.65527,3.40137-5.65527a2.56719,2.56719,0,0,1,2.5,1.57714h.041v-4.89746h2.21289v14.63184h-2.08985Zm-2.00878-8.19726c-1.27051,0-1.72071,1.02441-1.72071,3.99609,0,2.5.32715,4.07812,1.86426,4.07812,1.5166,0,1.86523-1.55761,1.86523-3.91406C199.95605,192.376,199.64844,190.85938,197.94727,190.85938Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M210.02148,199.11816H209.96a3.164,3.164,0,0,1-2.82812,1.57813c-1.63965,0-2.7461-.75878-2.7461-3.60645v-7.70605h2.21387V197.294c0,1.22949.55273,1.63964,1.49512,1.63964a1.72924,1.72924,0,0,0,1.80371-1.84375v-7.70605h2.21289v11.02539h-2.08985Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M221.89062,196.39258c-.14355,2.64355-1.04589,4.30371-3.77148,4.30371-3.19629,0-4.09766-2.37695-4.09766-5.79981,0-3.42187.90137-5.79882,4.09766-5.79882,3.29981-.041,3.791,2.582,3.791,3.95507h-2.21289c0-1.02539-.28711-2.35742-1.57813-2.3164-1.59766,0-1.88476,1.72168-1.88476,4.16015s.2871,4.16016,1.88476,4.16016c1.22949.02051,1.68066-1.00391,1.68066-2.66406Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M225.66406,195.18359v.63575c0,1.47461.18457,3.36035,1.84375,3.36035,1.57813,0,1.7627-1.80371,1.7627-2.459h2.08984a3.64388,3.64388,0,0,1-3.87305,3.97559c-1.80273,0-4.0371-.5332-4.0371-5.63574,0-2.88965.61523-5.96289,4.07812-5.96289,3.07422,0,3.89356,1.84375,3.89356,4.918v1.168Zm3.54492-1.5166v-.59375c0-1.39355-.45117-2.46-1.70117-2.46-1.41406,0-1.84375,1.39356-1.84375,2.82813v.22558Z"/>
          <path fill={props.fillColor1} class="cls-1" d="M233.4541,189.38379H235.668v1.72168h.04a2.72687,2.72687,0,0,1,2.5625-2.00781,1.707,1.707,0,0,1,.53222.082v2.2539a4.15893,4.15893,0,0,0-.92187-.123c-1.00391,0-2.21289.45117-2.21289,2.5v6.59863H233.4541Z"/>
        </g>
      </g>
      <g>
        <path fill={props.fillColor1} class="cls-1" d="M257.94538,14.22015a3.56575,3.56575,0,0,0,.05957.68213,1.18782,1.18782,0,0,0,.22558.51758,1.1003,1.1003,0,0,0,.44239.33008,1.75872,1.75872,0,0,0,.69726.12012,1.38006,1.38006,0,0,0,.89258-.32276,1.20944,1.20944,0,0,0,.39746-.99756,1.77594,1.77594,0,0,0-.09766-.62207,1.239,1.239,0,0,0-.32226-.47265,2.29779,2.29779,0,0,0-.59278-.38282,8.53035,8.53035,0,0,0-.90722-.35205,7.71516,7.71516,0,0,1-1.24512-.52539,3.33617,3.33617,0,0,1-.87793-.66748,2.4023,2.4023,0,0,1-.51758-.88476,3.74817,3.74817,0,0,1-.16406-1.1626,2.99245,2.99245,0,0,1,.88477-2.37012A3.54829,3.54829,0,0,1,259.25006,6.33a5.2733,5.2733,0,0,1,1.32715.15722,2.79177,2.79177,0,0,1,1.05078.51026,2.386,2.386,0,0,1,.68946.8999,3.16994,3.16994,0,0,1,.248,1.3125v.2998h-2.07031a2.14175,2.14175,0,0,0-.27051-1.17724.99309.99309,0,0,0-.89941-.4126,1.50628,1.50628,0,0,0-.60059.105,1.01338,1.01338,0,0,0-.38183.27783.94617.94617,0,0,0-.19532.39746,2.03165,2.03165,0,0,0-.05273.46485,1.55055,1.55055,0,0,0,.21.83252,1.88654,1.88654,0,0,0,.90039.62256l1.665.71972a5.109,5.109,0,0,1,1.00488.5625,2.76165,2.76165,0,0,1,.623.63037,2.11629,2.11629,0,0,1,.32227.74219,4.186,4.186,0,0,1,.08984.8999,2.94973,2.94973,0,0,1-.98242,2.46778,4.32682,4.32682,0,0,1-2.73731.77246,3.58688,3.58688,0,0,1-2.61816-.79492,3.10615,3.10615,0,0,1-.78711-2.28028v-.43506h2.16016Z"/>
        <path fill={props.fillColor1} class="cls-1" d="M267.41022,6.54,268.94049,14.1h.03028L270.51569,6.54H273.71v10.71h-1.97949V8.70013h-.03027L269.795,17.24994H268.1153L266.21,8.70013h-.02929v8.54981h-1.98047V6.54Z"/>
      </g>
    </g>
  </g>
</svg>
</a>
    )
}
export default HeclaMiningCompanyLogo
