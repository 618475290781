import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  
  .cls-1 {
        fill: #005da6;
      }

      .cls-1, .cls-2, .cls-3 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #00a7e2;
      }

      .cls-3 {
        fill: #00a1db;
      }
`;

const AlbertsonsLogo = () => {
    return (
<a href="https://www.albertsons.com/" target="_blank" rel="noopener noreferrer" >
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.99997 146.6376">
  <defs>
    <style>

    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M118.32363,0S69.59057,57.89488,50.48726,80.117c-4.87331,6.23784-5.84787,16.37418-2.33907,23.58666a32.45172,32.45172,0,0,0,3.50877,5.45816L12.67062,108.967c-5.45819-6.43274-8.967-15.00972-6.62756-22.61212,1.36422-5.45819,5.65273-9.35666,9.16149-13.84028C35.86756,48.14823,56.33515,24.17152,76.99818,0Z"/>
        <path class="cls-2" d="M122.22213.58488c23.00211,27.29028,43.85968,52.63153,66.86175,79.92213,3.89851,5.45783,5.06818,14.42483,1.94913,20.85753a18.98887,18.98887,0,0,1-6.04275,7.99217c-12.86541,0-25.926.19512-38.59665,0,5.45816-5.84789,7.01759-15.7894,3.8988-23.78153-2.53423-6.43271-8.38215-11.11122-12.67064-16.95907l-36.25724-43.08v-.39c7.01758-7.99213,13.84-16.17913,20.6627-24.56125Z"/>
        <path class="cls-1" d="M109.35666,49.7076c3.70364,6.82272,2.72909,16.37421-1.75423,22.02727-2.924,4.67848-6.43277,9.1618-8.77217,14.03514C94.93179,75.43848,83.62578,67.8364,86.93969,54.77577c2.14425-7.79724,8.187-13.45026,12.08576-20.27272,3.50881,5.06817,7.21239,10.13634,10.33121,15.20455Z"/>
        <path class="cls-1" d="M87.32939,76.02333A27.42333,27.42333,0,0,1,99.02545,92.39757a26.38625,26.38625,0,0,1,22.2221-20.27271c5.45816-.19487,11.30608,0,16.76424,0,1.75426,8.38215-2.14421,16.56911-8.38209,21.24755-5.26333,4.28849-12.47574,4.48336-19.49336,4.48336a14.087,14.087,0,0,0-10.72123,9.55182c-.1948.58455-.38966.97454-.58485,1.55939h0a13.463,13.463,0,0,0-8.96664-10.52636c-5.84818-1.36457-12.67058,0-17.93391-2.92393A22.223,22.223,0,0,1,59.64909,77.58305,26.97561,26.97561,0,0,1,59.844,71.93c9.55178.19487,20.07815-1.3645,27.48543,4.09334Z"/>
        <path class="cls-3" d="M107.21244,120.07819c.39,1.55943-.38975,3.31362.39,4.48333,1.16938.38972,2.7291-.19487,3.31359.97457a9.348,9.348,0,0,1,0,2.3394c1.55972-4.09366,6.43277-3.89883,10.52636-3.89883,2.14428,0,4.67851.39,6.043,2.14428a6.145,6.145,0,0,1-2.33941,5.653,2.12381,2.12381,0,0,1-2.14421.39c-.58459-.19515-.97458-.19515-1.16941-.78.19483-.97451,1.36457-2.3391.38973-3.50845-.77975-.78-2.53394-.78-3.70364-.39005-.58485.19486-.77971.77972-.97458,1.1697-.38995,1.75429.77972,2.534,2.14428,3.11882,3.31362,1.55937,8.187,2.14422,8.57695,6.8227,0,2.72912-.39,5.45789-2.72913,6.82246-3.70361,1.94938-9.35663,1.1697-13.25539.19512-2.14428-.97487-1.94909-3.89876-1.75429-6.043a1.60015,1.60015,0,0,1,.97457-.97457,2.58511,2.58511,0,0,1,1.94939,1.16944c.77971,2.33937,2.92392,2.72906,5.0682,3.11908a3.11,3.11,0,0,0,2.92389-.39c.39-.58482.19516-1.16938.39-1.94938-1.75455-2.33911-5.26329-2.534-7.79723-4.09334a4.98942,4.98942,0,0,1-2.924-3.314,23.79731,23.79731,0,0,1-.19516-5.263c-.77968,1.55937-3.31359.5845-4.09333,1.94909a60.59674,60.59674,0,0,0,.19489,11.8909c.58485,1.75455,3.11876,1.3645,2.33908,3.50877-1.5594,1.5594-4.28849.77972-6.43274.97455-1.55939-.19483-3.31394-.77968-3.89847-2.33911-.58485-2.33908-.19519-4.67844,0-7.01755v-6.43271c-.77968-1.55942-2.924-.77971-4.09366-1.1697-1.55936.19513-3.31391.19513-4.28849,1.55969l-.58485.97454c-.19483,4.09363-.77965,7.40729.19486,11.11122.19516.77965,1.16974,1.16937.97484,2.14421-2.3394,2.53394-6.043.77972-8.77207,1.16941-.58485-.19483-1.55943-.38969-1.75429-1.16941-.19483-1.1697,1.16944-1.55936,1.36459-2.72906.3897-4.09366.19483-8.77214.19483-12.86547.39-1.94942-3.11878-2.53427-1.1697-4.28846,2.33941-.58488,4.87335-1.16974,7.40758-.58488,1.16937.77971.38966,2.14427.97451,3.11878,1.1697-.97451,2.14428-2.14421,3.70367-2.5339,2.14421-.77975,5.0685.58452,6.82269-1.36457,2.14428-1.36456,3.70368-2.92392,5.65306-4.28849a1.22745,1.22745,0,0,1,1.55942.58485Z"/>
        <path class="cls-3" d="M36.84213,116.95908c.19484,4.67854-.19485,9.16186-.19485,13.84034a105.63434,105.63434,0,0,0,.19485,11.11092c0,1.55969,2.72907,2.33937.97456,3.7039a5.19153,5.19153,0,0,1-2.33911.58456L18.71334,146.004c-.58452-.19486-1.55939-.58485-1.36455-1.3646.19518-1.16934,1.55969-2.14421.97486-3.31359-.58485-1.55968-.39-3.50877-1.36457-5.06849-2.92392-.3897-6.4327.58485-7.99211-2.53394l-.39.19483c-.77971,3.11879-2.72909,6.043-2.14423,9.55185a2.11565,2.11565,0,0,1,.77968,1.75422c-1.16937,1.5594-3.50877.58486-5.263.77972C1.1697,145.61424.39,145.61424,0,144.83427L.19486,143.47h0c2.924-7.7973,5.8482-15.39974,8.577-23.00211.39-1.36456-1.75423-2.924,0-3.8985,3.31394-.39,7.21274-.58485,10.13665.58452l.7797,1.1697c2.33911,7.60244,4.28849,15.20455,6.23787,23.00214.38971.77969.77972,2.14425,2.14426,2.14425a3.10335,3.10335,0,0,0,.97455-1.94942A97.78819,97.78819,0,0,0,28.85,123.00212c0-1.3645-2.53425-2.33937-.97485-3.89876q3.50907-1.46187,7.01757-2.92393c.77969,0,1.75455,0,1.9494.77965Zm-24.75636,6.6276c-1.1697,2.3394-1.55939,5.06846-2.3391,7.60243.97454.97484,2.72909.39,4.09366.58485.77968-.19486,2.14424.39,2.14424-.77968-.39-2.33914-1.1697-4.67847-1.55972-7.01762a1.61875,1.61875,0,0,0-2.33908-.39Z"/>
        <path class="cls-3" d="M192.20271,125.1464c1.55936.97454.58485,3.31359.39,4.87327a4.43412,4.43412,0,0,1-3.89876,2.33915c-.38969-.19487-.97454.19479-1.16973-.39-.58453-1.36454,1.55968-3.50881-.38966-4.67816a3.7784,3.7784,0,0,0-2.924.38966,2.13765,2.13765,0,0,0-.58485,2.33937c2.72912,4.09337,9.94151,2.33915,10.72122,7.99214.39,2.924-.39,5.84788-2.53423,7.21244a21.00987,21.00987,0,0,1-14.23.19487c-.38966,0-.58452-.58485-.97451-.58485-1.94938,1.94938-5.263,1.1697-7.99216,1.1697-2.14421-.19486-2.14421-2.72913-2.729-4.28849-1.1697-3.70367-1.5594-7.7973-3.314-11.30605a2.12341,2.12341,0,0,0-2.14421-.38976,2.6772,2.6772,0,0,0-.97455,1.55943,91.56415,91.56415,0,0,0,.3897,10.33124c0,1.3645,2.14421,1.75449,1.36449,3.31391-.77964,1.36457-2.53393.97455-4.0933.97455-1.94938-.19483-4.28878.39-5.653-.97455-.39-1.1697.58453-1.94941.97451-2.72909.38973-4.09366,0-8.38212,0-12.86544.19487-1.75423-3.50874-3.31391-.58485-4.48332,2.33914-.77975,5.45819-1.36457,7.40761-.39.38969.58482,0,1.75452.77971,1.75452,2.53391-1.36453,5.653-3.704,8.77181-1.94941.97455.77971.97455,2.33907,1.75456,3.314,1.16934,4.67812,2.339,9.35667,3.70364,14.03485a3.10119,3.10119,0,0,0,1.75422,1.75449c.58485-1.55936-.58485-3.119.58485-4.28846,2.72906-.97487,2.72906,3.11879,5.263,3.11879,1.55969.39,3.704.97487,5.0685,0a2.19964,2.19964,0,0,0,.58453-2.14421c-1.7542-2.14428-4.873-1.75429-7.01726-3.11879-1.55969-.77972-3.31392-1.75455-3.89877-3.50881-.39-2.53424-.58485-5.06821.58485-7.40754,2.92393-2.72913,7.21242-2.534,11.11122-2.33915,1.36423.39,2.72877.39,3.89847,1.16974Z"/>
        <path class="cls-3" d="M148.343,127.29064a33.24047,33.24047,0,0,1,1.55939,14.6197,4.96239,4.96239,0,0,1-3.11882,3.89877c-3.31391,1.36456-7.60237.58485-11.50087.77971a6.74163,6.74163,0,0,1-5.26337-4.87334c-.97451-4.87334-1.75422-11.50091,1.16971-15.59454,3.70367-3.314,9.94151-2.33911,14.42483-1.36453a6.78909,6.78909,0,0,1,2.72913,2.53423Zm-10.52637.38966c-1.36459.58486-1.1694,2.33937-1.36459,3.50881.19519,3.89877-.19483,7.99214,1.75458,11.306a3.50738,3.50738,0,0,0,3.11876.39,2.37947,2.37947,0,0,0,1.75455-1.5594,44.37713,44.37713,0,0,0-1.36453-12.67064c-.58485-1.55969-2.5342-1.1697-3.89877-.97484Z"/>
        <path class="cls-3" d="M47.75817,117.73879c.39,2.72913-.19483,5.653.19515,8.38215,1.75428-.19486,2.7288-1.55942,4.48336-1.75429a3.72765,3.72765,0,0,1,2.92394.77975c3.3139,4.09333,4.48334,9.74635,4.09361,15.39938a5.472,5.472,0,0,1-3.70364,4.87334c-4.28847,1.36453-9.74665.58485-14.62.77968-.39-.38969-1.36456-.38969-1.55943-.97455-.19483-.97454.58487-1.55942.77972-2.33907a125.002,125.002,0,0,0,0-19.10335c.19483-1.36456-1.94938-1.16973-1.1697-2.72909a22.23257,22.23257,0,0,1,7.21241-4.09366,1.305,1.305,0,0,1,1.36454.77971Zm1.16971,11.89093a1.23582,1.23582,0,0,0-1.16971.97454c-.77969,2.14422-.19483,5.06847-.38967,7.60241a6.9055,6.9055,0,0,0,.77969,4.48332,4.28324,4.28324,0,0,0,2.72908.19519,1.8745,1.8745,0,0,0,1.55941-1.75455,18.17963,18.17963,0,0,0-2.14423-11.111,1.11841,1.11841,0,0,0-1.36457-.38995Z"/>
        <path class="cls-3" d="M78.36239,127.48544A10.43366,10.43366,0,0,1,79.727,134.503c-.38969,1.36456-1.94906,1.55943-3.11876,2.14428.39.58485,1.36453.77964,1.94938,1.1697,1.36454.58449.97452,2.14421.97452,3.31362a6.60471,6.60471,0,0,1-4.0936,4.67848,19.8189,19.8189,0,0,1-10.13637-.19487c-1.94942-.39-2.92393-2.3394-3.50878-3.89876a27.12136,27.12136,0,0,1,.77971-14.23c1.75449-3.89876,6.62786-3.50878,10.52637-3.31391a6.24066,6.24066,0,0,1,5.263,3.31391Zm-7.99207-.1948c-.58488,0-1.16941.58485-1.55943,1.16935a11.96569,11.96569,0,0,0-.19483,6.43276c1.55936.58486,3.31365-.19486,4.48336-.97457a8.11337,8.11337,0,0,0-.58485-6.04269,2.79032,2.79032,0,0,0-2.14425-.58485ZM74.8539,137.427a18.25645,18.25645,0,0,0-5.653,1.16971.71415.71415,0,0,0-.78.77971c.19519,1.55943.58485,3.11879,2.14457,3.89847a5.202,5.202,0,0,0,3.50849-.58485,4.85974,4.85974,0,0,0,1.36456-4.48332l-.58459-.77972Z"/>
        <path class="cls-3" d="M194.54178,123.78183a2.7291,2.7291,0,0,1,5.45819,0,2.7291,2.7291,0,1,1-5.45819,0Zm5.26336,0a2.58055,2.58055,0,0,0-2.53423-2.53423,2.41723,2.41723,0,0,0-2.33914,2.53423,2.252,2.252,0,0,0,2.33914,2.33911,2.41724,2.41724,0,0,0,2.53423-2.33911Zm-1.16973,1.5594h-.38966l-.97484-1.5594H196.686v1.5594h-.38966v-3.11876h1.36453a1.17232,1.17232,0,0,1,.77965.1948c.19486,0,.19486.38969.19486.58485,0,.58456-.38966.77971-.97451.77971l.97451,1.5594Zm-1.3645-1.75455c.39,0,1.16964,0,1.16964-.58456,0-.39-.58479-.58485-.97484-.58485H196.686v1.16941Z"/>
      </g>
    </g>
  </g>
</Logo>
</a>
    )
}

export default AlbertsonsLogo;
