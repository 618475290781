import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const ConPaulosLogo = (props) => {
    const data = useStaticQuery(graphql`
{
  conPaulosLogo: file(relativePath: { eq: "conPaulos@2x.png" }) {
    childImageSharp {
      fluid(maxWidth: 250, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`
    )

return (
    <>
    <a href="https://www.conpaulos.com" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.conPaulosLogo.childImageSharp.fluid} alt="Con Paulos Logo" />
    </a>
    </>
)

}

export default ConPaulosLogo