import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  cls-1 {
        fill: #003767;
      }

`;

const AvistaLogo = (props) => {
    return (
<a href="https://myavista.com/" target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.1748 19.95751">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path fill={props.fillColor} class="cls-1" d="M52.6748,19.897h9.459c5.3945,0,5.6953-4.40429,5.0156-5.65819-1.4668-2.711-4.8457-2.5196-4.8457-3.6216a.68309.68309,0,0,1,.7734-.7129h5.0703l.627-3.15331H60.4131c-1.2989,0-5.5996.50781-5.5996,4.58741,0,3.1308,5.1738,3.2402,5.0683,4.6914a.78811.78811,0,0,1-.873.7148H53.292Z"/>
        <polygon fill={props.fillColor} class="cls-1" points="21.668 7.048 27.705 6.058 28.802 0 20.989 0 21.668 7.048"/>
        <polygon fill={props.fillColor} class="cls-1" points="27.481 7.148 21.794 8.214 21.857 8.769 27.353 7.929 27.481 7.148"/>
        <polygon fill={props.fillColor} class="cls-1" points="27.13 8.978 21.97 9.935 21.961 10.494 26.972 9.678 27.13 8.978"/>
        <polygon fill={props.fillColor} class="cls-1" points="18.48 12.329 17.958 12.397 17.991 19.889 19.379 19.889 18.48 12.329"/>
        <polygon fill={props.fillColor} class="cls-1" points="20.482 12.012 20.016 12.069 19.979 19.889 21.361 19.889 20.482 12.012"/>
        <polygon fill={props.fillColor} class="cls-1" points="20.375 0 18.952 0 19.627 7.24 20.367 7.108 20.375 0"/>
        <polygon fill={props.fillColor} class="cls-1" points="16.372 10.919 6.543 12.305 5.39 13.657 16.438 11.471 16.372 10.919"/>
        <polygon fill={props.fillColor} class="cls-1" points="21.965 11.686 21.961 19.889 24.817 19.889 26.767 10.796 21.965 11.686"/>
        <polygon fill={props.fillColor} class="cls-1" points="17.65 7.599 18.364 7.457 18.369 0 16.965 0 16.986 0.231 17.65 7.599"/>
        <polygon fill={props.fillColor} class="cls-1" points="16.561 12.637 4.842 14.305 0 19.958 8.902 19.958 11.768 16.96 17.023 16.96 16.561 12.637"/>
        <polygon fill={props.fillColor} class="cls-1" points="16.378 7.855 16.38 0.615 8.8 9.52 16.378 7.855"/>
        <polygon fill={props.fillColor} class="cls-1" points="16.38 9.172 8.25 10.31 7.201 11.536 16.378 9.731 16.38 9.172"/>
        <polygon fill={props.fillColor} class="cls-1" points="44.596 19.889 51.136 19.889 53.761 6.751 47.135 6.751 44.596 19.889"/>
        <polygon fill={props.fillColor} class="cls-1" points="84.251 6.747 70.267 6.747 69.642 9.896 73.399 9.896 71.397 19.889 77.872 19.889 79.862 9.896 83.622 9.896 84.251 6.747"/>
        <path fill={props.fillColor} class="cls-1" d="M92.1025,19.88921h6.8946L96.6728,6.72071H87.4775l-7.6152,13.1685h6.5742l.8262-1.5215h4.5957Zm-3.3965-4.334,2.0938-4.3575.168.0137.5566,4.3438Z"/>
        <polygon fill={props.fillColor} class="cls-1" points="43.691 6.751 38.931 6.751 38.511 7.652 43.691 6.751"/>
        <polygon fill={props.fillColor} class="cls-1" points="38.155 8.431 35.101 15.01 34.935 15.01 34.819 9.111 28.628 10.374 29.761 19.889 39.031 19.889 46.021 6.825 38.155 8.431"/>
        <polygon fill={props.fillColor} class="cls-1" points="34.77 6.769 34.77 6.751 28.198 6.751 28.315 7.778 34.77 6.769"/>
        <polygon fill={props.fillColor} class="cls-1" points="28.422 8.652 28.51 9.387 34.803 8.294 34.786 7.431 28.422 8.652"/>
        <path cfill={props.fillColor} class="cls-1" d="M98.7978,7.89751a1.0563,1.0563,0,1,1,1.0567,1.0918A1.04554,1.04554,0,0,1,98.7978,7.89751Zm1.0567,1.3076a1.3,1.3,0,1,0-1.3203-1.3076A1.30586,1.30586,0,0,0,99.8545,9.20511Zm-.2735-1.2022h.2618l.3925.6494h.2559l-.4258-.6611a.39041.39041,0,0,0,.3887-.40921c0-.30079-.1758-.42669-.5313-.42669h-.5703v1.497h.2285Zm0-.1953v-.4609h.3086c.1602,0,.3282.0361.3282.2207,0,.2285-.17189.2402-.3672.2402Z"/>
      </g>
    </g>
  </g>
</Logo>
</a>
    )
}

export default AvistaLogo
