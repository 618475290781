import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  /* .cls-1 {
        fill: #17468f;
      }

      .cls-2 {
        fill: #087933;
      } */
`;

const IdahoForestGroupLogo = (props) => {
    return (
		<a href="https://ifg.com" target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481.04395 127.51141">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
        <path fill={props.fillColor} class="cls-1" d="M0,50.62469H.375c4.50635,0,5.24121.856,5.24121,6.10547V86.54071c0,5.25-.73486,6.10547-5.24121,6.10547H0v2.19043H18.03125V92.64618h-.375c-4.38281,0-5.09717-.85547-5.09717-6.10547V56.73016c0-5.36377.7417-6.10547,6.10547-6.10547h.375V48.43427H0Z"/>
        <path fill={props.fillColor} class="cls-1" d="M37.22363,62.13348H23.69434V63.8913h.375c2.67871,0,3.25341.35839,3.25341,4.16113V88.91766c0,3.69434-.49463,4.16114-4.41259,4.16114h-.375v1.75781H40.44922c8.72656,0,17.561-5.42578,17.561-15.79688C58.01025,66.63593,45.5791,62.13348,37.22363,62.13348Zm-4.8164,5.919c0-3.67139.89746-4.16113,4.46338-4.16113,9.46777,0,15.3496,5.84277,15.3496,15.249,0,8.59766-5.418,13.93848-14.14013,13.93848-5.23877,0-5.67285-1.63477-5.67285-3.45508Z"/>
        <path fill={props.fillColor} class="cls-1" d="M84.24854,86.611,76.4624,67.2077c-.55713-1.39843-.895-2.4873-1.14209-3.28271-.45361-1.46143-.68115-2.19483-1.54248-2.19483-.54931,0-.7998.22412-1.19287.76856L70.25,65.925a1.55822,1.55822,0,0,0-.3252,1.11865,18.11961,18.11961,0,0,1-1.08251,2.98242c-.209.51074-.43262,1.05567-.65577,1.61621l-4.04834,10.0791C60.0249,91.90985,59.229,92.71161,56.13574,93.08173l-.33056.03906v1.71582H66.522V93.09637l-.35742-.01757c-2.65137-.125-3.0166-1.03223-3.0166-1.99414a8.7029,8.7029,0,0,1,.90967-3.24414L66.146,82.33759H77.207l2.34766,6.01171a9.96009,9.96009,0,0,1,.8042,3.29c0,.59961-.24024,1.12207-3.19532,1.44141l-.335.03613v1.71973H90.50439V93.112l-.33984-.03125C87.14453,92.79657,86.62012,92.41766,84.24854,86.611Zm-7.8584-6.43457H67.00977l2.44531-6.1914c.08887-.22559.22705-.56739.39648-.98438.50733-1.25,1.29346-3.18652,1.834-4.72656.126.39551.27783.8418.44629,1.27832Z"/>
        <path fill={props.fillColor} class="cls-1" d="M115.60645,63.8913h.375c3.90673,0,4.40039.46679,4.40039,4.16113v8.49512H100.36768V68.05243c0-3.65576.522-4.16113,4.29687-4.16113h.375V62.13348H91.10449V63.8913h.375c3.19336,0,3.67432.54443,3.67432,4.16113V88.91766c0,3.65528-.478,4.16114-3.9336,4.16114h-.375v1.75781h14.3501V93.0788h-.375c-3.95312,0-4.45263-.4668-4.45263-4.16114V79.01141h20.01416v9.90625c0,3.53223-.34522,4.16114-4.66016,4.16114h-.375v1.75781h14.35059V93.0788h-.375c-3.27344,0-3.72608-.50586-3.72608-4.16114V68.05243c0-3.65576.45264-4.16113,3.72608-4.16113h.375V62.13348H115.60645Z"/>
        <path fill={props.fillColor} class="cls-1" d="M148.61279,61.73016c-10.436,0-17.72461,6.88965-17.72461,16.75489s7.28858,16.75488,17.72461,16.75488c10.43555,0,17.72364-6.88965,17.72364-16.75488S159.04834,61.73016,148.61279,61.73016Zm0,31.752c-10.04394,0-12.15234-8.15528-12.15234-14.99707S138.56885,63.488,148.61279,63.488c10.04346,0,12.15137,8.15527,12.15137,14.99707S158.65625,93.48212,148.61279,93.48212Z"/>
        <path fill={props.fillColor} class="cls-1" d="M318.876,64.21014c-1.02832,4.66065-1.41407,5.53858-7.48243,5.53858h-8.61914l.28125-16.03272c.07129-2.57861.34571-2.80322,1.71289-2.80322h5.90528c9.375,0,9.49414.17383,15.748,9.27l.11132.1626h2.60254l-.18066-.50147a62.707,62.707,0,0,1-1.67969-6.30127c-.37305-1.5957-.75781-3.24511-1.19141-4.832l-.07519-.27636H289.9209v2.19042h.375c4.93066,0,5.67285.79883,5.67285,6.10547,0,5.0376-.09766,13.24512-.18359,20.48731-.041,3.50781-.08008,6.77637-.10352,9.31836-.06738,5.10937-.58691,6.11035-6.39453,6.11035h-.375v2.19043H309.6084V92.64618h-.375c-5.874,0-6.39844-1.001-6.46582-6.10547V72.947H312.042c4.834,0,6.0459,1.10742,6.8291,6.23438l.04883.31835h2.28125v-15.584h-2.26074Z"/>
        <path fill={props.fillColor} class="cls-1" d="M343.43066,61.73016c-10.043,0-17.05761,6.88965-17.05761,16.75489s7.01465,16.75488,17.05761,16.75488,17.05664-6.88965,17.05664-16.75488S353.47363,61.73016,343.43066,61.73016Zm0,31.752c-9.64648,0-11.67089-8.15528-11.67089-14.99707S333.78418,63.488,343.43066,63.488c9.64551,0,11.66993,8.15527,11.66993,14.99707S353.07617,93.48212,343.43066,93.48212Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M388.14258,90.47137,380.125,78.61493c2.918-.80371,7.22559-2.83887,7.22559-7.84082,0-5.57178-4.96582-8.64063-13.98243-8.64063H363.21484V63.8913h.375c2.72071,0,3.30469.35839,3.30469,4.16357.02735,3.854-.01953,7.437-.06933,11.23-.04,3.07227-.082,6.25-.082,9.63281,0,3.65528-.46875,4.16114-3.85839,4.16114h-.375v1.75781h13.70214V93.0788h-.375c-3.52246,0-4.00976-.50586-4.00976-4.16114V79.51532h3.80566l8.5586,13.28516c1.10254,1.70312,1.708,2.03613,2.38086,2.03613H392.542V93.0788h-.375C390.543,93.0788,389.37793,92.32391,388.14258,90.47137Zm-6.17871-19.79882c0,5.12793-2.47364,6.8291-10.13672,6.88183V66.33856c0-1.69726.22851-2.24609,2.95215-2.24609C378.35547,64.09247,381.96387,66.12714,381.96387,70.67255Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M421.47949,86.75946c-3.15234,5.27051-3.99707,6.11719-8.64844,6.11719h-6.65332c-2.47656,0-2.85058-.39258-2.85058-1.74121V78.80927h5.27051c3.38476,0,4.12695.72558,4.66894,4.5664l.04492.32227h1.80957V71.96063h-1.79l-.06054.30078c-.71094,3.50391-.94824,4.085-5.127,4.085h-4.8164V65.73407c0-1.38135.08008-1.6416,2.59863-1.6416h4.335c4.627,0,5.96289,1.65478,8.17481,4.39648l.1123.13965h1.43653l-1.19629-6.49512H393.80762V63.8913h.375c3.56738,0,4.06054.50537,4.06054,4.16113,0,7.16016-.09961,14.23047-.20117,20.86035-.04687,3.53613-.374,4.166-4.36328,4.166h-.375v1.75781h28.04688l.0625-.29785c.082-.38965.18066-.87793.29-1.41992a59.51693,59.51693,0,0,1,1.40626-6.03223l.19628-.50977h-1.71679Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M437.22949,75.80341c-3.87988-1.77832-7.54394-3.458-7.54394-7.24707,0-3.45752,3.35547-5.06836,5.623-5.06836,4.01953,0,6.1084,3.77929,7.15332,6.94921l.085.25782H444V63.38739l-.2041-.10449a16.43824,16.43824,0,0,0-7.68067-1.55274c-8.05175,0-10.90918,4.709-10.90918,8.74121,0,5.74317,4.57422,7.69532,8.99707,9.584,3.9004,1.66406,7.585,3.2373,7.585,7.35058,0,4.17481-3.56739,6.07618-6.88282,6.07618-5.88672,0-8.209-6.33985-8.97168-8.42285l-.09082-.2461h-1.42871l.03809.40918c.02637.293.05273.708.084,1.19727.21485,3.32715.47852,6.37793,1.34766,7.16113,1.21,1.09473,6.58887,1.65918,8.61816,1.65918,8.11524,0,11.76563-5.76074,11.76563-9.85059C446.26758,79.947,441.67285,77.84052,437.22949,75.80341Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M479.82031,61.02411l-.10644.0957c-1.36817,1.22608-1.69336,1.51758-4.79,1.51758h-18.9502c-1.99511,0-3.20019-.43164-4.15722-1.48975l-.11231-.12353h-1.5166l-2.35742,9.26758h1.82422l.10254-.209c1.47363-2.99072,3.54-4.98242,8.83691-4.98242,3.65723,0,3.65723,0,3.65723,3.85938,0,2.668-.14356,7.749-.26953,12.23242-.09864,3.4834-.1836,6.49121-.1836,7.72558,0,3.53223-.36426,4.16114-4.917,4.16114h-.375v1.75781H471.7207V93.09637l-.35644-.01757c-4.02539-.19434-4.78516-.85547-4.78516-4.16114,0-1.22461.085-4.22754.1836-7.7041.127-4.48828.2705-9.57519.2705-12.2539,0-4.06055.24024-4.06055,4.26172-4.06055,5.13477,0,7.16113.77246,8.30664,5.11328l.07324.2793H481.044V61.02411Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M120.70605,119.47137h.375c1.23243,0,1.82374.11719,2.10938.419.26855.2832.35449.83008.29736,1.88574l-.124,2.25391c-.07861,1.76074-2.24268,2.13086-4.04395,2.13086-2.68115,0-7.75146-1.8086-7.75146-8.6543,0-4.9043,3.50488-8.89453,7.81348-8.89453,3.39209,0,5.54834,1.74609,6.59179,5.33887l.07862.2705h1.22168l-.00879-.38378c-.01026-.43555-.00684-.89649-.00342-1.35938a19.92309,19.92309,0,0,0-.09277-2.67285l-.021-.15137-.12061-.09277a12.53563,12.53563,0,0,0-7.769-2.29883c-6.57324,0-11.52978,4.48145-11.52978,10.42383,0,4.73242,3.666,9.82519,11.71484,9.82519a14.0565,14.0565,0,0,0,7.17187-1.91113l.18262-.10644.06543-3.69239c.0293-2.13379.19287-2.33008,1.94287-2.33008h.375v-1.3496h-8.4751Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M180.77832,124.43231l-4.79-6.87793c2.79932-.80078,4.332-2.47558,4.332-4.75781,0-3.41406-3.09619-5.29492-8.71777-5.29492H165.2334v1.35058h.375c1.59619,0,1.88037.17188,1.88037,2.32715.01709,2.2959-.01172,4.43164-.04248,6.69239-.0249,1.83984-.05029,3.71484-.05029,5.7246,0,2.08594-.228,2.3252-2.22022,2.3252h-.375v1.34961h8.69092v-1.34961h-.375c-2.09668,0-2.313-.21777-2.313-2.3252v-5.44531h2.11182l5.20361,7.84278c.71191,1.06835,1.11816,1.27734,1.5791,1.27734h3.80469v-1.34961h-.375A2.69057,2.69057,0,0,1,180.77832,124.43231ZM176.72754,112.737c0,2.90625-1.44971,3.8916-5.92383,3.94238v-6.52246c0-.8623,0-1.18457,1.66406-1.18457C174.58789,108.97235,176.72754,110.13641,176.72754,112.737Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M229.32422,107.26239c-6.24268,0-10.603,4.16309-10.603,10.124,0,5.96192,4.36035,10.125,10.603,10.125s10.60254-4.16308,10.60254-10.125C239.92676,111.42548,235.56689,107.26239,229.32422,107.26239Zm0,1.34961c5.79346,0,7.00976,4.77148,7.00976,8.77441s-1.2163,8.77442-7.00976,8.77442c-5.79443,0-7.01074-4.77149-7.01074-8.77442S223.52979,108.612,229.32422,108.612Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M289.69824,108.85223h.375a2.84728,2.84728,0,0,1,2.11035.582,2.27853,2.27853,0,0,1,.4502,1.72851l-.30859,8.39941c-.166,4.31739-1.67286,5.999-5.373,5.999-3.52637,0-5.80469-1.92481-5.80469-4.9043v-9.48047c0-1.95605.08984-2.32422,2.93164-2.32422h.375v-1.35058h-9.124v1.35058h.375c1.99219,0,2.2207.23926,2.2207,2.32422v9.41992c0,4.52344,2.876,6.915,8.31641,6.915,6.94531,0,7.86035-4.21484,7.94531-7.38086l.24707-7.02246c.12109-3.71875.46191-4.05175,2.32324-4.25781l.334-.03711v-1.31152h-7.39356Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M341.21289,107.50165h-8.8418v1.35058h.375c2.15332,0,2.375.2168,2.375,2.32422v12.41992c0,2.08594-.23144,2.3252-2.251,2.3252h-.375v1.34961h8.78418v-1.34961h-.375c-2.15332,0-2.375-.21777-2.375-2.3252v-12.2998c0-2.085.21191-2.32422,2.06543-2.32422.80468,0,4.8164.2002,4.8164,4.1543,0,3.09863-2.02051,4.60449-6.17578,4.60449h-.375v1.4707h.375c6.17969,0,9.583-2.21093,9.583-6.22461C348.81836,108.94012,344.88965,107.50165,341.21289,107.50165Z"/>
        <path fill={props.fillColor}  class="cls-1" d="M191.85638,49.81409A35.84736,35.84736,0,1,1,256.8916,70.61224l9.9715,9.9715a49.81409,49.81409,0,1,0-78.31873,0l9.9715-9.9715A35.67154,35.67154,0,0,1,191.85638,49.81409Z"/>
        <polygon fill={props.fillColor2}  class="cls-2" points="227.704 65.78 248.084 86.16 265.122 86.16 227.704 48.742 190.285 86.16 207.323 86.16 227.704 65.78"/>
        <polygon fill={props.fillColor2}  class="cls-2" points="227.704 83.894 241.664 97.855 251.58 97.855 227.704 73.978 203.827 97.855 213.743 97.855 227.704 83.894"/>
        </g>
        </g>
</Logo></a>
	)
	}
	export default IdahoForestGroupLogo

