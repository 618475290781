import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as style from "../Supporters.module.css"

import AvistaLogo from "../AvistaLogo"

import IdahoPowerLogo from '../IdahoPowerLogo'
import BuckKnivesLogo from "../BuckKnivesLogo"
import LitehouseFoodsLogo from "../LitehouseFoodsLogo"


const SilverLevel = () => {
    const data = useStaticQuery(graphql`
    {
      olympicSteel: file(relativePath: { eq: "olympic-steel-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      riverClub: file(relativePath: { eq: "river-club-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      infinitySigns: file(relativePath: { eq: "infinity-signs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <div className="tab-content">
        <hr />
        <h1 className="text-center">Silver Level Corporate Donors - $10,000+</h1>
        
    <Row className={`${style.silverLevel} my-4`}>

      <Col lg={6} mb={2}>
        <Row style={{alignItems: 'center', height: '100%'}}>
      <Col md={3} className="text-center">
        <AvistaLogo fillColor="#003767"/>
        <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
      </Col>
      <Col md={9}>
        <h2>Avista Corp.</h2>        
      </Col>
      </Row>
      </Col>

      <Col lg={6} mb={2}>
        <Row style={{alignItems: 'center', height: '100%'}}>
      <Col md={3} className="text-center">
        <BuckKnivesLogo />
        <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
      </Col>
      <Col md={9}>
        <h2>Buck Knives</h2>        
      </Col>
      </Row>
      </Col>

      <Col lg={6} mb={2}>
        <Row style={{alignItems: 'center', height: '100%'}}>
      <Col md={3} className="text-center">
      <LitehouseFoodsLogo />
        <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
      </Col>
      <Col md={9}>
        <h2>Litehouse Foods</h2>        
      </Col>
      </Row>
      </Col>

      <Col lg={6} mb={2}>
        <Row style={{alignItems: 'center', height: '100%'}}>
        <Col md={3} className="text-center">
      <a href="https://www.olysteel.com" target="_blank" rel="noopener noreferrer"><Img fluid={data.olympicSteel.childImageSharp.fluid} alt="Olympic Steel logo" /></a>
        <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
      </Col>
      <Col md={9}>
        <h2>Olympic Steel</h2>        
      </Col>
      </Row>
      </Col>
      <Col lg={6} mb={2}>
      <Row style={{alignItems: 'center', height: '100%'}}>
        <Col md={3} className="text-center">
              <a href="https://bestboisesigns.com/" target="_blank" rel="noopener noreferrer">
              <Img
                fluid={data.infinitySigns.childImageSharp.fluid}
                alt="Infinity Signs Logo"
                style={{ maxWidth: "162px" }}
              />
               <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
              </a>
            </Col>
        
            <Col >
              <h2>Infinity Signs Northwest Boise, Idaho</h2>
            </Col>
          </Row> 
        </Col> 
        <Col lg={6} mb={2}>
          <Row style={{alignItems: 'center', height: '100%'}}>
          <Col md={3} className="text-center">
                <a href="https://www.riverclubboise.com/" target="_blank" rel="noopener noreferrer">
                <Img
                  fluid={data.riverClub.childImageSharp.fluid}
                  alt="Infinity Signs Logo"
                  style={{ maxWidth: "162px" }}
                />
                 <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
                </a>
              </Col>
          
              <Col >
                <h2>River Club Boise</h2>
              </Col>
            </Row> 
          </Col>
    </Row>
  </div>
  )
}

export default SilverLevel
