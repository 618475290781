import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  
  .cls-1 {
    
        /* fill: #008145; */
      }

      .cls-2 {
        fill: #ec1c24;
      }
`;

const BankofIdahoLogo = (props) => {
    return (

<a href="https://www.bankofidaho.com" target="_blank" rel="noopener noreferrer">
  <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.99995 110.8001">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path fill={props.fillcolor} d="M13.63563,6.81782A6.81782,6.81782,0,1,1,6.81782,0a6.81772,6.81772,0,0,1,6.81781,6.81782"/>
      <path fill={props.fillcolor} d="M13.63563,71.44192a6.81782,6.81782,0,1,1-6.81781-6.81781,6.81773,6.81773,0,0,1,6.81781,6.81781"/>
      <path fill={props.fillcolor} d="M13.63563,55.2866a6.81782,6.81782,0,1,1-6.81781-6.81782,6.81773,6.81773,0,0,1,6.81781,6.81782"/>
      <path fill={props.fillcolor} d="M13.63563,22.97455a6.81782,6.81782,0,1,1-6.81781-6.81782,6.81772,6.81772,0,0,1,6.81781,6.81782"/>
      <path fill={props.fillcolor} d="M13.63563,39.12987a6.81782,6.81782,0,1,1-6.81781-6.81781,6.81773,6.81773,0,0,1,6.81781,6.81781"/>
      <path fill={props.fillcolor} d="M13.63563,87.59866a6.81782,6.81782,0,1,1-6.81781-6.81782,6.81772,6.81772,0,0,1,6.81781,6.81782"/>
      <path fill={props.fillcolor} d="M30.1954,71.44192a6.81782,6.81782,0,1,1-6.81781-6.81781,6.81773,6.81773,0,0,1,6.81781,6.81781"/>
      <path fill={props.fillcolor} d="M30.1954,55.2866a6.81782,6.81782,0,1,1-6.81781-6.81782A6.81773,6.81773,0,0,1,30.1954,55.2866"/>
      <path fill={props.fillcolor} d="M30.1954,39.12987a6.81782,6.81782,0,1,1-6.81781-6.81781,6.81773,6.81773,0,0,1,6.81781,6.81781"/>
      <path fill={props.fillcolor} d="M30.1954,87.59866a6.81782,6.81782,0,1,1-6.81781-6.81782,6.81772,6.81772,0,0,1,6.81781,6.81782"/>
      <path fill={props.fillcolor} d="M46.75376,87.59866a6.81782,6.81782,0,1,1-6.81782-6.81782,6.81773,6.81773,0,0,1,6.81782,6.81782"/>
      <path fill={props.fillcolor} d="M63.31353,71.44192a6.81852,6.81852,0,1,0-.00141,0"/>
      <path fill={props.fillcolor} d="M63.31353,55.2866a6.81852,6.81852,0,1,0-.00141,0"/>
      <path fill={props.fillcolor} d="M63.31353,87.59866a6.81852,6.81852,0,1,0-.00141,0"/>
      <path fill={props.fillcolor} d="M79.09964,24.07938V37.17387h7.53088a9.22869,9.22869,0,0,0,4.27135-.78635A6.153,6.153,0,0,0,93.43,34.02708a6.48561,6.48561,0,0,0,.90049-3.37227,6.22072,6.22072,0,0,0-3.99091-5.95819,11.51832,11.51832,0,0,0-4.215-.61724Zm0-17.59273v11.86h6.46269a11.927,11.927,0,0,0,2.24912-.1691,8.0966,8.0966,0,0,0,1.51773-.44955,6.58673,6.58673,0,0,0,2.69725-2.22093,6.05359,6.05359,0,0,0,1.0682-3.62452A4.79242,4.79242,0,0,0,92.111,8.764a6.0151,6.0151,0,0,0-2.72684-1.82776,12.09827,12.09827,0,0,0-3.76544-.44954ZM73.14145.75393H85.22553a22.44746,22.44746,0,0,1,6.12589.675,11.3535,11.3535,0,0,1,5.59321,3.68089,10.23519,10.23519,0,0,1,2.05183,6.605,10.14747,10.14747,0,0,1-1.37822,5.255,10.81475,10.81475,0,0,1-3.79363,3.79222A10.5148,10.5148,0,0,1,96.691,22.50528a10.32329,10.32329,0,0,1,2.05183,2.38863,10.47242,10.47242,0,0,1,1.2077,2.7818,11.424,11.424,0,0,1,.39458,2.9791,12.35529,12.35529,0,0,1-2.08,7.10952A11.24518,11.24518,0,0,1,92.476,42.12024a13.33088,13.33088,0,0,1-2.47319.59046,25.02722,25.02722,0,0,1-3.485.1973H73.14146Z"/>
      <path fill={props.fillcolor} d="M119.95862,6.65576h-.22548l-4.77726,17.76183h9.89133Zm-6.57683,23.43818L109.95315,42.908h-6.12589L115.6309.75534h8.5427L135.97584,42.908H129.85l-3.42863-12.81406Z"/>
      <path fill={props.fillcolor} d="M146.59856,10.92852l-.00141,1.12315c.00141.30016.00987.64542.02818,1.04.01973.39318.0296.75957.0296,1.09638V42.908H140.9208L140.92221.75534h7.08135l15.06317,31.97948h.05356q-.05285-.56229-.05356-1.12315V.75534H168.798L168.79663,42.908h-7.1363l-15.0054-31.97948h-.05778Z"/>
      <path fill={props.fillcolor} d="M200.948,42.908,189.25712,21.4385l-5.95679,7.41814V42.908h-5.95819V.75394h5.95819V20.42668l.055.055q.28113-.3361.61864-.78635.279-.33611.64683-.78634.36359-.45026.70319-.89909L199.48664.75394h7.30681l-13.32138,15.9073L207.918,42.908h-6.97Z"/>
      <path fill={props.fillcolor} d="M217.22455,22.41932a3.89831,3.89831,0,0,0-.18742-1.33172,1.62677,1.62677,0,0,0-3.08057,0,3.86387,3.86387,0,0,0-.18742,1.33172V24.085a3.87865,3.87865,0,0,0,.18742,1.33313,1.62744,1.62744,0,0,0,3.08057,0,3.91278,3.91278,0,0,0,.18742-1.33313Zm2.9354,1.894a7.00341,7.00341,0,0,1-.09442,1.2077,6.09784,6.09784,0,0,1-.23817.95827,4.01542,4.01542,0,0,1-1.62483,2.07156,5.32625,5.32625,0,0,1-5.41141,0,4.00829,4.00829,0,0,1-1.62483-2.07156,5.94671,5.94671,0,0,1-.23817-.95827,7.00341,7.00341,0,0,1-.09442-1.2077V22.191a7.00341,7.00341,0,0,1,.09442-1.2077,6.02287,6.02287,0,0,1,.23817-.95827,4.0049,4.0049,0,0,1,1.62483-2.07015,5.3175,5.3175,0,0,1,5.41141,0,4.012,4.012,0,0,1,1.62483,2.07015,6.17993,6.17993,0,0,1,.23817.95827A7.00341,7.00341,0,0,1,220.16,22.191Z"/>
      <path fill={props.fillcolor} d="M228.98591,15.32106c-.12543-.01409-.26352-.02395-.41572-.031-.1536-.007-.29171-.01128-.41714-.01128a2.69529,2.69529,0,0,0-1.53041.34386,1.49377,1.49377,0,0,0-.489,1.30212v.49886h2.56056V19.9419h-2.56056v9.13881h-2.9354V19.9419h-1.72631V17.42362h1.72631v-.6877a3.96368,3.96368,0,0,1,1.2077-3.21584,5.00774,5.00774,0,0,1,3.31026-1.009c.27762,0,.53409.007.76944.01973.23675.0141.403.0296.50027.04228Z"/>
      <rect fill={props.fillcolor} x="72.88215" y="48.42087" width="8.93447" height="45.89977"/>
      <path fill={props.fillcolor} d="M103.358,85.75258a8.01314,8.01314,0,0,0,3.764-.7638,5.40359,5.40359,0,0,0,2.29421-2.54083,6.69205,6.69205,0,0,0,.52-1.89681,17.47766,17.47766,0,0,0,.1536-2.38723V64.63961a17.4774,17.4774,0,0,0-.1536-2.38722,6.7046,6.7046,0,0,0-.52-1.89682,5.417,5.417,0,0,0-2.29421-2.54083,8.02957,8.02957,0,0,0-3.764-.7652H97.48294v28.703ZM88.60907,94.32205V48.42087h14.50371a17.21337,17.21337,0,0,1,9.17967,2.29563,13.54067,13.54067,0,0,1,5.50865,6.70226,16.431,16.431,0,0,1,.8554,3.151,22.54125,22.54125,0,0,1,.30721,3.88663V78.28793a22.535,22.535,0,0,1-.30721,3.88522,16.44548,16.44548,0,0,1-.8554,3.15243,13.54327,13.54327,0,0,1-5.50865,6.70085,17.21337,17.21337,0,0,1-9.17967,2.29562Z"/>
      <path fill={props.fillcolor} d="M139.34388,59.68055h-.24521l-3.54842,14.50513h7.46606Zm-5.99765,22.828-3.05941,11.81211h-9.11908l12.8507-45.89978h10.52689l12.85211,45.89978h-9.11767L145.219,82.50855Z"/>
      <path fill={props.fillcolor} d="M160.27506,48.42087h8.93446V66.108H183.041V48.42087h8.93589V94.32065H183.041v-19.646H169.20952v19.646h-8.93446Z"/>
      <path fill={props.fillcolor} d="M220.06553,63.966a18.71072,18.71072,0,0,0-.1522-2.50841,6.76143,6.76143,0,0,0-.5214-1.89823,5.1352,5.1352,0,0,0-2.38581-2.50982,7.1777,7.1777,0,0,0-3.12142-.73279h-.6736a7.09568,7.09568,0,0,0-3.151.73279,5.8033,5.8033,0,0,0-2.90724,4.40805,22.53664,22.53664,0,0,0-.12261,2.50841V78.77551a22.54585,22.54585,0,0,0,.12261,2.50983,5.79883,5.79883,0,0,0,2.90724,4.40663,7.0591,7.0591,0,0,0,3.151.73421h.6736a7.14,7.14,0,0,0,3.12142-.73421,5.11984,5.11984,0,0,0,2.3858-2.50841,6.76111,6.76111,0,0,0,.52141-1.89822,18.718,18.718,0,0,0,.1522-2.50983V63.966Zm-21.97119-.12261a19.76647,19.76647,0,0,1,1.102-7.03765,14.13323,14.13323,0,0,1,2.3858-3.88663,14.5356,14.5356,0,0,1,3.306-2.81563,15.10044,15.10044,0,0,1,3.97683-1.71361,16.51646,16.51646,0,0,1,4.346-.5806h.67361a16.18636,16.18636,0,0,1,4.315.5806,14.54359,14.54359,0,0,1,3.94583,1.746,15.47363,15.47363,0,0,1,3.306,2.81422,13.62375,13.62375,0,0,1,2.38581,3.85563A18.24345,18.24345,0,0,1,229,63.84339V78.89953a18.23852,18.23852,0,0,1-1.1626,7.03765,13.6447,13.6447,0,0,1-2.38581,3.85563,15.56188,15.56188,0,0,1-3.306,2.81562,14.53594,14.53594,0,0,1-3.94583,1.74321,16.18775,16.18775,0,0,1-4.315.582h-.67361a16.518,16.518,0,0,1-4.346-.582,15.00834,15.00834,0,0,1-3.97683-1.71362,14.39445,14.39445,0,0,1-3.306-2.81562,14.104,14.104,0,0,1-2.3858-3.88523,19.45764,19.45764,0,0,1-1.102-7.03765Z"/>
      <path fill={props.fillcolor} d="M5.5157,107.74631a1.90545,1.90545,0,0,0,.13387-.55383,7.311,7.311,0,0,0,.03946-.823H8.03116a9.8771,9.8771,0,0,1-.09442,1.25,4.70191,4.70191,0,0,1-.20716.8554,3.46932,3.46932,0,0,1-.59187,1.00336,3.54685,3.54685,0,0,1-.8554.72857,4.08412,4.08412,0,0,1-1.03437.4425,4.35039,4.35039,0,0,1-1.14006.15079H3.94864a4.27937,4.27937,0,0,1-1.12315-.15079,3.89686,3.89686,0,0,1-1.89822-1.17952A3.5058,3.5058,0,0,1,.31,108.47488a4.72585,4.72585,0,0,1-.30017-1.8193v-3.89369A4.72585,4.72585,0,0,1,.31,100.94259a3.51356,3.51356,0,0,1,.61724-.99632,3.93893,3.93893,0,0,1,.86244-.72716,3.87713,3.87713,0,0,1,1.03578-.451,4.27937,4.27937,0,0,1,1.12315-.15079h.12824a4.41672,4.41672,0,0,1,1.1457.15079,3.83443,3.83443,0,0,1,1.04423.451,3.93921,3.93921,0,0,1,.86244.72716,3.23637,3.23637,0,0,1,.60033.99632,5.59066,5.59066,0,0,1,.31708,2.011H5.70594c-.01268-.2748-.02959-.52-.05637-.737a1.85534,1.85534,0,0,0-.13387-.52986,1.30322,1.30322,0,0,0-.60033-.65671,1.80007,1.80007,0,0,0-.80749-.19729H3.91764a1.74406,1.74406,0,0,0-.799.19729,1.38165,1.38165,0,0,0-.60879.65671,1.7193,1.7193,0,0,0-.14233.48195,3.79989,3.79989,0,0,0-.04791.59328v3.86127a3.99467,3.99467,0,0,0,.04791.62429,1.96449,1.96449,0,0,0,.14233.49886,1.33621,1.33621,0,0,0,.60879.64824,1.79033,1.79033,0,0,0,.799.19024h.19024a1.79462,1.79462,0,0,0,.799-.19024,1.33621,1.33621,0,0,0,.60879-.64824"/>
      <path fill={props.fillcolor} d="M16.843,102.7943a4.67779,4.67779,0,0,0-.03946-.64824,1.76728,1.76728,0,0,0-.13528-.49182,1.3357,1.3357,0,0,0-.61583-.64824,1.86134,1.86134,0,0,0-.80749-.18884h-.17333a1.84132,1.84132,0,0,0-.81453.18884,1.34455,1.34455,0,0,0-.61019.64824,1.36133,1.36133,0,0,0-.14233.49182,5.91286,5.91286,0,0,0-.031.64824v3.82886a5.93087,5.93087,0,0,0,.031.64824,1.34538,1.34538,0,0,0,.14233.49041,1.3263,1.3263,0,0,0,.61019.64824,1.824,1.824,0,0,0,.81453.19025H15.245a1.84325,1.84325,0,0,0,.80749-.19025,1.3179,1.3179,0,0,0,.61583-.64824,1.74337,1.74337,0,0,0,.13528-.49041,4.69206,4.69206,0,0,0,.03946-.64824Zm-5.67916-.031a5.13112,5.13112,0,0,1,.28466-1.8193,3.68257,3.68257,0,0,1,.61724-1.00619,3.79785,3.79785,0,0,1,.854-.72715,3.91429,3.91429,0,0,1,1.02873-.4425,4.27737,4.27737,0,0,1,1.12315-.14938H15.245a4.18658,4.18658,0,0,1,1.11611.14938,3.76109,3.76109,0,0,1,1.02027.45095,3.995,3.995,0,0,1,.854.72716,3.56537,3.56537,0,0,1,.61724.99773,4.716,4.716,0,0,1,.30017,1.8193v3.89227a4.72587,4.72587,0,0,1-.30017,1.81931,3.55,3.55,0,0,1-.61724.99491,4.00142,4.00142,0,0,1-.854.72857,3.80485,3.80485,0,0,1-1.02027.45095,4.1882,4.1882,0,0,1-1.11611.15079h-.17333a4.27937,4.27937,0,0,1-1.12315-.15079,3.96159,3.96159,0,0,1-1.02873-.4425,3.76817,3.76817,0,0,1-.854-.72857,3.64582,3.64582,0,0,1-.61724-1.00336,5.056,5.056,0,0,1-.28466-1.81931Z"/>
      <path fill={props.fillcolor} d="M28.3916,108.47487H27.06411l-2.2153-5.379H24.8319v.31566c0,.08455.00282.18179.00846.29312.00564.10992.00846.2142.00846.30721v6.6304H22.69693V98.77661h2.51546l2.51406,6.3274,2.51546-6.3274h2.51687v11.86565H30.6069v-7.23073c0-.10569.00563-.21138.0155-.31566h-.0155Z"/>
      <path fill={props.fillcolor} d="M42.171,108.47487H40.84348l-2.21529-5.379h-.01691v.31566c0,.08455.00282.18179.00845.29312.00423.10992.00846.2142.00846.30721v6.6304H36.47631V98.77661h2.51546l2.514,6.3274,2.51546-6.3274h2.51687v11.86565H44.38627v-7.23073a2.94181,2.94181,0,0,1,.0155-.31566h-.0155Z"/>
      <rect fill={props.fillcolor} x="50.25709" y="98.77661" width="2.30971" height="11.86565"/>
      <path fill={props.fillcolor} d="M63.73489,98.7752v2.21389H60.74452v9.65176H58.43621v-9.65176H55.42893V98.7752Z"/>
      <path fill={props.fillcolor} d="M74.36606,98.7752v2.21389H71.37569v9.65176H69.066v-9.65176H66.0601V98.7752Z"/>
      <path fill={props.fillcolor} d="M77.2296,98.7752h7.1673v2.13638H79.53932v2.57888h4.00219v2.135H79.53932v2.879H84.3969v2.13638H77.2296Z"/>
      <path fill={props.fillcolor} d="M91.35706,108.42555a2.06169,2.06169,0,0,0,.97236-.1987,1.39551,1.39551,0,0,0,.59469-.65528,1.79363,1.79363,0,0,0,.13388-.49041,4.44386,4.44386,0,0,0,.03946-.61724v-3.49629a4.444,4.444,0,0,0-.03946-.61724,1.80753,1.80753,0,0,0-.13388-.49041,1.41963,1.41963,0,0,0-.59469-.6567,2.07992,2.07992,0,0,0-.97236-.19729H89.83792v7.41955Zm-3.81336,2.2153V98.7752h3.74994a4.44906,4.44906,0,0,1,2.37313.59328,3.50185,3.50185,0,0,1,1.42332,1.73193,4.24576,4.24576,0,0,1,.22265.81453,5.9083,5.9083,0,0,1,.07892,1.00478v3.5752a5.89635,5.89635,0,0,1-.07892,1.00477,4.21862,4.21862,0,0,1-.22265.81453,3.49571,3.49571,0,0,1-1.42332,1.73193,4.44933,4.44933,0,0,1-2.37313.59469Z"/>
      <path fill={props.fillcolor} d="M111.24259,98.7752v2.21389h-2.99037v9.65176H105.9425v-9.65176h-3.00587V98.7752Z"/>
      <path fill={props.fillcolor} d="M119.10463,102.7943a4.67779,4.67779,0,0,0-.03946-.64824,1.81834,1.81834,0,0,0-.13387-.49182,1.33822,1.33822,0,0,0-.61725-.64824,1.86126,1.86126,0,0,0-.80748-.18884h-.17334a1.83537,1.83537,0,0,0-.81312.18884,1.34075,1.34075,0,0,0-.6116.64824,1.38989,1.38989,0,0,0-.14233.49182,5.91286,5.91286,0,0,0-.031.64824v3.82886a5.93087,5.93087,0,0,0,.031.64824,1.3738,1.3738,0,0,0,.14233.49041,1.32269,1.32269,0,0,0,.6116.64824,1.8181,1.8181,0,0,0,.81312.19025h.17334a1.84317,1.84317,0,0,0,.80748-.19025,1.32042,1.32042,0,0,0,.61725-.64824,1.79375,1.79375,0,0,0,.13387-.49041,4.69206,4.69206,0,0,0,.03946-.64824Zm-5.67775-.031a5.13336,5.13336,0,0,1,.28325-1.8193,3.68257,3.68257,0,0,1,.61724-1.00619,3.79807,3.79807,0,0,1,.854-.72715,3.9145,3.9145,0,0,1,1.02874-.4425,4.27737,4.27737,0,0,1,1.12315-.14938h.17333a4.18651,4.18651,0,0,1,1.1161.14938,3.742,3.742,0,0,1,1.02028.45095,3.96127,3.96127,0,0,1,.854.72716,3.53343,3.53343,0,0,1,.61725.99773,4.71416,4.71416,0,0,1,.30157,1.8193v3.89227a4.724,4.724,0,0,1-.30157,1.81931,3.518,3.518,0,0,1-.61725.99491,3.96737,3.96737,0,0,1-.854.72857,3.78528,3.78528,0,0,1-1.02028.45095,4.18813,4.18813,0,0,1-1.1161.15079h-.17333a4.27931,4.27931,0,0,1-1.12315-.15079,3.96194,3.96194,0,0,1-1.02874-.4425,3.76839,3.76839,0,0,1-.854-.72857,3.64606,3.64606,0,0,1-.61724-1.00336,5.05831,5.05831,0,0,1-.28325-1.81931Z"/>
      <path fill={props.fillcolor} d="M135.14722,107.74631a1.90574,1.90574,0,0,0,.13388-.55383,7.31333,7.31333,0,0,0,.03946-.823h2.34213a9.8771,9.8771,0,0,1-.09442,1.25,4.71171,4.71171,0,0,1-.20575.8554,3.50523,3.50523,0,0,1-.59328,1.00336,3.547,3.547,0,0,1-.8554.72857,4.08419,4.08419,0,0,1-1.03438.4425,4.35037,4.35037,0,0,1-1.14005.15079h-.15784a4.29771,4.29771,0,0,1-1.12455-.15079,3.89686,3.89686,0,0,1-1.89822-1.17952,3.53784,3.53784,0,0,1-.61725-.99491,4.72811,4.72811,0,0,1-.29875-1.8193v-3.89369a4.72811,4.72811,0,0,1,.29875-1.8193,3.54547,3.54547,0,0,1,.61725-.99632,3.93893,3.93893,0,0,1,.86244-.72716,3.87713,3.87713,0,0,1,1.03578-.451,4.29777,4.29777,0,0,1,1.12455-.15079h.12683a4.41666,4.41666,0,0,1,1.1457.15079,3.83443,3.83443,0,0,1,1.04423.451,3.91414,3.91414,0,0,1,.86385.72716,3.292,3.292,0,0,1,.60034.99632,5.59453,5.59453,0,0,1,.31566,2.011h-2.34072c-.01128-.2748-.0296-.52-.05637-.737a1.85531,1.85531,0,0,0-.13388-.52986,1.29713,1.29713,0,0,0-.60033-.65671,1.80006,1.80006,0,0,0-.80748-.19729h-.19025a1.744,1.744,0,0,0-.799.19729,1.38165,1.38165,0,0,0-.60879.65671,1.71866,1.71866,0,0,0-.14232.48195,3.79868,3.79868,0,0,0-.04791.59328v3.86127a3.99467,3.99467,0,0,0,.04791.62429,1.96409,1.96409,0,0,0,.14232.49886,1.33625,1.33625,0,0,0,.60879.64824,1.7903,1.7903,0,0,0,.799.19024h.19025a1.79023,1.79023,0,0,0,.799-.19024,1.32992,1.32992,0,0,0,.60878-.64824"/>
      <path fill={props.fillcolor} d="M146.47314,102.7943a4.67779,4.67779,0,0,0-.03946-.64824,1.76728,1.76728,0,0,0-.13528-.49182,1.332,1.332,0,0,0-.61725-.64824,1.85069,1.85069,0,0,0-.80607-.18884h-.17334a1.84135,1.84135,0,0,0-.81453.18884,1.34459,1.34459,0,0,0-.61019.64824,1.36153,1.36153,0,0,0-.14233.49182,5.91286,5.91286,0,0,0-.031.64824v3.82886a5.93087,5.93087,0,0,0,.031.64824,1.34577,1.34577,0,0,0,.14233.49041,1.32639,1.32639,0,0,0,.61019.64824,1.824,1.824,0,0,0,.81453.19025h.17334a1.83274,1.83274,0,0,0,.80607-.19025,1.31444,1.31444,0,0,0,.61725-.64824,1.74337,1.74337,0,0,0,.13528-.49041,4.69206,4.69206,0,0,0,.03946-.64824Zm-5.67916-.031a5.09952,5.09952,0,0,1,.28467-1.8193,3.64853,3.64853,0,0,1,.61724-1.00619,3.79807,3.79807,0,0,1,.854-.72715,3.9145,3.9145,0,0,1,1.02874-.4425,4.27737,4.27737,0,0,1,1.12315-.14938h.17333a4.18663,4.18663,0,0,1,1.11611.14938,3.78032,3.78032,0,0,1,1.02027.45095,3.995,3.995,0,0,1,.854.72716,3.56539,3.56539,0,0,1,.61725.99773,4.71605,4.71605,0,0,1,.30016,1.8193v3.89227a4.72588,4.72588,0,0,1-.30016,1.81931,3.54975,3.54975,0,0,1-.61725.99491,4.00142,4.00142,0,0,1-.854.72857,3.82468,3.82468,0,0,1-1.02027.45095,4.18826,4.18826,0,0,1-1.11611.15079h-.17333a4.27931,4.27931,0,0,1-1.12315-.15079,3.96194,3.96194,0,0,1-1.02874-.4425,3.76839,3.76839,0,0,1-.854-.72857,3.61259,3.61259,0,0,1-.61724-1.00336,5.02536,5.02536,0,0,1-.28467-1.81931Z"/>
      <path fill={props.fillcolor} d="M158.02031,108.47487h-1.32749l-2.2153-5.379h-.01691v.31566c0,.08455.00282.18179.00845.29312.00423.10992.00845.2142.00845.30721v6.6304h-2.15188V98.77661h2.51547l2.514,6.3274,2.51547-6.3274h2.51688v11.86565H160.2356v-7.23073a2.93922,2.93922,0,0,1,.01551-.31566h-.01551Z"/>
      <path fill={props.fillcolor} d="M171.80109,108.47487h-1.3289l-2.21388-5.379h-.01692v.31566c0,.08455.00282.18179.00846.29312.00422.10992.00845.2142.00845.30721v6.6304h-2.15189V98.77661h2.51547l2.514,6.3274,2.51546-6.3274h2.51688v11.86565h-2.15188v-7.23073a2.93922,2.93922,0,0,1,.0155-.31566h-.0155Z"/>
      <path fill={props.fillcolor} d="M182.13209,98.7752v7.848a5.949,5.949,0,0,0,.031.64824,1.37415,1.37415,0,0,0,.14233.489,1.33335,1.33335,0,0,0,.60879.64965,1.83428,1.83428,0,0,0,.81593.19025h.17334a1.84314,1.84314,0,0,0,.80748-.19025,1.32365,1.32365,0,0,0,.61724-.64965,1.74765,1.74765,0,0,0,.13388-.489,4.939,4.939,0,0,0,.03946-.64824v-7.848h2.30972v7.879a4.721,4.721,0,0,1-.30016,1.8193,3.54544,3.54544,0,0,1-.61724.99632,3.99446,3.99446,0,0,1-.854.72716,3.78456,3.78456,0,0,1-1.02028.45236,4.18693,4.18693,0,0,1-1.11611.14938h-.17334a4.26986,4.26986,0,0,1-1.12315-.14938,3.91781,3.91781,0,0,1-1.02872-.44391,3.697,3.697,0,0,1-.854-.72716,3.6413,3.6413,0,0,1-.61725-1.00477,5.02534,5.02534,0,0,1-.28467-1.8193v-7.879Z"/>
      <path fill={props.fillcolor} d="M193.60176,103.06205v.31708c0,.08455.00142.18178.00845.29312.00424.10992.00845.21279.00845.30721v6.66139h-2.15329V98.7752h2.421l3.6231,7.57739h.01551c-.00987-.10428-.01551-.21-.01551-.31567V98.7752H199.66v11.86565h-2.43513l-3.60621-7.5788Z"/>
      <rect fill={props.fillcolor} x="203.37895" y="98.77661" width="2.30973" height="11.86565"/>
      <path fill={props.fillcolor} d="M216.85814,98.7752v2.21389h-2.99036v9.65176h-2.30973v-9.65176h-3.00728V98.7752Z"/>
      <path fill={props.fillcolor} d="M221.39865,98.7752l2.23079,4.36718h.031l2.26179-4.36718h2.60989l-3.71611,6.66v5.20567h-2.30973v-5.18876l-3.71752-6.67689Z"/>
      <path class="cls-2" d="M39.93172,78.43449c-5.48329-3.28913-8.15094-6.28654-8.15376-9.16417a4.02922,4.02922,0,0,1,1.13583-2.9495,4.16,4.16,0,0,1,3.00306-1.13161,5.425,5.425,0,0,1,3.68652,1.92922l.434.46928.43263-.46928a5.42446,5.42446,0,0,1,3.6823-1.93908,4.16441,4.16441,0,0,1,3.00587,1.126,4.02648,4.02648,0,0,1,1.14288,2.94528c.00282,2.87763-2.65779,5.88209-8.134,9.1839l-.11837.07046Z"/>
    </g>
  </g>
</Logo>
</a>
    )
}

export default BankofIdahoLogo
