import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  .cls-1 {
        fill: #f15a31;
      }

      .cls-2 {
        fill: #f58220;
      }
`;

const FirstInterstateBankLogo = () => {
    return (
		<a href="https://www.firstinterstatebank.com" target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.91776 92.1">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M53.56381.9a3.8715,3.8715,0,0,0-3-.9h-33.1a8.9586,8.9586,0,0,0-3.8,1.2,11.06552,11.06552,0,0,0-2.8,3.8l-10.7,44.1a7.036,7.036,0,0,0,.4,4.3l9.6,12c.7.8,1.4.7,1.7-.3l4.7-18.4a5.06163,5.06163,0,0,1,3.5-2.5h6l5-21.8s-5.9,0-6.6-.1-1.9.7-2.1.6c-.3-.2-.3-1.2-.1-1.8l2.4-9.4c.4-1.6,2.7-3.1,4.3-3.1h29.7c1.1,0,1.2-.7.4-1.5Z"/>
      <path class="cls-2" d="M20.26382,65.6c.7.8,2.4.8,3.5.8h33.2a8.95869,8.95869,0,0,0,3.8-1.2,11.06533,11.06533,0,0,0,2.8-3.8l10.6-44a7.0359,7.0359,0,0,0-.4-4.3l-9.8-12c-.7-.8-1.4-.7-1.7.3l-4.4,18.4c-.3,1-2.7,2.5-3.8,2.5l-5.8.1-5,21.8,6.6.1c.4,0,1.9-.7,2.1-.6.3.2.3,1.2.2,1.8l-2.4,9.4c-.4,1.6-3,3.5-4.6,3.5h-29.7c-1.1,0-1,.6-.3,1.3Z"/>
      <path d="M80.36382,37.4l4.2-24h18.7l-1,5.6h-10.8l-.8,4.4h8.6l-1,5.6h-8.6l-1.5,8.4Z"/>
      <path d="M99.96382,37.4l3.1-17.4h6.9l-3,17.4Zm4.3-24.6h6.9l-.9,4.9h-6.9Z"/>
      <path d="M111.66381,19.9h6.3l-.6,3.6h.1c1.4-2.7,3.4-4.1,6.1-4.1.3,0,.6.1.9.1l-1.2,6.9a5.90192,5.90192,0,0,0-1.4-.2c-2.8,0-4.5,1.3-5.2,5.1l-1.1,6.1h-6.9Z"/>
      <path d="M128.06382,31.9a2.03994,2.03994,0,0,0,.4,1.6,2.5783,2.5783,0,0,0,1.6.6c1.1,0,2.3-.3,2.5-1.6.5-2.8-10.6-.6-9.4-7.3.8-4.4,5.7-5.8,9.3-5.8,3.8,0,8.1.9,7.7,5.5h-6.2a1.47863,1.47863,0,0,0-.4-1.3,1.844,1.844,0,0,0-1.3-.4c-1.2,0-2.1.4-2.2,1.2-.4,2.4,10.9.8,9.7,7.3-.7,3.6-4.1,6.1-10.4,6.1-4,0-8.2-1.2-7.7-5.9Z"/>
      <path d="M150.76382,37.4c-9.2.5-10.2-.1-9.1-6.2l1.3-7.3h-2.4l.7-4h2.6l.9-5.4h6.8l-.9,5.4h3.3l-.7,4h-3.3l-1.1,6.3c-.3,1.6-.2,2.2,1.8,2.2h1.1Z"/>
      <path d="M75.16381,64.6l4.2-24h7.9l-4.2,24Z"/>
      <path d="M87.46382,47.2h6.3l-.5,2.7c1.9-2.1,3.8-3.2,6.4-3.2,4.6,0,6.3,2.7,5.4,7.5l-1.8,10.4h-6.9l1.6-9.2c.3-1.7.1-3.3-1.8-3.3-2.1,0-2.9,1.4-3.3,3.6l-1.6,8.9h-6.9Z"/>
      <path d="M115.26382,64.6c-9.2.5-10.2-.1-9.1-6.2l1.3-7.3h-2.4l.7-4h2.6l.9-5.4h6.8l-.9,5.4h3.3l-.7,4h-3.3l-1.1,6.3c-.3,1.6-.2,2.2,1.8,2.2h1.1Z"/>
      <path d="M123.96382,53.9a3.55886,3.55886,0,0,1,3.8-3.1,2.67014,2.67014,0,0,1,2.6,3.1Zm12.6,3.5c1.2-6.3-1.9-10.8-8.3-10.8a11.51817,11.51817,0,0,0-11.6,9.4c-1.1,5.9,2.7,9.1,8.3,9.1,4.1,0,8.7-1.4,10.7-5.8h-6.6a3.37437,3.37437,0,0,1-2.9,1.6,2.7336,2.7336,0,0,1-2.8-3.4C123.36382,57.4,136.56382,57.4,136.56382,57.4Z"/>
      <path d="M138.36381,47.2h6.3l-.6,3.6h.1c1.4-2.7,3.4-4.1,6.1-4.1.3,0,.6.1.9.1l-1.2,6.9a5.90192,5.90192,0,0,0-1.4-.2c-2.8,0-4.5,1.3-5.2,5.1l-1.1,6.1h-6.9Z"/>
      <path d="M154.16381,59.1a2.03994,2.03994,0,0,0,.4,1.6,2.5783,2.5783,0,0,0,1.6.6c1.1,0,2.3-.3,2.5-1.6.5-2.8-10.6-.6-9.4-7.3.8-4.4,5.7-5.8,9.3-5.8,3.8,0,8.1.9,7.7,5.5h-6.3a1.47863,1.47863,0,0,0-.4-1.3,1.844,1.844,0,0,0-1.3-.4c-1.2,0-2.1.4-2.2,1.2-.4,2.4,10.9.8,9.7,7.3-.7,3.6-4.1,6.1-10.4,6.1-4,0-8.2-1.2-7.7-5.9Z"/>
      <path d="M176.06382,64.6c-9.2.5-10.2-.1-9.1-6.2l1.3-7.3h-2.4l.7-4h2.6l.9-5.4h6.8l-.9,5.4h3.3l-.7,4h-3.3l-1.1,6.3c-.3,1.6-.2,2.2,1.8,2.2h1.1Z"/>
      <path d="M185.76382,61.1c-1.4,0-2.1-.6-1.9-1.4.4-2.3,3.3-1.7,5.8-2.8C189.26382,59.3,188.16381,61.1,185.76382,61.1Zm10.3,3.5.1-.3c-.8-.7-.7-1.2-.6-2.2l1.4-8.2c.7-4.2.8-7.2-7.5-7.2-6.9,0-9.9,2.2-10.6,5.9h6.7a2.48423,2.48423,0,0,1,2.7-1.7c.7,0,2.2.2,2,1.4-.6,3.3-12.4-.3-13.8,7.6-.7,3.6,2,5.3,5.3,5.3a10.46945,10.46945,0,0,0,7-2.4v1.9l7.3-.1Z"/>
      <path d="M207.46382,64.6c-9.2.5-10.2-.1-9.1-6.2l1.3-7.3h-2.4l.7-4h2.6l.9-5.4h6.8l-.9,5.4h3.3l-.7,4h-3.3l-1.1,6.3c-.3,1.6-.2,2.2,1.8,2.2h1.1Z"/>
      <path d="M216.06382,53.9a3.55888,3.55888,0,0,1,3.8-3.1,2.67013,2.67013,0,0,1,2.6,3.1Zm12.6,3.5c1.2-6.3-1.9-10.8-8.3-10.8a11.51817,11.51817,0,0,0-11.6,9.4c-1.1,5.9,2.7,9.1,8.3,9.1,4.1,0,8.7-1.4,10.7-5.8h-6.7a3.3744,3.3744,0,0,1-2.9,1.6,2.7336,2.7336,0,0,1-2.8-3.4C215.46382,57.4,228.66381,57.4,228.66381,57.4Z"/>
      <path d="M73.96382,80.3h6c4.3,0,6.9.7,6.1,4.7-.9,4.2-3.3,4.8-8.3,4.8h-5.8Zm2.2-10.7h5.2c4.9,0,6.5,1.1,5.9,4.1-.6,2.9-2.5,4.6-7.2,4.6h-5.7Zm2.9,22.1c4.6,0,8.2-1.8,9.3-6.8.7-3.2-.4-5.4-3.7-5.9v-.1c2-.3,4.2-1.8,4.9-5.2.8-3.6-.9-6.1-6.6-6.1h-8.7l-5.1,24C69.26382,91.7,79.06381,91.7,79.06381,91.7Z"/>
      <path d="M100.46382,85.3c-.7,3.5-4.2,5.1-7.4,5.1-1.9,0-3.5-1-3.1-3.1.5-2.3,2.6-3.4,5.7-3.7a21.03066,21.03066,0,0,0,5.4-.9Zm-5.4-3.3c-4.2.6-6.5,2.3-7.1,5.2-.7,3.3,1.7,4.9,4.7,4.9a10.61135,10.61135,0,0,0,7.1-2.9c-.2,1.2-.2,2.4,1.1,2.6a2.76883,2.76883,0,0,0,.9.1,5.68793,5.68793,0,0,0,1.6-.3l.3-1.6c-1.8.6-2.2.2-1.8-1.3l2-9.4c.8-4-1.6-5.4-5.1-5.4-3.8,0-7.2,1.6-8.3,5.7h2.1c1-3,3.1-3.9,5.8-3.9,3.1,0,4,1.4,3.6,3.5a2.34373,2.34373,0,0,1-1.1,1.8,12.66166,12.66166,0,0,1-2.8.6Z"/>
      <path d="M104.96382,91.7h-2l3.7-17.3h2l-.5,2.4c1.9-1.7,3.8-2.9,6.2-2.9,2.1,0,3.9.7,4.7,2.4a5.80012,5.80012,0,0,1,0,3.9l-2.4,11.3h-2l2.4-11.3c.7-3.1-.3-4.7-3.4-4.7a6.56782,6.56782,0,0,0-5.5,3.4,9.88758,9.88758,0,0,0-1.1,3.3Z"/>
      <path d="M118.06381,91.7l5.1-24h2l-3.2,14.9,10.2-8.2h2.7l-7.8,6.2,4.9,11h-2.2l-4.4-9.6-3.9,3.1-1.4,6.6Z"/>
      <path d="M68.36382,67.4a3.22216,3.22216,0,0,1-3.2-3.2,3.1504,3.1504,0,0,1,6.3,0A3.07677,3.07677,0,0,1,68.36382,67.4Zm0,.4a3.585,3.585,0,0,0,3.6-3.6,3.64985,3.64985,0,0,0-3.6-3.6,3.585,3.585,0,0,0-3.6,3.6A3.64986,3.64986,0,0,0,68.36382,67.8Zm.6-3.4c.7,0,1.3-.3,1.3-1.1a1.48929,1.48929,0,0,0-.4-.9,1.64457,1.64457,0,0,0-1-.2h-1.7v4h.5V64.4h.9l1.2,1.8h.6Zm-1.4-.4V62.6h1.2c.5,0,1.1.1,1.1.7,0,.8-.9.7-1.4.7Z"/>
    </g>
  </g>
</Logo></a>
	)
	}
	export default FirstInterstateBankLogo