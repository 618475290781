import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  .st0 {
        fill: #221E1F;
      }
    .st1{fill:#FFFFFF;}

`;

const BuckKnivesLogo = (props) => {
    return (
<a href="https://buckknives.com" target="_blank" rel="noopener noreferrer">
<Logo version="1.1" id="layer"  x="0px" y="0px"
	 viewBox="0 0 652 652">
<g>
	<path class="st0" d="M694.6,119C566.2,87.7,441,71.9,322.3,71.9h-2h-2.1C199.6,71.9,74.3,87.7-54,119c-5.3,1.3-9.1,6.1-9.1,11.5
		v322.7c0,5.5,3.7,10.2,9.1,11.5c52.1,12.7,103.7,22.8,154.5,30.4v-46c0-7.7,5.4-14.1,12.9-15.4c68-11.7,137.6-17.7,206.8-17.7
		c69.3,0,138.9,6,206.8,17.7c7.5,1.3,13,7.8,13,15.4v46c50.8-7.6,102.4-17.7,154.5-30.4c5.3-1.3,9.1-6.1,9.1-11.5V130.5
		C703.6,125,699.9,120.3,694.6,119"/>
	<path class="st0" d="M526.4,438c-67.7-11.7-137.1-17.6-206.1-17.6c-69,0-138.3,5.9-206.1,17.6c-5.4,0.9-9.3,5.6-9.3,11v94.2
		c0,5.4,3.9,10.1,9.3,11c67.7,11.7,137.1,17.6,206.1,17.6c69,0,138.4-5.9,206.1-17.6c5.3-0.9,9.3-5.6,9.3-11v-94.2
		C535.6,443.6,531.7,439,526.4,438"/>
	<polygon class="st1" points="181.6,476.3 188.5,476.3 188.5,496.3 207.4,476.3 216,476.3 199.5,493.4 216.7,516 208.2,516 
		194.7,498.1 188.5,504.5 188.5,516 181.6,516 	"/>
	<polygon class="st1" points="235.5,476.3 242,476.3 263.2,503.7 263.2,476.3 270.1,476.3 270.1,516 264.3,516 242.3,487.8 
		242.3,516 235.5,516 	"/>
	<rect x="294.3" y="476.3" class="st1" width="7" height="39.7"/>
	<polygon class="st1" points="321.3,476.3 329,476.3 341,506.8 352.9,476.3 360.4,476.3 343.9,516.3 337.8,516.3 	"/>
	<polygon class="st1" points="379.4,476.3 408.8,476.3 408.8,482.5 386.3,482.5 386.3,492.9 406.2,492.9 406.2,499.1 386.3,499.1 
		386.3,509.8 409.1,509.8 409.1,516 379.4,516 	"/>
	<path class="st1" d="M428.3,510.2l4.2-5c3.8,3.3,7.6,5.2,12.5,5.2c4.3,0,7-2,7-5v-0.1c0-2.8-1.6-4.3-9-6.1
		c-8.4-2-13.2-4.5-13.2-11.8v-0.1c0-6.8,5.7-11.5,13.5-11.5c5.8,0,10.4,1.7,14.4,5l-3.7,5.3c-3.6-2.7-7.1-4.1-10.8-4.1
		c-4.1,0-6.5,2.1-6.5,4.7v0.1c0,3.1,1.8,4.4,9.4,6.2c8.4,2,12.8,5,12.8,11.6v0.1c0,7.4-5.8,11.8-14.2,11.8
		C438.8,516.6,433,514.4,428.3,510.2"/>
	<path class="st1" d="M122.4,300.9c19.5-4.4,29.6-15.4,29.6-26.8c0-16.9-19.1-30-45.6-30H23.9c4.8,2.9,8.1,7.5,8.1,12.4V368h72.9
		c31.8,0,56.8-14.5,56.8-35.1C161.7,317.2,145.4,304.2,122.4,300.9 M64.5,255.4H91c17.1,0,27.8,7.3,27.8,21
		c0,11.5-11.6,20.7-27.8,20.7H64.5V255.4z M95.9,356.4H64.5v-47.9h31.4c17.1,0,30.9,9.8,30.9,24C126.8,346.8,112.7,356.4,95.9,356.4
		 M330.6,306c0,39.3,32,64.6,74.9,64.6c28,0,41.9-9,53.6-13.6v-12.7c-9.8,5.6-25.6,13.5-42.5,13.5c-31.2,0-50.1-20.6-50.1-51.8
		c0-30.7,17.2-52.2,50.5-52.2c16.9,0,36.8,8.7,42.1,19.5V251c-13.6-4.1-28.6-9.6-50.4-9.6C361.8,241.4,330.6,261.1,330.6,306
		 M606,351.9L606,351.9l-54.8-54l63.3-53.8h-29.2c2.9,2.9,2.4,4.6-1.6,8.3L523.2,304v-47.5c0-5,3.3-9.6,8.1-12.4h-48.7
		c4.8,2.9,8.1,7.5,8.1,12.4V368h32.5v-56.2l45.3,44.5c9.8,9.6,12.4,11.7,27.9,11.7h30.7C622.5,365.1,618.7,364.4,606,351.9
		 M291.1,256.5v66.4c0,17.2-17.1,31-38.4,31c-21.1,0-38.4-10.1-38.4-31v-66.4c0-5,3.3-9.6,8.1-12.4h-48.7c4.8,2.9,8.1,7.5,8.1,12.4
		v66.4c0,26.6,28.5,47.9,62.7,47.9c34.4,0,62.1-21.3,62.1-47.9v-66.4c0-6.5,2.5-8.8,7.4-12.4h-30.4
		C288.8,247.7,291.1,250,291.1,256.5"/>
	<path class="st1" d="M628.5,251.6L628.5,251.6c0-6.9,5.6-12.7,12.6-12.7c7.1,0,12.6,5.7,12.6,12.5v0.1c0,6.9-5.6,12.6-12.6,12.6
		S628.5,258.4,628.5,251.6 M652.2,251.5L652.2,251.5c0-6.2-4.8-11.2-11.1-11.2c-6.4,0-11.2,5.1-11.2,11.2v0.1
		c0,6.2,4.8,11.1,11.1,11.1C647.4,262.7,652.2,257.6,652.2,251.5 M635.9,244.4h5.9c1.8,0,3.2,0.5,4.2,1.4c0.7,0.7,1.1,1.8,1.1,2.9
		v0.1c0,2.1-1.2,3.5-2.9,4.1l3.3,4.8H644l-2.9-4.3h-0.1h-2.3v4.3h-3V244.4z M641.8,250.9c1.5,0,2.3-0.8,2.3-1.9V249
		c0-1.3-0.9-1.9-2.4-1.9h-2.9v3.9H641.8z"/>
	<polygon class="st1" points="150.2,184 150.2,181 153.7,181 153.7,190.3 150.2,190.3 150.2,187.3 146.2,187.3 146.2,194.8 
		156,194.8 156,190.3 160.2,190.3 160.2,198.1 137.9,198.1 137.9,194.8 141,194.8 141,176.9 137.9,176.9 137.9,173.5 160.2,173.5 
		160.2,181 156,181 156,176.9 146.2,176.9 146.2,184 	"/>
	<path class="st1" d="M182.7,189.7c0.5,3.8,2,5.4,5.2,5.4c2.8,0,4.5-1.2,4.5-3.3c0-1.7-0.8-2.5-3.6-3.5l-4.5-1.6
		c-4.5-1.6-6.1-3.3-6.1-6.6c0-4.5,3.1-7.2,8.4-7.2c3.1,0,5.1,0.9,6.1,2.8v-2.3h4.2v7.9h-4.2c0-3.3-1.6-5-4.9-5
		c-2.6,0-4.2,1.1-4.2,3.1c0,1.5,0.8,2.2,3.6,3.2l4.3,1.5c2.7,0.9,4.2,1.8,5,3.1c0.7,1.1,1.1,2.4,1.1,3.9c0,4.6-3.2,7.3-8.7,7.3
		c-3.4,0-5.5-1-6.5-3.1v2.6h-4.2v-8.4H182.7z"/>
	<polygon class="st1" points="228.9,194.7 232.5,194.7 232.5,198.1 220.1,198.1 220.1,194.7 223.7,194.7 223.7,176.9 218.9,176.9 
		218.9,182 214.7,182 214.7,173.5 238,173.5 238,182 233.8,182 233.8,176.9 228.9,176.9 	"/>
	<rect x="247.5" y="193.1" class="st1" width="5.1" height="5"/>
	<path class="st1" d="M403.6,194.7h3.3v3.4h-12.2v-3.4h3.8v-16.5c-2.4,2.2-2.9,2.5-6.2,4.6v-4.6c3-2,3.5-2.4,6.2-4.7h5.1V194.7z"/>
	<path class="st1" d="M429.8,191.9c0.1,2,1.1,3.3,2.8,3.3c1.7,0,3.2-1.1,3.8-2.9c0.7-1.9,1.1-3.9,1.1-6.8c-0.8,2.4-2.9,3.9-5.6,3.9
		c-4.3,0-7.4-3.3-7.4-8c0-5.2,3.2-8.4,8.5-8.4c2.8,0,5.4,1.1,7,3.1c1.5,1.8,2.3,4.8,2.3,8.9c0,8.9-3.4,13.6-9.9,13.6
		c-4.6,0-7.5-2.5-7.6-6.7H429.8z M429.4,181.2c0,2.9,1.4,4.7,3.7,4.7c2.4,0,4-2,4-4.7c0-2.9-1.5-4.8-3.8-4.8
		C430.9,176.5,429.4,178.3,429.4,181.2"/>
	<path class="st1" d="M476.1,177.3c1.2,2.2,1.6,4.5,1.6,8.1c0,4.5-0.7,7.5-2.5,9.9c-1.5,2.1-3.8,3.2-6.6,3.2c-5.9,0-9-4.4-9-13.1
		c0-3.9,0.7-7,2.2-9.1c1.5-2.2,3.9-3.4,6.8-3.4C472.1,173,474.5,174.4,476.1,177.3 M464.8,186.1c0,6.3,1.2,9,3.9,9c2.7,0,4-3,4-9.3
		c0-6.4-1.3-9.3-3.9-9.3C466.1,176.5,464.8,179.5,464.8,186.1"/>
	<path class="st1" d="M494.8,180.9c0-1.6,0.5-3.3,1.4-4.6c1.4-2.2,3.9-3.3,7.4-3.3c5.3,0,8.5,2.5,8.5,6.9c0,3.1-1,4.5-7.2,10.3
		c-3.7,3.5-4.1,3.8-5.3,4.5c0.3,0,0.4,0,0.5,0l0.8,0h7.3v-3.6h4.3v6.9h-17.7v-4.7c0.1-0.1,0.3-0.2,0.5-0.4l4.6-4
		c2.4-2.1,4.9-4.7,5.9-6.1c0.8-1.2,1.1-2,1.1-3c0-2-1.3-3.3-3.3-3.3c-2.3,0-3.7,1.6-3.7,4.4H494.8z"/>
	<path class="st1" d="M305,145.9c0.5,0.6,0.8,1.3,0.8,2.1v28.5h1.4V148c0-0.7-0.3-1.4-0.8-2.1H305z M329.1,168.3
		c0-3.8-3.7-6.4-8.2-6.4h-8.4v12.8h8.4C325.3,174.6,329.1,172.1,329.1,168.3 M328.9,153.3c0,2.7-2.4,4.9-6,5.4v0.1
		c4.3,0,7.3-2.5,7.3-5.5c0-3.6-2.9-5.6-7.3-5.6v0.1C326.7,148.2,328.9,150.1,328.9,153.3 M327,153.3c0-3.6-2.9-5.6-7.4-5.6h-7.1
		v11.1h7.1C323.9,158.8,327,156.4,327,153.3 M324.3,161.9v0.1c3.8,0.5,6.7,2.9,6.7,6.3c0,3.5-3.1,5.9-7.1,6.3v0.1h0.2
		c4.5,0,8.2-2.6,8.2-6.4C332.4,164.5,328.8,161.9,324.3,161.9 M363.8,140.1v-4.9h-15.3v-5.6h-91.8v20.9h22.1v7.9
		c3.7,0.7,11.6,2.3,11.6,10.5c0,8.3-5.3,14.4-11.1,17.6v11.6h19.2v-1.3c0-2.4,1.9-4.3,4.3-4.3h34.8c2.4,0,4.3,1.9,4.3,4.3v1.3h19.2
		v-11.6c-5.8-3.2-11.1-9.3-11.1-17.6c0-8.3,7.9-9.8,11.6-10.5c16.6-3.2,34.9-6.5,41.3-18.3H363.8z M323.2,177.7h-19.4V148
		c0-1.3-0.9-2.6-2.2-3.3h22c7.1,0,12.1,3.5,12.1,8c0,3-2.7,5.9-7.9,7.1c6.2,0.9,10.5,4.4,10.5,8.5
		C338.4,173.8,331.7,177.7,323.2,177.7"/>
</g>
</Logo>
</a>
    )
}

export default BuckKnivesLogo
