import React from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import Img from 'gatsby-image'

const MessageFromModal = (props) => (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    <Modal.Header closeButton>
        <Row>
            <Col>
                <div>
                    <h2>{props.partner}</h2>
                </div>
            </Col>
        </Row>
    </Modal.Header>
    <Modal.Body>
    <div style={{
                float: 'right',
                marginLeft: '20px',
                width: '250px'
            }}>
              <Img
                fluid={props.personPhoto}
                style={{ maxWidth: "250px" }}
                alt={props.messagePerson}
                className="rounded"
              />
              
            </div>
        <p>{props.message}</p>
        <p><em>{props.messagePerson}</em><br />{props.personTitle}</p>
        {props.partnerLogo && 
        <>
        <div style={{width: '140px'}}><a href={props.partnerUrl} target="_blank" rel="noopener noreferrer">{props.partnerLogo}</a>
        </div><p style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
        </>
        }
        
        
        

    </Modal.Body>
</Modal>
)

export default MessageFromModal