import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  .cls-1 {
        fill: #3d9b46;
      }

      .cls-1, .cls-2 {
        fill-rule: evenodd;
      }

      .cls-3 {
        fill: #231f20;
      }

`;

const BoiseCascadeLogo = (props) => {
    return (
<a href="https://www.bc.com/" target="_blank" rel="noopener noreferrer">

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 403.79058 123.81903">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path fill={props.fillColor} d="M68.631,86.67316,86.85,101.17789,95.87143,90.9188,68.631,69.1621V60.67136l18.39594,14.6815,8.84454-10.61332L68.631,43.15971v-8.4902L87.38073,49.52788l8.4907-10.79019L62.08636,11.8514,28.47816,38.73769,36.9689,49.52788,55.71868,34.66951v8.4902L28.47816,64.73954l8.84448,10.61332,18.396-14.6815V69.1621L28.47816,90.9188l9.02135,10.25909L55.71868,86.67316v25.29492a50.83939,50.83939,0,1,1,12.91227-.17692v-25.118Zm1.64147,36.59163a62.00041,62.00041,0,1,0-8.36293.55424,61.9252,61.9252,0,0,0,8.36293-.55424Z"/>
        <path fill={props.fillColor2} d="M133.64431,51.04244V77.87813H146.1786a13.6403,13.6403,0,0,0,5.78483-1.12485,7.97472,7.97472,0,0,0,4.01722-7.07036,6.22336,6.22336,0,0,0-4.3386-6.267,5.21356,5.21356,0,0,0,3.37448-5.30315,6.49772,6.49772,0,0,0-3.21378-5.94556,10.85435,10.85435,0,0,0-4.17794-1.1248h-13.9805Zm5.46348,10.44527V55.70238h6.91009c2.24969,0,3.5352.96417,3.5352,2.89241A3.02148,3.02148,0,0,1,146.5,61.48771h-7.39217Zm0,11.73034V66.14769h7.55285a3.38157,3.38157,0,0,1,3.69591,3.3745,3.72,3.72,0,0,1-4.01727,3.69586Z"/>
        <path fill={props.fillColor2} d="M169.1577,57.47c-6.26691,0-10.28459,4.1784-10.28459,10.606,0,6.26691,4.01768,10.44486,10.28459,10.44486s10.28419-4.17795,10.28419-10.6055c0-6.26691-4.178-10.44531-10.28419-10.44531Zm-.48208,4.49976h.48208q4.8207,0,4.82071,6.26691c0,3.69591-1.76756,5.78488-4.82071,5.78488s-4.8207-2.24965-4.8207-6.10624c0-3.6959,1.60693-5.78487,4.33862-5.94555Z"/>
        <path fill={props.fillColor2} d="M182.97747,50.721v4.98139h5.30277V50.721Zm0,7.23113v19.926h5.30277v-19.926Z"/>
        <path fill={props.fillColor2} d="M209.4918,64.05872c-.32136-4.33908-3.21377-6.58873-8.838-6.58873-4.98182,0-8.19564,2.57106-8.19564,6.58873a4.67263,4.67263,0,0,0,1.92827,4.01722,8.283,8.283,0,0,0,3.21385,1.44625A36.901,36.901,0,0,0,202.4214,70.647q2.41045.4821,2.41042,1.92825c0,1.12489-1.28557,1.92829-3.37455,1.92829-2.73214,0-4.01765-.96417-4.17833-2.89242h-5.46349c.16068,4.33867,3.5352,6.90964,9.48114,6.90964,5.46348,0,8.838-2.571,8.838-6.74891a4.6727,4.6727,0,0,0-1.92825-4.01727c-1.44625-1.12484-2.89246-1.60693-7.55247-2.41042-1.92872-.482-3.05352-1.1248-3.05352-2.089,0-1.1248,1.44661-1.76752,3.2142-1.76752,2.089,0,3.37452.8034,3.5352,2.571Z"/>
        <path fill={props.fillColor2} d="M231.18542,69.36147v-.64272c0-7.39177-3.21382-11.24876-9.32-11.24876-6.10674,0-9.64187,3.857-9.64187,10.44531,0,6.749,3.53513,10.6055,9.64187,10.6055,4.65994,0,8.03448-2.41029,8.99861-6.42755h-5.3028c-.482,1.44616-1.76756,2.08893-3.53513,2.08893-2.73178,0-4.01727-1.28549-4.4993-4.1779v-.64281Zm-13.498-3.3745c.482-2.89242,1.60685-4.1779,4.01727-4.1779,2.24965,0,3.69581,1.44616,4.01717,4.1779Z"/>
        <path fill={props.fillColor2} d="M267.98434,59.88032c-.3214-5.46347-5.30279-9.48074-11.56971-9.48074-7.71353,0-12.37356,5.3028-12.37356,14.14122,0,8.67725,4.66,14.14113,12.37356,14.14113a10.78383,10.78383,0,0,0,9.15938-4.33908,13.31233,13.31233,0,0,0,2.41033-5.6241h-5.62415c-.48209,3.21382-2.73174,4.98143-6.10624,4.98143-4.01767,0-6.58873-3.37454-6.58873-9.15938,0-5.78528,2.57106-9.32042,6.91009-9.32042a5.458,5.458,0,0,1,5.78488,4.65994Z"/>
        <path fill={props.fillColor2} d="M289.03515,77.23536c-.96417-.48208-1.12485-1.28553-1.12485-3.05318V62.77324c0-3.21428-3.37445-5.30325-8.35584-5.30325-3.53518,0-5.78488.96412-7.23154,2.89246a9.59864,9.59864,0,0,0-1.44616,4.01763h5.14252c.32136-1.92825,1.28553-2.73169,3.53518-2.73169,1.92829,0,3.05309.80344,3.05309,2.24965,0,1.44616-.32136,1.60693-5.30274,2.24965a8.40953,8.40953,0,0,0-4.66044,1.44625,6.24594,6.24594,0,0,0-2.41042,4.98135c0,3.69586,2.41042,5.94551,6.428,5.94551a7.642,7.642,0,0,0,6.10628-2.89242,14.25064,14.25064,0,0,0,.482,2.24975h5.78483v-.64277Zm-7.8738-8.35589a1.53694,1.53694,0,0,0,1.12484-.3214l.32136-.32141h0v1.76762c0,3.05314-1.60693,4.66-4.4993,4.66a2.40826,2.40826,0,0,1-2.57106-2.41037,2.72706,2.72706,0,0,1,2.24961-2.571l3.37455-.80345Z"/>
        <path fill={props.fillColor2} d="M308.80048,64.05872c-.32136-4.33908-3.37446-6.58873-8.83793-6.58873-5.14211,0-8.35634,2.57106-8.35634,6.58873a5.10909,5.10909,0,0,0,1.9287,4.01722,8.28234,8.28234,0,0,0,3.21377,1.44625,36.89467,36.89467,0,0,0,4.82071,1.12485c1.607.3214,2.5711.96412,2.5711,1.92825,0,1.12489-1.44625,1.92829-3.53522,1.92829-2.73174,0-4.01727-.96417-4.1779-2.89242h-5.46388c.16063,4.33867,3.69622,6.90964,9.64178,6.90964,5.30275,0,8.838-2.571,8.838-6.74891a5.109,5.109,0,0,0-1.9283-4.01727c-1.607-1.12484-2.89241-1.60693-7.55244-2.41042-2.089-.482-3.21387-1.1248-3.21387-2.089,0-1.1248,1.44626-1.76752,3.21387-1.76752,2.24965,0,3.53518.8034,3.53518,2.571Z"/>
        <path fill={props.fillColor2} d="M330.1731,65.18352c-.32131-4.82107-3.69627-7.71353-8.83834-7.71353-6.10623,0-9.48073,4.01772-9.48073,11.08808,0,6.26691,3.3745,9.96273,9.32,9.96273,4.98139,0,8.517-3.0531,8.999-7.87376h-5.3032c-.32136,2.24965-1.60684,3.53514-3.69582,3.53514-2.5711,0-3.85663-2.08893-3.85663-6.10624,0-4.01722,1.44626-6.26687,4.01731-6.26687,1.9283,0,3.05315,1.1248,3.53514,3.37445Z"/>
        <path fill={props.fillColor2} d="M351.06328,77.23536c-.96417-.48208-1.12485-1.28553-1.12485-3.05318V62.77324c0-3.21428-3.37491-5.30325-8.3563-5.30325-3.53518,0-5.78483.96412-7.23109,2.89246a9.59878,9.59878,0,0,0-1.44616,4.01763H338.047c.32136-1.92825,1.28548-2.73169,3.53518-2.73169,1.92825,0,3.0531.80344,3.0531,2.24965,0,1.44616-.32132,1.60693-5.14207,2.24965a8.55668,8.55668,0,0,0-4.82067,1.44625,6.24594,6.24594,0,0,0-2.41042,4.98135c0,3.69586,2.41042,5.94551,6.42769,5.94551a7.64181,7.64181,0,0,0,6.10619-2.89242,14.25687,14.25687,0,0,0,.48209,2.24975h5.78524v-.64277Zm-7.87422-8.35589a1.53686,1.53686,0,0,0,1.12485-.3214l.32132-.32141h0v1.76762c0,3.05314-1.60685,4.66-4.49926,4.66a2.40832,2.40832,0,0,1-2.5711-2.41037,2.727,2.727,0,0,1,2.24969-2.571l3.3745-.80345Z"/>
        <path fill={props.fillColor2} d="M367.29342,51.04244v9.64137a7.06122,7.06122,0,0,0-5.946-3.21382c-4.82067,0-8.19521,4.1784-8.19521,10.44531,0,6.42755,3.37454,10.6055,8.51661,10.6055a6.24474,6.24474,0,0,0,5.78529-3.21378v2.57111h5.14207V51.04244Zm-4.17839,11.088c2.89246,0,4.33912,2.089,4.33912,6.10623,0,3.69591-1.60734,5.94552-4.49976,5.94552-2.5711,0-4.33862-2.41029-4.33862-6.26688,0-3.6959,1.60684-5.78487,4.49926-5.78487Z"/>
        <path fill={props.fillColor2} d="M394.45052,69.36147v-.64272c0-7.39177-3.21383-11.24876-9.32-11.24876-6.10673,0-9.64187,3.857-9.64187,10.44531,0,6.749,3.53514,10.6055,9.64187,10.6055,4.66,0,8.03449-2.41029,9.15934-6.42755h-5.46352c-.482,1.44616-1.76757,2.08893-3.53514,2.08893-2.57146,0-4.01772-1.28549-4.33912-4.1779l-.16063-.64281ZM380.95206,65.987c.48213-2.89242,1.76766-4.1779,4.01731-4.1779,2.2501,0,3.69627,1.44616,4.01767,4.1779Z"/>
      </g>
      <path fill={props.fillColor2} d="M403.79058,54.34564a5.057,5.057,0,1,1-5.00977-4.91993A4.95619,4.95619,0,0,1,403.79058,54.34564Zm-8.84961,0a3.8298,3.8298,0,0,0,3.83984,3.92968,3.76689,3.76689,0,0,0,3.749-3.89941,3.79733,3.79733,0,1,0-7.58887-.03027Zm3.0293,2.5498h-1.13965V51.97552a13.40445,13.40445,0,0,1,1.88965-.14942,2.61254,2.61254,0,0,1,1.67968.38965,1.23736,1.23736,0,0,1,.51074,1.04981,1.26985,1.26985,0,0,1-1.0205,1.16992V54.496c.48047.14941.71972.53906.87011,1.19922a4.40025,4.40025,0,0,0,.36036,1.20019h-1.2002a3.85327,3.85327,0,0,1-.38965-1.20019c-.09082-.50977-.39062-.75-1.02051-.75h-.54Zm.03027-2.75976h.54c.62988,0,1.13965-.21,1.13965-.71973,0-.4502-.33008-.75-1.04981-.75a2.72193,2.72193,0,0,0-.62988.05957Z"/>
    </g>
  </g>
</Logo>
</a>
    )
}
export default BoiseCascadeLogo
