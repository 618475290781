// extracted by mini-css-extract-plugin
export var blueRow = "Supporters-module--blueRow--DOZ45";
export var bronzeLevel = "Supporters-module--bronzeLevel--UK60k";
export var copperLevel = "Supporters-module--copperLevel--sc9jN";
export var goldLevel = "Supporters-module--goldLevel--ayjmT";
export var jade = "Supporters-module--jade--4uTOg";
export var jasper = "Supporters-module--jasper--C1Bq5";
export var lightBlueRow = "Supporters-module--lightBlueRow--qgith";
export var messageLink = "Supporters-module--messageLink--t9kXf";
export var opal = "Supporters-module--opal--w1jkO";
export var platinumLevel = "Supporters-module--platinumLevel--dXjUH";
export var silverLevel = "Supporters-module--silverLevel--y-YaX";
export var starGarnet = "Supporters-module--starGarnet--n1eSg";
export var supporterTitleDiv = "Supporters-module--supporterTitleDiv--wGFpD";
export var supportersRow = "Supporters-module--supportersRow--AOz5w";
export var tabContent = "Supporters-module--tab-content--p8Hph";
export var topaz = "Supporters-module--topaz--C8Gdn";