import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const BattelleLogo = (props) => {
    const data = useStaticQuery(graphql`
{
  battelleLogo: file(relativePath: { eq: "BattelleLogo.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 250, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`
    )

return (
    <>
    <a href="https://inl.gov/" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.battelleLogo.childImageSharp.fluid} alt="Battelle Energy Alliance Logo" />
    </a>
    </>
)

}

export default BattelleLogo