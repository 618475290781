import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"
import { Link } from "gatsby"
import { Container, Row, Col, Nav } from "react-bootstrap"

// import { BLOCKS } from "@contentful/rich-text-types"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import PlatinumLevel from "../components/SupportersPage/Corporate/PlatinumLevel"
import Partners from "../components/SupportersPage/Partners"
import SmallBusinessCivic from "../components/SupportersPage/SmallBusinessCivic"
import Individuals from "../components/SupportersPage/Individuals/Individuals"

const Supporters = (props) => {
  //const [selectedTab, setSelectedTab] = useState(props.location.hash.substring(1));
  const [key, setKey] = useState("corporate")

  // useEffect(() => {
  //   setKey(props.location.pathname)
  //   console.log(key)
  // }, [])

  // const changeTab = (e) => {
  //   setKey(e)
  // }

  useEffect(() => {
    setKey(props.location.pathname.id);
    console.log(props.location.pathname);
}, [props.location.pathname]);
    
    const currentTab = `${props.location.pathname}`;
    console.log(`currentTab:  ${currentTab}`)
  // const handleSelect = (e) => {
  //   setSelectedTab(e);
  //   props.history.replace({
  //     hash: `$(e)`
  //   })
  // }
  
  //const [key, setKey] = useState("corporate")
  // const supporterContent = data.allContentfulSupportersSections.edges
  

  // const options = {
  //   renderNode: {
  //     [BLOCKS.EMBEDDED_ASSET]: ({
  //       data: {
  //         target: { fields },
  //       },
  //     }) => (
  //       <div
  //         dangerouslySetInnerHTML={{
  //           __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />`,
  //         }}
  //       />
  //     ),

  //     [BLOCKS.EMBEDDED_ENTRY]: node => {
  //       const fields = node.data.target.fields.imageLink
  //       const imgLnk = node.data.target.fields.url
  //       return (
  //         <div
  //           dangerouslySetInnerHTML={{
  //             __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
  //           }}
  //         />
  //       )
  //     },
  //   },
  // }

  return (
    
    <Layout>
      <SEO title="USS IDAHO Supporters" />
      <Container fluid className="container-fluid-full">
        <PageTitle
          subPage="USS IDAHO Supporters"
          pageTitle="USS IDAHO Supporters"
          link="/"
        >
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <Row>
            <Col lg={12}>
              <Row>
                <Col>
                  <p>
                    We would like to sincerely thank all of our supporters that
                    are making the goals of the Commissioning Committee a
                    reality.
                  </p>
                  <p>
                    The USS IDAHO Commissioning Foundation has dedicated itself
                    with raising funds to cover the costs of Commissioning
                    activities, events leading up to and including Commissioning
                    Week as well as habitability improvements, memorials and
                    support programs for the crew and families.
                  </p>
                  <p>
                    This covers incurred expenses which are customary of the
                    commissioning of a US Navy warship. The funds raised ensure
                    various commissioning events, community outreach and host
                    functions are in place to celebrate the commissioning of our
                    state’s namesake ship.
                  </p>
                  <p>
                    Our fundraising goals include funding scholarships for the
                    USS IDAHO Crew and their families via the Dolphin
                    Scholarship Foundation.
                  </p>
                  <p>
                    Additionally our goals include funding other support
                    programs through the Navy's Morale, Welfare and Recreation
                    programs and Family Service Center Programs.
                  </p>
                </Col>
              </Row>
              <Row>
              </Row>
              {/* <Tabs
                    justify
                    activeKey={key}
                    onSelect={k => setKey(k)}
                  >
                    <Tab eventKey="corporate" id="corporate" title="Corporate">
                    <DiamondLevel />
                    </Tab>
                    <Tab eventKey="civic" title="Small Business/Civic">
                    <SmallBusinessCivic />
                    </Tab>
                    <Tab eventKey="individuals" title="Individuals">
                    <Individuals />
                    </Tab>
                    <Tab eventKey="partners" title="Partners">
                    <Partners />
                    </Tab>
                </Tabs> */}
              <Nav variant="tabs"
                    justify
                    activeKey={key}
                    onSelect={k => setKey(k)}
                    >
        <Nav.Item>
          {/* window.location not available in SSR  
          ${currentTab === 'corporate' && 'active'} */}
          <Link to="/supporters/corporate" id="corporate" eventKey="corporate" activeClassName="active" partiallyActive={true} className={`supportersNav nav-link ${currentTab === '/supporters/corporate' ? 'active' : 'wtf'}`} role="tab">
                Corporate
            </Link>
        </Nav.Item>
        <Nav.Item>
            <Link to="/supporters/small-business-civic" id="civic" eventKey="civic" partiallyActive={true} activeClassName="active" className={`supportersNav nav-link ${currentTab === '/supporters/small-business-civic' ? 'active' : 'wtf'}`} role="tab">
                Small Business/Civic
            </Link>
        </Nav.Item>
        <Nav.Item>
            <Link to="/supporters/individuals" id="individuals" eventKey="individuals" partiallyActive={true} activeClassName="active" className={`supportersNav nav-link ${currentTab === '/supporters/individuals' ? 'active' : 'wtf'}`} role="tab">
                Individuals
            </Link>
        </Nav.Item>
        <Nav.Item>
            <Link to="/supporters/partners" id="partners" eventKey="partners" partiallyActive={true} activeClassName="active" className={`supportersNav nav-link ${currentTab === '/supporters/partners' ? 'active' : 'wtf'}`} role="tab">
                Partners
            </Link>
        </Nav.Item> 
        </Nav>
  <div  style={{width: '100%'}}>
<Router basepath="/supporters">
    <PlatinumLevel path="/" />
    <PlatinumLevel path="corporate" />
    <SmallBusinessCivic path="small-business-civic" />
    <Individuals path="individuals" />    
    <Partners path="partners" />
</Router> 
</div>
    


              

                    {/* {supporterContent.map(({ node }) => {
                      return (
                        <>
                        
                          <Row className="supportersRow my-4">
                            <Col md={2} className="text-center">
                              <img src={node.logo.fixed.src} alt="" />
                            </Col>
                            <Col md={10}>
                              <h3>{node.sectionTitle}</h3>
                              {documentToReactComponents(
                                node
                                  .childContentfulSupportersSectionsSectionContentRichTextNode
                                  .json,
                                options
                              )}
                            </Col>
                          </Row>
                        </>
                      )
                    })} */}
                  
            </Col>
            {/* <Col className="d-none d-md-block">
              <SupportersSidebar />
            </Col> */}
          </Row>
        </ContentBlock>
      </Container>
    </Layout>
  )
}

// export const query = graphql`
//   {
//     allContentfulSupportersSections {
//       edges {
//         node {
//           childContentfulSupportersSectionsSectionContentRichTextNode {
//             json
//           }
//           sectionTitle
//           donationAmount
//           logo {
//             fixed(width: 150, quality: 80) {
//               src
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default Supporters
