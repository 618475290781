import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 100%;
  height: auto;
  .cls-1 {
        fill: #231f20;
      }

      .cls-2 {
        fill: #fff100;
      }

      .cls-3 {
        fill: #eb2c8b;
      }

      .cls-4 {
        fill: #00aeee;
      }

      .cls-5 {
        fill: #00a550;
      }

      .cls-6 {
        fill: #ec2425;
      }

      .cls-7 {
        fill: #2e3192;
      }
`;

const AlbertsonsFamilyLogo = (props) => {
    return (

<a href="https://jkaf.org" target="_blank" rel="noopener noreferrer">
  <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.05684 274">
  <defs>
    <style>

    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_2-2" data-name="Layer 2">
      <path class="cls-1" d="M97.47618,208.54082a2.07795,2.07795,0,0,0,3.81532-1.14649v-6.85055h.52113v6.85055a2.60213,2.60213,0,0,1-4.71389,1.52392Z"/>
      <rect class="cls-1" x="105.30235" y="208.87798" width="0.59851" height="0.91278"/>
      <path class="cls-1" d="M110.616,206.43054H115.251l-2.3167-5.33925Zm6.66737,3.36053h-.57326l-1.23652-2.83939h-5.09289l-1.22389,2.83939h-.57324l4.01115-9.24618h.67748Z"/>
      <rect class="cls-1" x="119.96519" y="208.87798" width="0.6001" height="0.91278"/>
      <path class="cls-1" d="M129.39186,206.32664c-1.276,0-2.31827.6901-2.31827,1.53656s.92541,1.53813,2.05928,1.53813a1.89048,1.89048,0,0,0,2.05768-1.47181v-1.60288Zm-2.34352-2.52672a3.48662,3.48662,0,0,1,2.08453-.65062,2.28473,2.28473,0,0,1,2.57882,2.26614v4.376h-.52114v-.82119a2.82916,2.82916,0,0,1-2.05768.95068,2.36926,2.36926,0,0,1-2.58041-2.05769c0-1.13229,1.27757-2.0577,2.8394-2.0577h1.79869v-.39006c0-1.22546-.92541-1.745-2.05768-1.745a3.05241,3.05241,0,0,0-1.70554.49429Z"/>
      <path class="cls-1" d="M140.448,209.79107h-.51957v-4.19276a1.92385,1.92385,0,0,0-1.92818-1.9282,2.20059,2.20059,0,0,0-2.18877,2.18719v3.93377h-.52114v-6.511h.52114v1.11965a2.51587,2.51587,0,0,1,2.18877-1.25073,2.44616,2.44616,0,0,1,2.44775,2.44933Z"/>
      <path class="cls-1" d="M146.51522,203.67011a2.90852,2.90852,0,1,0,2.40986,2.86466,2.67259,2.67259,0,0,0-2.40986-2.86466m2.8789,6.121H148.873v-1.44495a2.891,2.891,0,0,1-2.383,1.576,3.42665,3.42665,0,0,1,0-6.77316,2.87522,2.87522,0,0,1,2.383,1.58867v-4.7139h.52114Z"/>
      <polygon class="cls-1" points="161.556 204.829 159.25 207.174 159.25 209.79 158.729 209.79 158.729 200.544 159.25 200.544 159.25 206.419 165.033 200.544 165.75 200.544 161.921 204.451 165.75 209.79 165.112 209.79 161.556 204.829"/>
      <path class="cls-1" d="M169.73385,206.43054h4.6365l-2.31826-5.33925Zm6.66736,3.36053h-.57326l-1.23651-2.83939h-5.0929l-1.22389,2.83939h-.57323l4.01115-9.24618H172.39Z"/>
      <polygon class="cls-1" points="185.074 200.544 185.074 201.065 181.426 201.065 181.426 209.79 180.906 209.79 180.906 201.065 177.26 201.065 177.26 200.544 185.074 200.544"/>
      <polygon class="cls-1" points="195.166 205.428 188.705 205.428 188.705 209.792 188.185 209.792 188.185 200.544 188.705 200.544 188.705 204.907 195.166 204.907 195.166 200.544 195.687 200.544 195.687 209.792 195.166 209.792 195.166 205.428"/>
      <path class="cls-1" d="M206.42865,203.51314a2.44481,2.44481,0,0,0-2.44773-2.44775h-3.54216v4.88445h3.54216a2.43441,2.43441,0,0,0,2.44773-2.4367m-2.44773-2.96889a2.96209,2.96209,0,0,1,.54639,5.87461l2.293,3.37158h-.62535l-2.25352-3.321h-3.50266v3.321h-.52114v-9.24619Z"/>
      <polygon class="cls-1" points="213.018 205.793 209.059 200.544 209.709 200.544 213.278 205.285 216.847 200.544 217.498 200.544 213.539 205.793 213.539 209.792 213.018 209.792 213.018 205.793"/>
      <polygon class="cls-1" points="221.143 201.404 221.143 209.791 220.622 209.791 220.622 200.545 221.143 200.545 227.603 208.932 227.603 200.545 228.123 200.545 228.123 209.791 227.603 209.791 221.143 201.404"/>
      <path class="cls-1" d="M8.025,236.65109h6.19837l-3.08527-7.90236Zm12.152,7.7948H17.28083l-2.00248-5.08776H6.97l-1.97653,5.08776H2.09913l7.52224-19.21632h3.03151Z"/>
      <polygon class="cls-1" points="25.699 225.23 28.406 225.23 28.406 241.739 39.772 241.739 39.772 244.446 25.699 244.446 25.699 225.23"/>
      <path class="cls-1" d="M48.16343,236.19126v5.54759h8.06366a2.77383,2.77383,0,1,0,0-5.54759Zm6.9549-2.70519a2.77476,2.77476,0,1,0,0-5.54946h-6.9549v5.54946ZM45.4564,244.44589V225.22957h9.66193a5.4813,5.4813,0,0,1,4.08652,9.14834,5.4655,5.4655,0,0,1-2.97776,10.068Z"/>
      <polygon class="cls-1" points="81.994 225.23 81.994 227.937 70.897 227.937 70.897 233.485 81.181 233.485 81.181 236.192 70.897 236.192 70.897 241.739 81.994 241.739 81.994 244.446 68.191 244.446 68.191 225.23 81.994 225.23"/>
      <path class="cls-1" d="M101.18354,231.40071a3.45487,3.45487,0,0,0-3.46537-3.46353H91.44v6.90111h6.27813a3.43173,3.43173,0,0,0,3.46537-3.43758m-3.46537-6.17059a6.16295,6.16295,0,0,1,1.46105,12.15205l4.27749,7.06429H100.3158l-4.16625-6.903H91.44v6.903H88.733V225.23012Z"/>
      <polygon class="cls-1" points="124.811 225.23 124.811 227.937 117.774 227.937 117.774 244.446 115.067 244.446 115.067 227.937 108.031 227.937 108.031 225.23 124.811 225.23"/>
      <path class="cls-1" d="M131.82029,239.0329c0,2.0581,2.2472,3.13907,5.008,3.13907,2.759,0,5.00618-1.19036,5.00618-3.13907s-2.2472-2.59764-5.00618-3.13905c-3.87144-.75835-7.17181-1.89494-7.17181-5.5476,0-3.54512,3.22064-5.54944,7.17181-5.54944,4.22,0,7.17182,2.27318,7.17182,5.54944h-2.707c0-1.78739-2.00248-2.84239-4.46479-2.84239-2.733,0-4.46662,1.055-4.46662,2.84239,0,1.51484,2.0581,2.38073,4.46662,2.84054,4.24784.81212,7.71324,2.11,7.71324,5.84611s-3.4654,5.84613-7.71324,5.84613c-4.24968,0-7.71507-2.21942-7.71507-5.84613Z"/>
      <path class="cls-1" d="M159.69769,242.17253a7.33406,7.33406,0,1,0-7.335-7.335,7.32277,7.32277,0,0,0,7.335,7.335m0-17.37517a10.04111,10.04111,0,1,1-10.0402,10.04019,10.03693,10.03693,0,0,1,10.0402-10.04019"/>
      <polygon class="cls-1" points="178.887 229.832 178.887 244.446 176.18 244.446 176.18 225.23 178.887 225.23 189.604 239.846 189.604 225.23 192.311 225.23 192.311 244.446 189.604 244.446 178.887 229.832"/>
      <polygon class="cls-1" points="223.597 225.23 223.597 227.937 212.5 227.937 212.5 233.485 222.787 233.485 222.787 236.192 212.5 236.192 212.5 244.446 209.795 244.446 209.795 225.23 223.597 225.23"/>
      <path class="cls-1" d="M232.39378,236.65109h6.19838l-3.08524-7.90236Zm12.15208,7.7948h-2.89619l-2.0025-5.08776h-8.30839l-1.97654,5.08776H226.468l7.52221-19.21632h3.03154Z"/>
      <polygon class="cls-1" points="252.773 231.563 252.773 244.446 250.066 244.446 250.066 225.23 252.773 225.23 259.891 241.172 267.009 225.23 269.716 225.23 269.716 244.446 267.009 244.446 267.009 231.563 261.352 244.446 258.43 244.446 252.773 231.563"/>
      <rect class="cls-1" x="277.43109" y="225.22955" width="2.70522" height="19.21633"/>
      <polygon class="cls-1" points="287.85 225.23 290.557 225.23 290.557 241.739 301.922 241.739 301.922 244.446 287.85 244.446 287.85 225.23"/>
      <polygon class="cls-1" points="309.664 236.57 301.979 225.23 305.253 225.23 311.018 233.783 316.782 225.23 320.057 225.23 312.371 236.57 312.371 244.446 309.664 244.446 309.664 236.57"/>
      <polygon class="cls-1" points="101.734 261.204 101.734 261.909 93.803 261.909 93.803 267.109 101.205 267.109 101.205 267.813 93.803 267.813 93.803 273.717 93.098 273.717 93.098 261.204 101.734 261.204"/>
      <path class="cls-1" d="M112.23657,273.29479a5.83365,5.83365,0,1,0-5.83283-5.83447,5.82915,5.82915,0,0,0,5.83283,5.83447m0-12.3725a6.53886,6.53886,0,1,1-6.538,6.538,6.53585,6.53585,0,0,1,6.538-6.538"/>
      <path class="cls-1" d="M132.87307,261.204h.7052v7.71943a5.07571,5.07571,0,1,1-10.15141,0V261.204h.7052v7.71943a4.37051,4.37051,0,1,0,8.741,0Z"/>
      <polygon class="cls-1" points="139.833 262.368 139.833 273.717 139.128 273.717 139.128 261.204 139.833 261.204 148.574 272.554 148.574 261.204 149.279 261.204 149.279 273.717 148.574 273.717 139.833 262.368"/>
      <path class="cls-1" d="M155.71118,273.013h3.788a5.55189,5.55189,0,1,0,0-11.10378h-3.788Zm-.7052-11.809h4.49323a6.25628,6.25628,0,0,1,0,12.51256H155.006Z"/>
      <path class="cls-1" d="M171.35993,269.169h6.2744l-3.13719-7.22512Zm9.02277,4.54762h-.77608l-1.67406-3.84241h-6.89062L169.386,273.7166h-.776l5.42913-12.51255h.91612Z"/>
      <polygon class="cls-1" points="192.12 261.204 192.12 261.909 187.185 261.909 187.185 273.717 186.48 273.717 186.48 261.909 181.545 261.909 181.545 261.204 192.12 261.204"/>
      <rect class="cls-1" x="196.33104" y="261.20471" width="0.7052" height="12.51255"/>
      <path class="cls-1" d="M208.40232,273.29479a5.83365,5.83365,0,1,0-5.83284-5.83447,5.83054,5.83054,0,0,0,5.83284,5.83447m0-12.3725a6.53886,6.53886,0,1,1-6.5397,6.538,6.5369,6.5369,0,0,1,6.5397-6.538"/>
      <polygon class="cls-1" points="220.473 262.368 220.473 273.717 219.768 273.717 219.768 261.204 220.473 261.204 229.214 272.554 229.214 261.204 229.919 261.204 229.919 273.717 229.214 273.717 220.473 262.368"/>
      <polygon class="cls-2" points="242.035 0.242 242.035 45.249 196.606 45.249 164.744 77.133 242.666 77.133 242.666 77.275 318.49 77.275 242.035 0.242"/>
      <polygon class="cls-3" points="164.445 85.944 164.445 85.944 164.445 163.964 242.358 163.964 204.916 122.377 164.445 122.377 164.445 85.944"/>
      <path class="cls-2" d="M164.41405,0H81.787V77.13515h82.627Zm0,86.47354a82.39136,82.39136,0,0,1-82.129,77.18852h-.498v.169h82.627Z"/>
      <path class="cls-4" d="M164.44471,77.13291h-.03114v9.34287c.01337-.17788.02226-.35576.03114-.53364V77.13291Zm-82.65813,0H.04892L.01781,80.66374l-.00444.22233L0,81.37967a82.38041,82.38041,0,0,0,81.78658,82.28459Z"/>
      <path class="cls-5" d="M164.41405,77.13291H81.787v86.53135h.498a82.39462,82.39462,0,0,0,82.129-77.18848Z"/>
      <polygon class="cls-3" points="242.035 0.007 164.446 0.007 164.446 45.249 196.606 45.249 241.812 0.02 242.035 0.243 242.035 0.007"/>
      <polygon class="cls-6" points="241.814 0.02 196.607 45.249 242.036 45.249 242.036 0.242 241.814 0.02"/>
      <polygon class="cls-4" points="164.743 77.133 164.445 77.133 164.52 77.133 164.525 77.511 204.92 122.38 242.665 122.38 242.665 77.275 164.6 77.275 164.743 77.133"/>
      <polygon class="cls-5" points="164.741 77.134 164.599 77.276 242.664 77.276 242.664 77.134 164.741 77.134"/>
      <path class="cls-7" d="M164.52566,77.5131l.03556,3.37517.00889.48916q0,2.30125-.12451,4.56692v36.43309h40.47082Z"/>
      <polygon class="cls-4" points="164.445 77.134 164.445 77.423 164.525 77.516 164.521 77.134 164.445 77.134"/>
      <path class="cls-7" d="M164.44516,77.42329v8.52018q.12671-2.26788.12451-4.56689l-.00893-.48916-.03555-3.37517Z"/>
    </g>
  </g>
</Logo>
</a>
    )
  }
export default AlbertsonsFamilyLogo;