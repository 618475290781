import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as style from "./Supporters.module.css"

const SmallBusinessCivic = () => {
  const data = useStaticQuery(graphql`
    {
      idahoDefenseAlliance: file(
        relativePath: { eq: "idaho-defense-alliance.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      idahoVeterans: file(relativePath: { eq: "idvs-logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sayler: file(relativePath: { eq: "Sayler.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hagedorn: file(relativePath: { eq: "Hagedorn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      infinitySigns: file(relativePath: { eq: "infinity-signs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dar: file(relativePath: { eq: "DAR-Horz-Full-Color-Blue-text.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      universityFinancial: file(
        relativePath: { eq: "University-Financial-2023-Logo-Black.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tamarack: file(relativePath: { eq: "tamarack-resort.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="tab-content">
      <Row className={`${style.starGarnet} my-4`}>
        <Row style={{ width: "100%" }} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{ color: "white", textAlign: "center !improtant" }}>
              Star Garnet - $2,500
            </h1>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col>
            <Row>
              <Col sm={4}>
                <a
                  href="https://tamarackidaho.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fluid={data.tamarack.childImageSharp.fluid}
                    alt="Tamarack Resort Logo"
                    style={{ maxWidth: "162px" }}
                  />
                </a>
              </Col>
              <Col>
                <h2>Tamarack Resort</h2>
                <h4>Tamarack Idaho</h4>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col sm={4}>
                <a
                  href="https://www.myunifi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fluid={data.universityFinancial.childImageSharp.fluid}
                    alt="University Financial Logo"
                    style={{ maxWidth: "162px" }}
                  />
                </a>
              </Col>

              <Col>
                <h2>University Financial</h2>
                <h4>Pocatello, Idaho</h4>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>

      <Row className={`${style.opal} my-4`}>
        <Row style={{ width: "100%" }} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{ textAlign: "center !improtant" }}>Opal - $1,000</h1>
          </Col>
        </Row>
        <Col>
          <Row className="text-center">
            <Col md={3}>
              <h3>Connie's</h3>
              <h4>Island Park, Idaho</h4>
            </Col>
            <Col md={3}>
              <a href="https://www.primuspolicy.com/">
                <p>
                  <h3>Primus Policy Group </h3>
                  <h4>Government Affairs</h4>
                </p>
              </a>
            </Col>
            <Col md={3}>
              <a href="https://www.facebook.com/cda.eagles/">
                <p>
                  <h3>Coeur d'Alene Fraternal Order of Eagles</h3>
                  <h4>Coeur d'Alene, Idaho</h4>
                </p>
              </a>
            </Col>
            <Col md={3}>
              <a href="https://www.facebook.com/cda.eagles/">
                <p>
                  <h3>Hayden Lake Fraternal Order of Eagles</h3>
                  <h4>Hayden, Idaho</h4>
                </p>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={`${style.jasper} my-4`}>
        <Row style={{ width: "100%" }} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{ textAlign: "center !improtant" }}>Jasper - $500</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Merrick RV Sales &amp; Service</h3>
            <h4>Blackfood, Idaho</h4>
          </Col>
        </Row>
      </Row>
      <Row className={`${style.jade} my-4`}>
        <Row style={{ width: "100%" }} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{ textAlign: "center !improtant" }}>Jade - $250</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Potandon Produce</h3>
            <h4>Idaho Falls, Idaho</h4>
          </Col>
          <Col>
            <h3>USSVI Toledo Base</h3>
            <h4>Toledo, Ohio</h4>
          </Col>
        </Row>
      </Row>

      <Row className={`${style.topaz} my-4`}>
        <Row style={{ width: "100%" }} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{ textAlign: "center !important" }}>Topaz - $100</h1>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center !important" }}>
            <h3>
              Daughters of the American Revolution
              <br />
              <span style={{ fontSize: "smaller" }}>
                LT George Farragut Chapter
              </span>
            </h3>
            <h4>Coeur d'Alene, Idaho</h4>
          </Col>
          <Col style={{ textAlign: "center !important" }}>
            <h3>Boise Hawks Baseball</h3>
            <h4>Boise, Idaho</h4>
          </Col>
          <Col style={{ textAlign: "center !important" }}>
            <h3>American Legion Post 60</h3>
            <h4>Cascade, Idaho</h4>
          </Col>
          <Col style={{ textAlign: "center !important" }}>
            <h3>Ashton Rotary Club</h3>
            <h4>Ashton, Idaho</h4>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default SmallBusinessCivic
