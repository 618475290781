import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { StickyContainer, Sticky } from 'react-sticky';
import Img from "gatsby-image"
import InlightMediaLogo from "../Individuals/InlightMediaLogo"
import MessageFromModalButton from "../MessageFromModal/MessageFromModalButton"
import MessageFormModalButtonSchweitzer from "../MessageFromModal/MessageFormModalButtonSchweitzer"
import IdahoPowerLogo from '../IdahoPowerLogo'
import UnitedHeritageLogo from '../UnitedHeritageInsurance'
import AlbertsonsLogo from '../AlbertsonsLogo'
import BankOfIdahoLogo from '../BankofIdahoLogo'
import FirstInterstateBankLogo from '../FirstInterstateBankLogo'

import GoldLevel from './GoldLevel'
import SilverLevel from './SilverLevel'
import BronzeLevel from './BronzeLevel'
import CopperLevel from './CopperLevel'
import BrassLevel from './BrassLevel'
import * as style from "../Supporters.module.css"
import IdahoCentralLogo from "../IdahoCentralCreditUnion";
import ConPaulosLogo from "../ConPaulosLogo"
import AgriBeefLogo from "../AgriBeefLogo"
import AlbertsonsFamilyLogo from "../AlbertsonsFamilyLogo"
import BattelleLogo from "../BattelleLogo"
import HeclaMiningCompanyLogo from "../HeclaLogo"
import SELLogo from "../SELLogo"
import DeltaDentalLogo from "../DeltaDentalLogo";
import SimplotLongLogo from "../SimplotLongLogo"
import AvistaLogo from "../AvistaLogo"
import BoiseCascadeLogo from "../BoiseCascadeLogo"
import IdahoForestGroupLogo from "../IdahoForestGroupLogo";

const PlatinumLevel = () => {
    const data = useStaticQuery(graphql`
    {
      nateJorgensen: file(relativePath: { eq: "NateJorgensen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      conPaulosLogo: file(relativePath: { eq: "conPaulos.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      conPaulos: file(relativePath: { eq: "ConPaulos.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kentOram: file(relativePath: { eq: "KentOram.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dennisJohnson: file(relativePath: { eq: "dennis_johnson.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lisaGrow: file(relativePath: { eq: "lisa_grow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      johnColgrove: file(relativePath: { eq: "JohnColgrove.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      unitedHeritage: file(relativePath: { eq: "uh_web_logo_80-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      toddGill: file(relativePath: { eq: "ToddGill.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jeffNewgard: file(relativePath: { eq: "JeffNewgard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lorrieAsker: file(relativePath: { eq: "LorrieAsker.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      robRebholtz: file(relativePath: { eq: "RobRebholtz.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jkafLogo: file(relativePath: { eq: "jkaf.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      simplotLogoWhite: file(relativePath: { eq: "SimplotCompanyFoundationLogo_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      johnCWagner: file(relativePath: { eq: "JohnCWagner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rogerQuarles: file(relativePath: { eq: "RogerQuarles.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phillipsBaker: file(relativePath: { eq: "PhillipsBaker.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      edumundSchweitzer: file(relativePath: { eq: "EdmundSchweitzer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      daveWhitehead: file(relativePath: { eq: "DaveWhitehead.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gregDonaca: file(relativePath: { eq: "GregDonaca.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      garrettLofto: file(relativePath: { eq: "GarrettLofto.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      scottSimplot: file(relativePath: { eq: "ScottSimplot.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      marcBrinkmeyer: file(relativePath: { eq: "MarcBrinkmeyer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  

  return (
      <div className="tab-content">
        
        <h3 className="text-center my-4">We are very honored to have the following generous Corporate Donors</h3>
        <hr />
        <StickyContainer>

        <Sticky>
        {({ style, isSticky }) => <div style={{ ...style, paddingTop: isSticky ? '130px' : '0px', boxShadow: isSticky ? ' 0px 3px 5px 0px rgba(0,0,0,0.5)' : 'none' }} className={style.supporterTitleDiv}><h1 className="text-center">Platinum Level Corporate Donors - $50,000+</h1></div>}
          
        </Sticky>
    <Row className={`${style.supportersRow} my-4`}>
      <Col md={2} className="text-center logo-col">
        <IdahoPowerLogo />
        <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
      </Col>
      <Col md={10}>
        <h2>Idaho Power</h2>
        <h4>Idaho Power, headquartered in vibrant and fast-growing Boise, Idaho, has been a locally operated energy company since 1916. The company’s goal to provide 100% clean energy by 2045 builds on its long history as a clean-energy leader that provides reliable service at affordable prices.</h4>
        <MessageFromModalButton 
        messagePerson="Lisa Grow"
        personTitle="CEO of Idaho Power"
        partnerUrl="https://www.idahopower.com"
        partner="Idaho Power"
        message="We are so proud to support the USS Idaho, those who will serve aboard this impressive and historic submarine, and their families."
        personPhoto={data.lisaGrow.childImageSharp.fluid}
        partnerLogo={<IdahoPowerLogo />}
      />
      </Col>
    </Row>
    <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
     <AlbertsonsLogo />
     <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Albertsons</h2>
      <h4>Locally great and nationally strong, Albertsons Companies (NYSE: ACI) is one of the largest food and drug retailers in the United States, operating stores across 34 states and the District of Columbia under 20 well-known banners. Albertsons Cos. is committed to helping people across the country live better lives by making a meaningful difference, neighborhood by neighborhood. In 2019 alone, along with the Albertsons Companies Foundation, the Company gave over $225 million in food and financial support. These efforts helped millions of people in the areas of hunger relief, education, cancer research and treatment, programs for people with disabilities and veterans outreach.</h4>
      <MessageFromModalButton 
        messagePerson="John Colgrove"
        personTitle="Division President, Albertsons Companies Intermountain Division "
        partnerUrl="https://www.albertsons.com"
        partner="Albertsons"
        personPhoto={data.johnColgrove.childImageSharp.fluid}
        message="Albertsons is honored to support the USS Idaho, its crew and their families. Our Company has a long history of supporting the communities we serve, and want to recognize and highlight the military members committed to protecting our country."
        partnerLogo={<AlbertsonsLogo />}
      />
    </Col>
  </Row>
    <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <UnitedHeritageLogo />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>United Heritage Insurance</h2>
      <h4>United Heritage Insurance is a family of mutual companies that combines life, disability, vision, home and auto insurance. Our companies were founded over a century ago and continue to serve our communities and customers with financial strength and service excellence. We are here to help people and hundreds of employees and thousands of agents across the country strive to meet this vision every day.</h4>
      <MessageFromModalButton 
        messagePerson="Todd H. Gill"
        personTitle="President and CEO of United Heritage Insurance"
        partnerUrl="https://www.unitedheritage.com/index.php"
        partner="United Heritage Insurance"
        message="Supporting our military families is critical, knowing firsthand the sacrifice and commitment my father and brother-in-law made in their US Navy careers. We are proud supporters of our military, the US Navy, the USS Idaho, and especially the families of those serving on the USS Idaho."
        personPhoto={data.toddGill.childImageSharp.fluid}
        partnerLogo={<UnitedHeritageLogo />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <BankOfIdahoLogo fillcolor="white" />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Bank of Idaho</h2>
      <h4>Bank of Idaho is the bank with a heart, where people bank with people. Our commitment is to make a difference in our community, because this is where we call home, too.</h4>
      <MessageFromModalButton 
        messagePerson="Jeff Newgard"
        personTitle="Bank of Idaho President"
        partnerUrl="https://www.bankofidaho.com"
        partner="Bank of Idaho"
        message="Bank of Idaho is so incredibly excited to be involved in the USS Idaho project. Our commitment to the military and military families is a cause that is incredibly important to our entire organization."
        personPhoto={data.jeffNewgard.childImageSharp.fluid}
        partnerLogo={<BankOfIdahoLogo fillcolor="#008145"/>}
      />
    </Col>
  </Row>

  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <FirstInterstateBankLogo />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>First Interstate Bank</h2>
      <h4>For decades, First Interstate has been part of the fabric of many hard-working towns and cities. Our belief—that community banking means so much more than simply transactions and accounts—is rooted in an approach to service centered around you. <strong>First Interstate Bank—Built for you.</strong></h4>
      <MessageFromModalButton 
        messagePerson="Lorrie Asker"
        personTitle="Regional President First Interstate Bank"
        partnerUrl="https://www.firstinterstatebank.com"
        partner="First Interstate Bank"
        message="Honoring our heroes and acknowledging their sacrifice is critical to maintaining liberty. It is with great esteem and appreciation that we support the commissioning of the USS Idaho. We are especially pleased to see a portion of our support provide a scholarship fund for the children of the USS Idaho’s service men and women."
        personPhoto={data.lorrieAsker.childImageSharp.fluid}
        partnerLogo={<FirstInterstateBankLogo />}
      />
    </Col>
  </Row>

  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <ConPaulosLogo />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Con Paulos Chevrolet</h2>
      <h4>Home of the Warranty Forever</h4>
      {/* <h4>Our staff is totally committed to "Doing Business Your Way" because the true measure of our success are Satisfied Customers. We are trusted by the Magic Valley to provide excellent value and "World Class Service" and we are constantly searching for improved ways to serve "You" our customer.</h4> */}
      <MessageFromModalButton 
        messagePerson="Con Paulos"
        personTitle="Owner of Con Paulos Chevrolet"
        partnerUrl="https://www.conpaulos.com"
        partner="Con Paulos Chevrolet"
        message="Con Paulos Chevrolet is proud to support the efforts of the USS Idaho SSN 799 Commissioning Foundation"
        personPhoto={data.conPaulos.childImageSharp.fluid}
        partnerLogo={<ConPaulosLogo />}
      />
    </Col>
  </Row>

  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <IdahoCentralLogo fillcolor='#1c8900' />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Idaho Central Credit Union</h2>
      <h4>Idaho Central is the premier credit union in Idaho and offers full-service locations throughout the Treasure Valley, Magic Valley, Eastern Idaho and North Idaho. While growth is vital to our success, we remain focused on our mission, "Helping members achieve financial success."</h4>
      <MessageFromModalButton 
        messagePerson="Kent Oram"
        personTitle="CEO of Idaho Central Credit Union"
        partnerUrl="https://www.iccu.com"
        partner="Idaho Central Credit Union"
        message="It’s a true pleasure to support the USS Idaho and those involved in its mission. Idaho is our home and we take great pride in its history and the incredible service of our military families. On behalf of our members and team members, congratulations on this amazing accomplishment."
        personPhoto={data.kentOram.childImageSharp.fluid}
        partnerLogo={<IdahoCentralLogo fillcolor='#1c8900' />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <AgriBeefLogo fillColor1="#ffffff" fillColor2="#ffffff"/>
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Agri Beef</h2>
      <h4>Real Families, Great People, Exceptional Beef</h4>
      <h4>We are real families and great people working hard every day to produce exceptional beef. Agri Beef is family-owned-and-operated company rooted in the Northwest and has been in business since 1968.</h4>
      <MessageFromModalButton 
        messagePerson="Rob Rebholtz"
        personTitle="President and CEO"
        partnerUrl="https://www.agribeef.com"
        partner="Agri Beef"
        message={<>"We extend our sincerest gratitude to the men and women who serve our great state and country in the U.S. Navy. Agri Beef is deeply honored to help perpetuate the bond between the citizens of the state of Idaho and the crews and families of the "gem of the fleet," the USS Idaho."</>}
        personPhoto={data.robRebholtz.childImageSharp.fluid}
        partnerLogo={<AgriBeefLogo fillColor1="#231f20" fillColor2="#7a2531" />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <AlbertsonsFamilyLogo />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>J.A. and Kathryn Albertson Family Foundation</h2>
      <h4>The J.A. and Kathryn Albertson Family Foundation is committed to accelerating opportunities in Idaho. We do this by investing time, energy, and resources into: Limitless Learning, Innovative Leaders, and Stronger Communities.</h4>
      <MessageFromModalButton 
        messagePerson="Roger Quarles"
        personTitle="Executive Director, J.A. and Kathryn Albertson Family Foundation"
        partnerUrl="https://jkaf.org"
        partner="J.A. and Kathryn Albertson Family Foundation"
        message="We are honored to support the USS Idaho Dolphin Scholarship Endowment Fund. Providing the resources for military family members to attain a quality education aligns with our values of learning, leadership, and strengthening community. We are also proud to make this gift in the name of Tom Wilford, former president of the J.A. &amp; Kathryn Albertson Family Foundation."
        personPhoto={data.rogerQuarles.childImageSharp.fluid}
        partnerLogo={<AlbertsonsFamilyLogo />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <BattelleLogo />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Battelle Energy Alliance</h2>
      <h4>Battelle Energy Alliance is responsible for the day-to-day management and operations of Idaho National Laboratory. INL is a U.S. Department of Energy national laboratory that performs work in each of DOE’s strategic goal areas: energy, national security, science and environment.  INL is the nation’s center for nuclear energy research and development.
</h4>
      <MessageFromModalButton 
        messagePerson="John C. Wagner"
        personTitle="Director of Idaho National Laboratory and President of Battelle Energy Alliance, LLC."
        partnerUrl="https://inl.gov/"
        partner="Battelle Energy Alliance, LLC."
        message={<>"Battelle Energy Alliance (BEA) is proud of the role Idaho National Laboratory (INL) has played in the development and modernization of the U.S. Nuclear Navy," said John Wagner, who serves as INL director and BEA president. "Support for our nation's armed forces and their families is in the lab's DNA and we are grateful for this opportunity to contribute to the USS Idaho Scholarship Fund."</>}
        personPhoto={data.johnCWagner.childImageSharp.fluid}
        partnerLogo={<BattelleLogo />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <HeclaMiningCompanyLogo fillColor1="#1e4184" fillColor2="#c7c3c2" />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Hecla Mining Company</h2>
      <h4>Hecla Mining Company is a precious metals company. Our business is to create value for our shareholders by discovering, acquiring, developing, producing, and marketing mineral resources at a profit. Our mission is to create long-term value for shareholders from mining silver, gold and associated base metals.</h4>
      <MessageFromModalButton 
        messagePerson="Phil Baker"
        personTitle="President and CEO of Hecla Mining Co"
        partnerUrl="https://www.hecla-mining.com/"
        partner="Hecla Mining Company"
        message={<>For 130 years, Hecla has supported Idaho families and our military. And we are now proud to support the brave men and women of our Navy who will serve on the USS Idaho. May the “Gem of the Fleet” be blessed with fair winds and following seas.</>}
        personPhoto={data.phillipsBaker.childImageSharp.fluid}
        partnerLogo={<HeclaMiningCompanyLogo fillColor1="#1e4184" fillColor2="#c7c3c2" />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <SELLogo fillColor="white" />
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Schweitzer Engineering Laboratories</h2>
      <h4>Making Electric Power Safer, More Reliable, and More Economical</h4>
      <MessageFormModalButtonSchweitzer 
        messagePerson="Edmund O. Schweitzer, III"
        messagePerson2="Dave Whitehead"
        personTitle="President and Chief Technology Officer"
        personTitle2="Chief Executive Officer"
        partnerUrl="https://selinc.com"
        partner="Schweitzer Engineering Laboratories"
        message={<>We are grateful and respectful for the men and women in uniform who protect our life, liberty and pursuit of happiness. We are honored to support the commissioning of the USS Idaho.</>}
        message2={<>It is SEL's honor to contribute to the USS Idaho and her crew’s distinguished mission. We are forever grateful for our U.S. Navy, and the USS Idaho sailors and their families.</>}
        personPhoto={data.edumundSchweitzer.childImageSharp.fluid}
        personPhoto2={data.daveWhitehead.childImageSharp.fluid}
        partnerLogo={<SELLogo fillColor="#003a5d"/>}
      />
    </Col>
  </Row>
    <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <DeltaDentalLogo fillColor="#4aa851" />
      {/* <DeltaDentalLogo fillColor="#fff" /> */}
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Delta Dental of Idaho</h2>
      <h4></h4>
      <MessageFromModalButton 
        messagePerson="Greg Donaca"
        personTitle="President and CEO"
        partnerUrl="https://www.deltadentalid.com/"
        partner="Delta Dental of Idaho"
        message={<>It’s Delta Dental of Idaho’s great honor to support the USS IDAHO and the men and women who serve to protect our country.</>}
        personPhoto={data.gregDonaca.childImageSharp.fluid}
        partnerLogo={<DeltaDentalLogo fillColor="#4aa851"/>}
      />
    </Col>
  </Row>

  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <a href="https://www.simplot.com/" target="_blank" rel="noopener noreferrer"><Img fluid={data.simplotLogoWhite.childImageSharp.fluid} alt="Simpot Company Foundation logo" /></a>
      {/* <SimplotLogo fillColor1="white" fillColor2="white" /> */}
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Simplot</h2>
      <h4>Bringing Earth's Resources to Life</h4>
      <MessageFromModalButton 
        messagePerson="Scott Simplot"
        personTitle="Chairman of the Board"
        partnerUrl="https://www.deltadentalid.com/"
        partner="Simplot"
        message={<>It is our highest honor to support the crew and family members of the USS Idaho for the sacrifices they make to keep our country safe. Go roam the seas, and come back to port safely. You are part of our family now.</>}
        personPhoto={data.scottSimplot.childImageSharp.fluid}
        partnerLogo={<SimplotLongLogo />}
        //partnerLogo={<SimplotLogo fillColor1="#dcb363" fillColor2="#73472e" />}
      />
    </Col>
  </Row>

  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <BoiseCascadeLogo fillColor="#3d9b46" fillColor2="#000000"/>
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Boise Cascade</h2>
      <h4>As a leading manufacturer and distributor of building materials, we bring people, products, and services together to build strong homes, businesses and communities that stand the test of time.</h4>
      <MessageFromModalButton 
        messagePerson="Nate Jorgensen"
        personTitle="Chief Executive Officer"
        partnerUrl="https://www.bc.com/"
        partner="Boise Cascade"
        message={<>We’re honored to be part of history in supporting the USS Idaho and its crews that will be protecting the Gem state and our country.</>}
        personPhoto={data.nateJorgensen.childImageSharp.fluid}
        partnerLogo={<BoiseCascadeLogo fillColor="#3d9b46" fillColor2="#000000"/>}
      />
    </Col>
  </Row>
  
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center logo-col">
      <IdahoForestGroupLogo fillColor="white" fillColor2="white"/>
      <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
    </Col>
    <Col md={10}>
      <h2>Idaho Forest Group</h2>
      <h4>As a leading manufacturer and distributor of building materials, we bring people, products, and services together to build strong homes, businesses and communities that stand the test of time.</h4>
      <MessageFromModalButton 
        messagePerson="Marc A. Brinkmeyer "
        personTitle="Owner"
        partnerUrl="https://www.bc.com/"
        partner="Idaho Forest Group"
        message={<>The Idaho Forest Group family is a proud supporter of the Armed Forces and we are excited to be able to support the USS Idaho, including its crew and their families. North Idaho has a rich naval history at Farragut State Park and a Naval Acoustic Research Detachment in Bayview. We want ensure that the culture and majesty of Idaho is apparent to all who see and serve aboard the submarine.</>}
        personPhoto={data.marcBrinkmeyer.childImageSharp.fluid}
        partnerLogo={<IdahoForestGroupLogo fillColor="#17468f" fillColor2="#087933"/>}
      />
    </Col>
  </Row> 



  
  </StickyContainer>
  <GoldLevel />
  <SilverLevel />
  <BronzeLevel />
  {/* <CopperLevel />
  <BrassLevel /> */}
    </div>
  )
}

export default PlatinumLevel
