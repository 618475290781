import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BaeSystems from './BAE-Logo.svg'


const SilverLevel = () => {
    const data = useStaticQuery(graphql`
    {
      riverClub: file(relativePath: { eq: "river-club-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dar: file(relativePath: { eq: "DAR-Horz-Full-Color-Blue-text.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      infinitySigns: file(relativePath: { eq: "infinity-signs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <div className="tab-content">
        <hr />
        <h1 className="text-center">Bronze Level Corporate Donors - $5,000+</h1>
        
        <Row>
          <Col lg={6} mb={2}>
          <Row style={{alignItems: 'center', height: '100%'}}>
          <Col md={3} className="text-center">
                <a href="https://www.baesystems.com/en/home" target="_blank" rel="noopener noreferrer">
                <img
                  src={BaeSystems}
                  alt="BAE Systems Logo"
                  style={{ maxWidth: "162px" }}
                />
                 <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
                </a>
              </Col>
          
              <Col >
                <h2>BAE Systems</h2>
              </Col>
            </Row> 
          </Col> 
        </Row>
        <Row>
          
          <Col lg={6} mb={2}>
          <Row style={{alignItems: 'center', height: '100%'}}>
          <Col md={3} className="text-center">
                <a href="https://www.dar.org/national-society/become-member/chapters-by-state/ID" target="_blank" rel="noopener noreferrer">
                <Img
                  fluid={data.dar.childImageSharp.fluid}
                  aalt="Daughter of the American Revolution Logo"
                  style={{ maxWidth: "162px" }}
                />
                 <p className="text-center mt-2" style={{fontSize: 'small', color: 'gray'}}>Click logo to visit partner website.</p>
                </a>
              </Col>
          
              <Col >
              <h2>Daughters of the American Revolution</h2>
              <h4>
                Idaho State Society
              </h4>
              </Col>
            </Row> 
          </Col>
        </Row>
  </div>
  )
}

export default SilverLevel
