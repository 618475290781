import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import MessageFromModalSchweitzer from "./MessageFormModalSchweitzer"
import * as styles from "../Supporters.module.css"

function Modal(props) {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
    {props.messagePerson ? (
      <div onClick={() => setModalShow(true)} className={styles.messageLink}>
        { props?.messagePerson ? (
          <>
            Message from{" "}
        {props.personTitle && (
          `${props.personTitle}${", "}`
        )}
        {props.messagePerson} {" "} {props?.messagePerson2 ? (
          <>
            {"and "}
            {props.personTitle2}{", "}{props.messagePerson2}
          </>
        ): null }  
        

        {" "}<span><FontAwesomeIcon icon={faExternalLink}/></span>
          </>
        ) : null }

        
      </div>
    ) : null }
      

      <MessageFromModalSchweitzer 
        messagePerson={props.messagePerson}
        messagePerson2={props.messagePerson2}
        partner={props.partner}
        message={props.message}
        partnerUrl={props.partnerUrl}
        personTitle={props.personTitle}
        personPhoto={props.personPhoto}
        messagePerson2={props.messagePerson2}
        message2={props.message2}
        personTitle2={props.personTitle2}
        personPhoto2={props.personPhoto2}
        partnerLogo={props.partnerLogo}
      show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default Modal
